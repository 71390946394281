export enum UrlList {
    URL = 'http://localhost:9999',
    URL_CORE = 'http://localhost:8501',
    URL_USERS = '/services/beplicoreuser/api',
    URL_PROFILE_INTEGRATED = '/services/bepliccoreprofileintegra/api',
    URL_GENERAL_INTEGRATED = '/services/bepliccoregeneralintegra/api',
    URL_ROOMS = '/services/bepliccoreroom/api',
    URL_CONTACTS = '/services/bepliccorecontact/api',
    URL_CONTACS_ROOMS_INTEGRA = '/services/bepliccorecontactroomintegra/api',
    URL_CONVERSATIONS = '/services/bepliccoreconversation/api',
    URL_CONVERSATIONS_ACK = '/services/bepliccoreconversationack/api',
    URL_CONVERSATIONS_ROOMS_INTEGRA = '/services/bepliccoreconversationroomsintegra/api',
    URL_MAINDOOR = '/services/bepliccoremaindoor/api',
    URL_UAA = '/services/beplicuaa/api',
    URL_SECURITY = '/services/beplicsecurity/api',
    URL_DASHBOARD = '/services/bepliccoredashboard/api',
    URL_FILE = '/services/bepliccorefile/api',
    URL_PARTNER = '/services/beplicpartners',
    URL_CAMPAIGN = '/services/bepliccorecampaign/api',
    URL_REACTIVE_MESSAGES = '/services/bepliccorereactivemessages/api',
    URL_MESSAGE_CHAT_BOT = '/services/bepliccoremessagechatbot/api',
    URL_CHATBOT = '/services/bepliccoremessagechatbot/api',


    MOBILE_PROFILE_STATUS = '/profile/mobile/updateAppStatus',
    MOBILE_GET_PROFILE = '/profile/mobile/appStatus/',
    GET_USER_PROFILE = '/v1/profile',
    UPDATE_PROFILE = '/profile',
    UPDATE_PASSWORD = '/account/change-password-customer-user',
    GET_CUSTOMER_ROOMS = '/findSala',
    DELETE_ROOM = '/rooms/',
    GET_ROOMS_WITH_CONVERSATIONS_NOT_VIEW = '/findRooms',
    GET_CHANNELS = '/channels',
    GET_CHANNELS_PARTNER_CONFIG = '/channelsByPartnerConfig',
    GET_USERS = '/customer-users-byCustomers',
    FIN_CUSTOMERUSER_ASIGNED_TO_SALA = '/customer-user/findCustomerUserAsignedtoSala',
    CREATE_ROOM = '/sala',
    UPDATE_ROOM = '/sala/update',
    GET_CUSTOMER_CONTACTS = '/contacts',
    GET_UPDATE_CONTACT = '/contacts',
    UPDATE_CONTACT_CHAT = '/contactChat/update',
    GET_CONVERSATIONS_BY_ROOM = '/chats/findByRoomId/',
    GET_CHATS_BY_ROOMS = '/chats/findRoomByNotView',
    GET_MESSAGES_BY_CHAT = '/messages/findByChat/',
    UPDATE_CHAT = '/chats',
    GET_USERS_BY_CUSTOMER_ID = '/profile/findProfiles/',
    UPDATE_MESSAGE = '/messages',
    CREATE_USER = '/profile/create',
    DELETE_USER = '/profile',
    SEND_IMAGE = '/maindoor/messageOut',
    SEND_MESSAGE_IN = '/maindoor/messageIn',
    GET_USER_WITH_ASSIGNED_ROOMS = '/sala/findRoomsAsignedToUser',
    ASSIGN_ROOMS_TO_USER = '/sala/updateCustomerUserRoom',
    GET_CONTACT_BY_ID = '/contacts',
    GET_NAVIGATION = '/navigation',
    GET_MESSAGES_BY_MONTH = '/dashboard',
    VALIDATE_EMAIL = '/users/existsEmail',
    RECOVER_ACCOUNT_INIT = '/account/reset-password/init',
    RECOVER_ACCOUNT_FINISH = '/account/reset-password/finish',
    CUSTOMER_LABELS = '/customers-labels/findLabel/',
    ADD_LABEL = '/contact-labels/modify',
    USER_AUTHORITIES = '/users-authorities/authoritiesByPartnerConfig',

    GET_HOST = '/chapiportalcore/api/propertiesServer',
    CHANNELS = '/api/channels',
    BOT_QUESTIONS = '/chapiportalcore/api/bot-questions',
    CUSTOMER_GREETINGS = '/chapiportalcore/api/customer-greetings',
    CUSTOMER_QUESTIONS = '/chapiportalcore/api/customer-questions',
    COSTUMER_RESOURCES = '/chapiportalcore/api/customers',
    COSTUMER_RESOURCES2 = '/api/customers',
    COSTUMER_UPDATE_ACTIVE = '/chapiportalcore/api/customers/update',
    CUSTUMER_CHANNELS = '/chapiportalcore/api/customer-channels',
    CUSTOMER_BUSINESS = '/chapiportalcore/api/customers/business',
    CUSTOMER_BUSINESS2 = '/api/customers/business',
    CUSTOMER_BOT = '/chapiportalcore/api/customer-bots',
    CUSTOMER_BOTS_BY_CUSTOMER = '/chapiportalcore/api/customer-bots-findbycustomer/',
    CUSTOMER = '/chapiportalcore/api/customers',
    CUSTOMER2 = '/api/customers',
    GREETING = '/chapiportalcore/api/greetings',
    PAYMENT_CUSTOMER = '/chapiportalcore/api/payment-of-customers',
    PAYMENT_CUSTOMER_STATE = '/api/payment-of-customers-state',
    PAYMENT_CUSTOMER2 = '/api/payment-of-customers',
    BUSINESS = '/chapiportalcore/api/businesses/',
    PAYMENT_TYPE = '/chapiportalcore/api/payment-types',
    PROFILE_INFO = '/chapiportalcore/api/profile-info',
    QUESTIONS = '/chapiportalcore/api/questions',
    CHATS = '/chapiportalcore/api/chats/find-by-session',
    GET_CHAT_STATE = '/chapiportalcore/api/chats/',
    CHAT_STATE = '/chapiportalcore/api/chats',
    MSG_STATE = '/chapiportalcore/api/chats/update',
    LOGIN = '/auth/login',
    BOT = '/api/bots',
    BOT2 = '/api/bots',
    CONTACTS = '/chapiportalcore/api/contacts',
    LATES_MESSAGES = '/chapiportalcore/api/messages/search',
    CONTACTSBYBOT = '/chapiportalcore/api/chats/findcontactsBybot',
    CUIT_EXISTS = '/chapiportalcore/api/businesses/existsCuit',
    MANUAL = '/chapiportalcore/api/reference-manuals',
    SERVED_CLIENTS = '/api/messages/customerServed',
    NOT_SERVED_CLIENTS = '/api/messages/customerNotServed',
    MONTHLY_MESSAGES = '/api/messages/monthBot',
    CREATE_GROUP = '/chapiportalcore/api/group-name',
    GROUPS = '/chapiportalcore/api/group-names/bot',
    CONTACT_GROUPS = '/chapiportalcore/api/contact-group-names/getByGroups',
    ADD_CONTACT_TO_GROUP = '/chapiportalcore/api/contact-group-names',
    CONTACTS_BY_WEYWORD = '/chapiportalcore/api/contacts/searchInMessageText',
    REGISTER = '/chapiuaa/api/register',
    LOGIN_REGISTER = '/chapiuaa/api/activate?key=',
    // DELETE_USER = '/chapiuaa/api/users/desactivate',
    ACCOUNT = '/chapiuaa/api/account',
    USER = '/chapiuaa/api/users',
    USER2 = '/api/users',
    USER_EXISTS = '/chapiuaa/api/users/exists',
    EMAIL_EXISTS = '/chapiuaa/api/users/existsEmail',

    //Endpoint usado en la version vieja
    // RECOVER_ACCOUNT_INIT = '/api/account/reset-password/init',
    //RECOVER_ACCOUNT_FINISH = '/api/account/reset-password/finish',
    CHANGE_PASSWORD = '/api/account/change-password',
    SEND_MESSAGE = '/chapiportalcore/api/messages/send/contacts',
    GET_CONTACTS_BY_BOT = '/chapiportalcore/api/chats/findbybot',
    GET_CONTACTS_BY_BOT_PAGEABLE = '/chapiportalcore/api/chats/getContactByBot/',
    GET_CHATS_BY_CONTACT = '/chapiportalcore/api/messages/chat/',
    VALIDATE_CUIT = '/chapiportalcore/api/customers/checkCuil',
    VALIDATE_FISCAL_NUMBER = '/chapiportalcore/api/customers/checkCuil',
    MENU_ROLES = '/chapiportalcore/api/menu-rolesById',
    MENU_ROLES2 = '/api/menu-rolesById',
    GET_BOT_APP_SECRET_PASS = '/chapiportalcore/api/bots/findappSecretPass',
    SEND_CONTACT_AND_CHAT = '/chapiportalcore/api/contacts/contactAndChat',
    SEND_WEB_MESSAGE = '/chapiportalcore/api/messages/send',
    SAVE_IMAGE = '/chapiportalcore/public/files/uploadFiles/',
    GET_IMAGE = '/chapiportalcore/public/files',
    GET_PDF = '/chapiportalcore/public/files/downloadFile',

    // CONVERSATION URL
    FIND_BY_ROOM_AND_USER = '/chats/findbyroomanduser',
    FIND_BY_ROOMID_AND_MESSSAGE = '/chats/findByRoomIdAndMessageText',
    FIND_BY_ROOMID_AND_MESSSAGE_USERNAME = '/chats/findByRoomIdAndMessageTextAndCustomeruser',
    CLOSE_CHAT = '/chats/updateState',

    // CONTACT_ROOM_INTEGRA
    FIND_CHAT_BY_USER = '/contactChat/findChatWebByContactAndRoom',

    // USERS
    FIND_CUSTOMERRUSERS_BY_IDCUSTOMER = '/customeruser-status'
}
