import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'template-reject-info-dialog',
    templateUrl: './template-reject-info-dialog.component.html',
    styleUrls: ['./template-reject-info-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TemplateRejectInfoDialogComponent implements OnInit {

    reasonRejectCustom1: string = 'Faltan parámetros variables o tiene llaves disparejas. El formato correcto es {{1}}.';
    reasonRejectCustom2: string = 'Los parámetros variables no son secuenciales. Por ejemplo, {{1}}, {{2}}, {{4}}, {{5}} están definidos, pero {{3}} no existe.';


    constructor(
        public matDialogRef: MatDialogRef<TemplateRejectInfoDialogComponent>,
    ) { }

    ngOnInit() {
    }

}
