import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'preview-template-v1-dialog',
    templateUrl: './preview-template-v1-dialog.component.html',
    styleUrls: ['./preview-template-v1-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PreviewTemplateV1DialogComponent implements OnInit {

    constructor(
        public matDialogRef: MatDialogRef<PreviewTemplateV1DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {

    }

}
