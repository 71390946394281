import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { TranslateService } from '@ngx-translate/core';

import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'beplic-assign-flow-list',
    templateUrl: './assign-flow-list.component.html',
    styleUrls: ['./assign-flow-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AssignFlowListComponent implements OnInit {
    countAssignedRooms: number = 0;
    flows = [];

    flowSelected: any;

    constructor(private translate: TranslateService,
        public matDialogRef: MatDialogRef<AssignFlowListComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,) { }

    ngOnInit() {
        this.flowSelected = this._data.flow;
        this.filterFreeAndAssignedRooms(this._data.rooms)
    }

    filterFreeAndAssignedRooms(rooms: any[]) {
        this.flows = rooms.filter(room => room.assignedAnotherFlow === false || (room.flowId !== null && room.flowId === this.flowSelected.id));
        this.updateCountAssignedRooms(this.flows);
    }

    selectedItem(room: any) {
        const customerUserRoomToFind = this.flows.find(flow => flow.roomId === room.roomId);
        if (customerUserRoomToFind) {
            customerUserRoomToFind.assigned = room.assigned;
        } else {
            this.flows.push(room);
        }

        this.updateCountAssignedRooms(this.flows);
    }

    submit() {
        this.matDialogRef.close(['save', this.flows]);
    }

    updateCountAssignedRooms(customeruserRooms: any[]) {
        const assignedRooms = customeruserRooms.filter(customeruserRoom => customeruserRoom.assigned && customeruserRoom.flowId === this.flowSelected.id);
        const assignedRoomss = customeruserRooms.filter(customeruserRoom => customeruserRoom.assigned && customeruserRoom.flowId === null);

        this.countAssignedRooms = assignedRooms.length + assignedRoomss.length;
    }

}
