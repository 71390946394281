import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

import { fuseAnimations } from '@fuse/animations';


@Component({
    selector: 'shared-table',
    templateUrl: './shared-table.component.html',
    styleUrls: ['./shared-table.component.scss'],
    animations: fuseAnimations,
})
export class SharedTableComponent<T> implements OnInit {


    @Input() columns: { columnDef: string, header: string, field: string }[] = [];
    @Input() dataSource = new MatTableDataSource<T>();
    @Input() totalElements;
    @Output() onChangePage = new EventEmitter<any>();
    @Output() onSelectElement = new EventEmitter<any>();


    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    @ViewChild('filter', { static: true })
    filter: ElementRef;

    displayedColumns: string[] = [];

    length: number;

    constructor(
        private router: Router,
    ) { }


    ngOnInit() {
        this.displayedColumns = this.columns.map(c => c.columnDef);
    }

    getValue(element: any, field: string) {
        return element ? element[field] : null;
    }



    selectedItem(flow: any, typeEvent: string): void {
        this.onSelectElement.emit([flow, typeEvent]);
    }

    goToEditFlow(flow: any) {
        this.router.navigateByUrl('/apps/conversations-flow/edit/' + flow.id);
    }

    onPageChange($event: any) {
        this.onChangePage.emit($event);
    }

    
}
