import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  animations: fuseAnimations
})
export class LoadingScreenComponet {
    @Input() showPreLoading: boolean = false;
    @Input() messageKey: string = 'ADMINISTRATION.ROOMS.WARNING.MESSAGE';
}
