import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { UrlList } from '../../url-list.enum';
import { CustomerGreeting } from '../../../shared/model/customer-greeting.model';
//import { Question } from '../../../shared/model/question.model';
import { CustomerQuestion } from '../../../shared/model/customer-question.model';
import { CustomerChannel } from '../../model/customer-channel.model';
import { Bot } from '../../model/bot.model';
import { BotQuestion } from '../../model/bot-question.model';
import { CustomerAccount } from '../../model/user.model';
import { BarHorizontal2DComponent } from '@swimlane/ngx-charts';
// import { environment } from 'environments/environment';

@Injectable()
export class BotService {
  avatar: Avatar = new Avatar();
  avatars: Avatar[] = [];
  url = UrlList.URL_CORE;
  user: CustomerAccount;

  constructor(private http: HttpClient) { }

  /**
   * Servicios de los canales de integración de bot
  */
  getChannels(): Observable<any> {
    return this.http.get(this.url + UrlList.CHANNELS);
  }

  getChannel(id: number): Observable<any> {
    return this.http.get(this.url + UrlList.CHANNELS + '/' + id);
  }

  getAllChannels(): Observable<any> {
    return this.http.get(this.url + UrlList.CHANNELS);
  }

  // ELIMINAR ESTE METODO
  deleteCustomerChannel(id: number): Observable<any> {
    return this.http.delete(this.url + UrlList.CUSTUMER_CHANNELS + '/' + id);
  }

  updateCustomerChannel(customerChannel: CustomerChannel): Observable<any> {
    const body = {
      id: customerChannel.id,
      channelId: customerChannel.channelId,
      customerId: customerChannel.customerId,
      customerFiscalNumber: customerChannel.customerFiscalNumber
    };
    return this.http.put(this.url + UrlList.CUSTUMER_CHANNELS, body);
  }

  getCustomerChannels(): Observable<any> {
    return this.http.get(this.url + UrlList.CUSTUMER_CHANNELS);
  }

  /**
     * Servicios referentes a bot
     */
  saveBot(bot: Bot): Observable<any> {
    this.user = JSON.parse(sessionStorage.getItem('userSessionCurrent'));
    const body = {
      id: bot.id,
      botName: bot.botName,
      completed: bot.completed,
      active: bot.active,
      pictureName: bot.pictureName,
      channelBotId: bot.channelBotId,
      customerBotId: bot.customerBotId,
      fanpageUser: bot.fanpageUser,
      customerId: this.user.id,
      appIdentifier: bot.appIdentifier,
      appSecretPass: bot.appSecretPass,
      phone: bot.phone,
      sexo: bot.sexo
    };
    return this.http.post(this.url + UrlList.BOT, body);
  }

  saveCustomerBot(bot: Bot): Observable<any> {
    const body = {
      botId: bot.id,
      customerId: bot.customerBotId
    };
    return this.http.post(this.url + UrlList.CUSTOMER_BOT, body);
  }

  deleteBot(id: number): Observable<any> {
    const body = { id: id}
    return this.http.put(this.url + UrlList.BOT + '/' + id, body);
  }

  deleteBotCustomer(bot: Bot): Observable<any> {
    return this.http.delete(this.url + UrlList.BOT + `/${bot.id}`);
  }

  getAllBots(): Observable<any> {
    return this.http.get(this.url + UrlList.BOT);
  }

  // getAllBotsByCustomer(customerId: number): Observable<any> {
  //   return this.http.get(environment.apiUrlCore + UrlList.BOT2 + '/customer/' + customerId);
  // }

  getAllCustomerBotsbyCustomer(idCustomer: number): Observable<any> {
    return this.http.get(this.url + UrlList.CUSTOMER_BOTS_BY_CUSTOMER + idCustomer);
  }

  getAllBotQuestions(): Observable<any> {
    return this.http.get(this.url + UrlList.BOT_QUESTIONS);
  }

  editBot(bot: Bot): Observable<any> {
    const body = {
      id: bot.id,
      botName: bot.botName,
      completed: bot.completed,
      // customerChannelId: bot.customerChannelId,//BORRARLO
      active: bot.active,
      pictureName: bot.pictureName,
      channelBotId: bot.channelBotId,
      customerBotId: bot.customerBotId,
      fanpageUser: bot.fanpageUser,
      appIdentifier: bot.appIdentifier,
      appSecretPass: bot.appSecretPass,
      phone: bot.phone,
      sexo: bot.sexo
    };
    return this.http.put(this.url + UrlList.BOT, body);
  }

  /**
    * Servicios referentes al entrenamiento de bot
    * -- Question
    * -- CustomerQuestion
    */
  getQuestions(): Observable<any> {
    return this.http.get(this.url + UrlList.QUESTIONS);
  }

  getQuestionsCustomer(): Observable<any> {
    return this.http.get(this.url + UrlList.CUSTOMER_QUESTIONS);
  }

  addQuestionsCustomer(customerQuestion: CustomerQuestion): Observable<any> {
    const body = {
      customerId: customerQuestion.customerId,
      question: customerQuestion.question,
      questionMasterId: customerQuestion.questionMasterId,
      id: customerQuestion.id,
      answer: customerQuestion.answer
    };
    return this.http.post(this.url + UrlList.CUSTOMER_QUESTIONS, body);
  }

  addBotQuestion(botQuestion: BotQuestion): Observable<any> {
    const body = {
      botId: botQuestion.botId,
      question: botQuestion.question,
      questionMasterId: botQuestion.questionMasterId,
      id: botQuestion.id,
      answer: botQuestion.answer
    }
    return this.http.post(this.url + UrlList.BOT_QUESTIONS, body);
  }

  editBotQuestion(botQuestion: BotQuestion): Observable<any> {
    const body = {
      botId: botQuestion.botId,
      question: botQuestion.question,
      questionMasterId: botQuestion.questionMasterId,
      id: botQuestion.id,
      answer: botQuestion.answer

    }
    return this.http.put(this.url + UrlList.BOT_QUESTIONS, body);
  }

  editQuestionsCustomer(customerQuestion: CustomerQuestion): Observable<any> {
    const body = {
      customerId: customerQuestion.customerId,
      question: customerQuestion.question,
      questionMasterId: customerQuestion.questionMasterId,
      id: customerQuestion.id,
      answer: customerQuestion.answer
    };
    return this.http.put(this.url + UrlList.CUSTOMER_QUESTIONS, body);
  }

  deleteQuestionCustomer(question: CustomerQuestion): Observable<any> {
    return this.http.delete(this.url + UrlList.CUSTOMER_QUESTIONS + `/${question.id}`);
  }

  deleteBotQuestion(id: number): Observable<any> {
    return this.http.delete(this.url + UrlList.BOT_QUESTIONS + `/${id}`);
  }

  /**
     * Servicios referentes a los saludos del bot
     * -- Greetings
     * -- Customer-Greetings
     */
  getGreetings(): Observable<any> {
    return this.http.get(this.url + UrlList.GREETING);
  }

  getCustomerGreetings(idCustomer: number): Observable<any> {
    return this.http.get(this.url + UrlList.CUSTOMER_GREETINGS + '/customer/' + idCustomer);
  }

  addGreetingCustomer(greeting: CustomerGreeting): Observable<any> {
    const body = {
      customerId: greeting.customerId,
      greeting: greeting.greeting,
      greetingMasterId: greeting.greetingMasterId,
      id: null
    };
    return this.http.post(this.url + UrlList.CUSTOMER_GREETINGS, body);
  }

  editGreetingCustomer(greeting: CustomerGreeting): Observable<any> {
    const body = {
      customerId: greeting.customerId,
      greeting: greeting.greeting,
      greetingMasterId: greeting.greetingMasterId,
      id: greeting.id
    };
    return this.http.put(this.url + UrlList.CUSTOMER_GREETINGS, body);
  }

  deleteCustomerGreeting(greeting: CustomerGreeting): Observable<any> {
    return this.http.delete(this.url + UrlList.CUSTOMER_GREETINGS + `/${greeting.id}`);
  }

  getBotCustomer(id: number): Observable<any> {
    return this.http.get<any>(this.url + UrlList.BOT + '/' + id);
  }

  getBotAppSecretPass(id: string): Observable<any> {
    const body = {
      appSecretPass: id
    }
    return this.http.post(this.url + UrlList.GET_BOT_APP_SECRET_PASS, body);
  }
  getCustomerChannel(id: number): Observable<any> {
    return this.http.get(this.url + UrlList.CUSTUMER_CHANNELS + '/' + id);
  }

  getAvatars(): Observable<Avatar[]> {
    for (let i = 1; i <= 16; i++) {
      this.avatar.img = 'a' + i + '.png';
      this.avatars.push(this.avatar);
      this.avatar = new Avatar();
    }
    return of(this.avatars);
  }

  getServedClients(idBot: number): Observable<any> {
    return this.http.get(this.url + UrlList.SERVED_CLIENTS + '/' + idBot);
  }

  getMonthlyMessages(idBot: number): Observable<any> {
    return this.http.get(this.url + UrlList.MONTHLY_MESSAGES + '/' + idBot);
  }

  getNotServedClients(idBot: number): Observable<any> {
    return this.http.get(this.url + UrlList.NOT_SERVED_CLIENTS + '/' + idBot);
  }
}

export class Avatar {
  img: string;
  constructor() {
    this.img = null;
  }
}
