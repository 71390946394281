import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Contact } from '../model/contact.model';
import { UrlList } from '../url-list.enum';
import { Bot } from '../model/bot.model';
import { Chat } from '../model/chat.model';
import { Group } from '../model/group.model';

@Injectable()
export class ContactService {
    private url = UrlList.URL;
    /*CONTACTS: Contact[] = [
      { id: 1, name: 'joel', firstName: 'joel', lastName: 'Dominguez', profilePicture: '', gender: 'hombre', number: 5493884499130 },
      { id: 2, name: 'juancito', firstName: 'juan', lastName: 'Gomez', profilePicture: '', gender: 'hombre', number: 5493884768327 },
      { id: 3, name: 'juancito', firstName: 'juan', lastName: 'Gomez', profilePicture: '', gender: 'hombre', number: 321948445 },
      { id: 4, name: 'juancito', firstName: 'juan', lastName: 'Gomez', profilePicture: '', gender: 'hombre', number: 984564318 },
      { id: 5, name: 'juancito', firstName: 'juan', lastName: 'Gomez', profilePicture: '', gender: 'hombre', number: 369852147 },
    ]*/
    constructor(private http: HttpClient) { }
    /*getAllContacts(): Observable<Contact[]> {
     return of(this.CONTACTS);
   }*/
    getContacts() {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Skip-Authorization': '' });
        return this.http.get(this.url + UrlList.CONTACTS, { headers: headers });
    }

    getContactsByBot(botId: number) {
        const body = {
            id: botId
        }
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Skip-Authorization': '' });
        return this.http.post(this.url + UrlList.GET_CONTACTS_BY_BOT, body, { headers: headers });
    }

    getContactsByBotPageable(botId:number,page:string): Observable<any>{
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Skip-Authorization': '' });

        return this.http.get(this.url + UrlList.GET_CONTACTS_BY_BOT_PAGEABLE + botId,{params:{page:page,size:'50'}});
    }


    getChatByContact(contactId: number,page: string):Observable<any> {
        const body = {
            id: contactId
        }
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Skip-Authorization': '' });
        return this.http.get(this.url + UrlList.GET_CHATS_BY_CONTACT+contactId,{params:{page:page,size:'10'}});
    }

    changeChatState(chat: Chat) {
        // console.log("Entro a moficiar el chat desde el contact service");


        if (chat.idSessionChat === 'I' && chat.conversationStatus === false) {
            chat.conversationStatus = true;
        }
        // console.log("ID USUARIO A MODIFICAR EN SERVICIO: " +chat.customId);
        // if(chat.customId == null || chat.customId == undefined){
        //     chat.customId = null;
        // }
        const body = {
            id: chat.id,
            idSessionChat: chat.idSessionChat,
            botId: chat.bot.id,
            contactId: chat.contact.id,
            msg_state: true,
            customerName: chat.customerName,
            conversationStatus: chat.conversationStatus,
            customerServiceStatus: chat.customerServiceStatus
        }
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Skip-Authorization': '' });
        return this.http.put(this.url + UrlList.MSG_STATE, body, { headers: headers });
    }

    setApiwhaConfig(bot: Bot) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Skip-Authorization': '' });
        return this.http.put(this.url + UrlList.BOT, bot, { headers: headers });
    }

    setContact(bot: Bot, contact: Contact) {
        const body = {
            idBot: bot.id,
            name: contact.name,
            phone: contact.phone,
            email: contact.email
        }
        return this.http.post(this.url + UrlList.SEND_CONTACT_AND_CHAT, body);
    }

    getTableContacts(bots: Bot[]): Observable<any> {
        return this.http.post(this.url + UrlList.CONTACTSBYBOT, bots);
    }

    getChat(id: number) {
        return this.http.get(this.url + UrlList.GET_CHAT_STATE + id);
    }

    saveGroup(group:any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Skip-Authorization', '');
    headers = headers.append('Content-Type', 'application/json');
    const body = {
      name: group.name,
      botId: group.idBot

    };
    return this.http.post(this.url + UrlList.CREATE_GROUP, body, { headers: headers });
    }

    deleteGroup(group:Group): Observable<any> {
        const body = {
            id: group.id,
            name: group.name,
            creationDate: group.creationDate,
            botId: group.botId,
            state: false
        }
        return this.http.put(this.url + UrlList.CREATE_GROUP, body);
      }
}

