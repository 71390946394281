import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { LocalStorageService } from 'app/services/local-storage.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AcademyCoursesService implements Resolve<any> {
    onCategoriesChanged: BehaviorSubject<any>;
    onCoursesChanged: BehaviorSubject<any>;
    onLoadUserRooms: BehaviorSubject<any>;
    on: BehaviorSubject<any>;
    private urlRooms = UrlList.URL_ROOMS;
    private urlUsers = UrlList.URL_USERS;
    private urlGeneralInegtrated = UrlList.URL_GENERAL_INTEGRATED;
    userProfile: CustomerAccount;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _localStorageService: LocalStorageService
    ) {
        // Set the defaults
        this.onCategoriesChanged = new BehaviorSubject({});
        this.onCoursesChanged = new BehaviorSubject({});
        this.onLoadUserRooms = new BehaviorSubject({});
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.getUserProfile();
        return new Promise((resolve, reject) => {
            resolve(null);
        });
    }

    getUserProfile(): void {
        this.userProfile = this._localStorageService.getCurrentUser();
    }

    getChannels(): Observable<any> {
        return this._httpClient.get(this.urlRooms + UrlList.GET_CHANNELS);
    }

    getChannelsByPartnerConfig(): Observable<any> {
        const userProfile = this._localStorageService.getCurrentUser();
        const configValues: string[] = userProfile.frontConfigurations && userProfile.frontConfigurations.configurations ? userProfile.frontConfigurations.configurations : [];
        return this._httpClient.post(this.urlRooms + UrlList.GET_CHANNELS_PARTNER_CONFIG, configValues);
    }

    getRooms(customeruserId: number, customersId: number, page?: number, size?: number): Observable<any> {
        const body = {
            customeruserId: customeruserId,
            customersId: customersId
        }
        return this._httpClient.post(this.urlRooms + UrlList.GET_CUSTOMER_ROOMS + '?page=' + page + "&size=" + size, body);
    }

    getAllRoomsByCustomer(customerId: number): Observable<any> {

        return this._httpClient.get(this.urlRooms + '/sala/' + customerId).pipe(
            map((responseArray: any) => responseArray.map(room => ({
                asigned: false,
                customersId: customerId,
                customeruserId: null,
                rooms: {
                    ...room,
                    channelId: room.channel!.id // Extrae el `id` de `channel` y lo asigna a `channelId`
                }
            })))
        );
    }


    getAllUsers(customerId: number, page?: number, size?: number): Observable<any> {
        return this._httpClient.get(this.urlUsers + UrlList.GET_USERS + '/' + customerId + '?page=' + page + "&size=" + size);
    }

    getUsersAssignedToRoom(room, page?: number, size?: number): Observable<any> {

        return this._httpClient.get(this.urlGeneralInegtrated + UrlList.FIN_CUSTOMERUSER_ASIGNED_TO_SALA, { params: { customerId: room.customersId, roomId: room.room.id, page: page.toString(), size: size.toString() } });
    }

    createRoom(room: any): Observable<any> {
        if (!this.userProfile) {
            this.getUserProfile();
        }
        const me = {
            customersId: this.userProfile.customerId,
            customeruserId: this.userProfile.id,
            firstName: this.userProfile.firstName,
            lastName: this.userProfile.lastName,
            login: undefined,
        };
        if (!room.users) {
            room.users = [];
            room.users.push(me);
        } else {
            room.users.push(me);
        }

        let body = {
            name: room.name,
            pictureName: room.pictureName,
            completed: room.completed,
            active: room.active,
            fanpage_user: null,
            appSecretPass: room.appSecretPass,
            phoneNumber: null,
            channelId: room.channelId,
            customerUserRoomDTO: room.users,
            modality: room.modality,
            pageId: null,
            activeWelcomeMessage: room.activeWelcomeMessage || false,
            welcomeMessage: room.welcomeMessage,
            widgetWebchat: null,
            activeAutoResponse: room.activeReminderMessage || false,
            messageAutoResponse: room.reminderMessage,
            timeAutoResponse: room.startTime,
            email: null,
            pageIdInstagram: null,
            apiProvider: room.apiProvider,
            importSubscribers: false,
            groupNotification: null,
            waba: null,
            phoneNumberId: null,
            code: null,
            fbUserId: null,
            phoneCode: null,
            customerUserId: this.userProfile.id
        }

        if (room.channelId === 1) {
            body.appSecretPass = room.key; // Campo solo para crear sala para revision de permisos de WhatsApp
            body.phoneNumber = room.number;
            body.importSubscribers = room.importSubscribers;
            body.waba = room.waba;
            body.phoneNumberId = room.phoneNumberId;
            body.phoneCode = room.phoneCode;
            if (room.asset) {
                body.code = room.asset.code;
            }
        } else if (room.channelId === 2 || room.channelId === 3) {
            body.fanpage_user = room.fanPage;

        } else if (room.channelId === 4) {
            body.fanpage_user = room.fanPage;
            body.appSecretPass = room.asset.pageAccessToken;
            body.pageId = room.asset.fbPageId;
            body.fbUserId = room.asset.fbUserId;
        } else if (room.channelId === 5) {
            body.email = room.email;

            // entryServer: room.entryServer,
            // SMTPServer: room.SMTPServer,
            // user: room.user,
            // // password: room.password
        } else if (room.channelId === 6) {
            body.appSecretPass = room.asset.pageAccessToken;
            body.fanpage_user = room.asset.username;
            body.pageIdInstagram = room.asset.igPageid;
            body.pageId = room.asset.fbPageId;
            body.fbUserId = room.asset.fbUserId;
        }

        return this._httpClient.post(this.urlRooms + UrlList.CREATE_ROOM, body);
    }

    updateRoom(room: any): Observable<any> {
        if (!this.userProfile) {
            this.getUserProfile();
        }
        const me = {
            asigned: true,
            customersId: this.userProfile.customerId,
            customeruserId: this.userProfile.id,
            firstName: this.userProfile.firstName,
            id: null,
            lastName: this.userProfile.lastName,
            roomId: room.id,
        };
        room.users.push(me);

        const body = {
            id: room.id,
            name: room.name,
            pictureName: room.pictureName,
            completed: room.completed,
            customerUserRoomDTO: room.users,
            fanpage_user: room.fanPage,
            modality: room.modality,
            activeWelcomeMessage: room.activeWelcomeMessage || false,
            welcomeMessage: room.welcomeMessage,
            activeAutoResponse: room.activeReminderMessage || false,
            messageAutoResponse: room.reminderMessage,
            timeAutoResponse: room.startTime,
            clientName: room.knowledgeId,
            knowledge: room.activeKnowledgeIntegration,
            email: room.email,
            customerUserId: this.userProfile.id,
            externalIntegrationId: room.externalIntegrationId
        };

        return this._httpClient.post(this.urlRooms + UrlList.UPDATE_ROOM, body);
    }

    saveRoomWithWidgetId(room: any, widgetWebChatId): Observable<any> {
        const body = {
            id: room.id,
            name: room.name,
            pictureName: room.pictureName,
            completed: room.completed,
            active: room.active,
            fanpage_user: room.fanpage_user,
            appSecretPass: room.appSecretPass,
            phoneNumber: room.number,
            channelId: room.channel,
            customerUserRoomDTO: room.editUsers,
            creationDate: room.creationDate,
            modality: room.modality,
            activeWelcomeMessage: room.activeWelcomeMessage,
            pageId: room.pageId,
            welcomeMessage: room.activeWelcomeMessage ? room.welcomeMessage : null,
            activeAutoResponse: room.activeAutoResponse,
            messageAutoResponse: room.reminderMessage,
            timeAutoResponse: room.startTime,
            widgetWebchatId: widgetWebChatId
        };

        // return null;
        return this._httpClient.post(this.urlRooms + UrlList.UPDATE_ROOM, body);
    }

    subscribeToWebhook(pageId, accessToken): Observable<any> {
        // tslint:disable-next-line: max-line-length
        return this._httpClient.post(`https://graph.facebook.com/${pageId}/subscribed_apps?subscribed_fields=messages&access_token=${accessToken}`, {});
    }

    getCustomerUsersByIdCustomer(customerId: number, page?: number, size?: number) {
        return this._httpClient.get(`${this.urlUsers}${UrlList.FIND_CUSTOMERRUSERS_BY_IDCUSTOMER}/${customerId}?page=${page}&size=${size}`)
    }

    getRoomById(roomId: number) {
        return this._httpClient.get('/services/bepliccoreroom/api/rooms/' + roomId);
    }

    checkMetaAsset(assetId: string, channelId: number) {
        return this._httpClient.get(`/services/bepliccoreroom/api/rooms/asset/${assetId}/channel/${channelId}`);
    }
}
