import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private urlDashboard = UrlList.URL_DASHBOARD;

    constructor(
        private _httpClient: HttpClient,
    ) { }

    getConversationIndicators(filters: any): Observable<IndicatorResponse> {
        const body: any = {
            customerId: filters.customerId,
            customerUsersId: filters.customerUsersId,
            fromDate: filters.fromDate,
            toDate: filters.toDate,
            channelId: filters.channelId
        };
        return this._httpClient.post<IndicatorResponse>(`${this.urlDashboard}/get-conversation-indicators`, body);
    }

    /**
     * method that obtains signed url from the dashboard
     * @param dashboardData data to obtain signed url from the dashboard
     * @returns signed url
     * @see Ms-dashboard ConversationMetabaseResource
     */
    getDashboard(dashboardData: any): Observable<string> {
        const body: any = dashboardData

        const apiUrl = `${this.urlDashboard}/get-dashboard`;
        return this._httpClient.post(apiUrl, body, { responseType: 'text' });
    }

    /**
     * Gets the list of currently active reports
     * @returns active report list
     * @see Ms-dashboard ConversationMetabaseResource
     */
    getReports(): Observable<DasboardReportResponse[]> {
        const apiUrl = `${this.urlDashboard}/get-reports-list`;
        return this._httpClient.get<DasboardReportResponse[]>(apiUrl);
    }
}
