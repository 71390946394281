export class FileRules {

    static readonly MAX_FILE_SIZE = 10485760; // The size in bytes 
    static readonly MAX_IMG_SIZE = 5242880; // The size in bytes 

    static getMaxSize(fileType: 'img' | 'file' | 'document' | 'video') {
        return fileType === 'img' ? this.MAX_IMG_SIZE : this.MAX_FILE_SIZE;
    }

    static getTitleMaxFileSize(fileType: 'img' | 'file' | 'document' | 'video', title: string) {
        return `¡${title} no debe superar los ${Math.round(this.getMaxSize(fileType) / 1000000)} MB!`;
    }
}