import { FuseNavigation } from '@fuse/types';

export const navigation_user: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',

        children: [
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type: 'collapsable',
                icon: 'dashboard',

                children: [
                    {
                        id: 'analytics',
                        title: 'Analytics',
                        type: 'item',
                        url: '/apps/dashboards/analytics',

                    },
                    {
                        id: 'project',
                        title: 'Project',
                        type: 'item',
                        url: '/apps/dashboards/project',

                    }
                ]
            },
            // {
            //     id: 'administration',
            //     title: 'Administration',
            //     translate: 'NAV.DASHBOARDS',
            //     type: 'collapsable',
            //     icon: 'dashboard',
            //     show: true,
            //     children: [
            //         {
            //             id: 'rooms',
            //             title: 'Rooms',
            //             type: 'item',
            //             url: '/apps/list-channels/courses',
            //             show: true,

            //         },
            //         {
            //             id: 'users',
            //             title: 'Users',
            //             type: 'item',
            //             url: '/apps/users/list',
            //             show: true,

            //         }
            //     ]
            // },

            {
                id: 'chat',
                title: 'Chat',
                translate: 'NAV.CHAT',
                type: 'item',
                icon: 'chat',
                url: '/apps/chat',

                badge: {
                    title: '13',
                    bg: '#09d261',
                    fg: '#FFFFFF'
                }
            },

            {
                id: 'contacts',
                title: 'Contacts',
                translate: 'NAV.CONTACTS',
                type: 'item',
                icon: 'account_box',
                url: '/apps/contacts',

            },

        ]
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'group',
        icon: 'pages',
        children: [

            {
                id: 'profile',
                title: 'Profile',
                type: 'item',
                icon: 'person',
                url: '/pages/profile',

            },



        ]
    },
];
