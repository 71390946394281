import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { DataCreateContactDialog } from 'app/interfaces/dialog/data-create-contact-dialog.interface';
import { ContactDTO } from 'app/interfaces/dto/contact-dto.interface';
import { RequestCreateContactDTO } from 'app/interfaces/dto/request-create-contact-dto.interface';
import { BusinessFormDialogComponent } from 'app/main/apps/contacts/contact-form copy/business-form.component';
import { ContactsService } from 'app/main/apps/contacts/contacts.service';
import { RoomsService } from 'app/main/apps/services/rooms.service';
import { WarningCreateContactDialogComponent } from '../warning-create-contact-dialog/warning-create-contact-dialog.component';
import { LocalStorageService } from 'app/services/local-storage.service';

const MAX_LENGTH_FIELDS = 100;

@Component({
    selector: 'create-contact-dialog',
    templateUrl: './create-contact-dialog.component.html',
    styleUrls: ['./create-contact-dialog.component.scss']
})
export class CreateContactDialogComponent implements OnInit {
    rform: FormGroup;
    displayedColumns: string[] = ['room', 'selected'];
    dataSource = [];
    businessFilteredOptions: Observable<string[]>;
    countryCodeOptions: Observable<string[]>;
    businessList: any[] = [];
    selectedBussines: any;
    rooms: any;
    actionsType: string;
    userProfile: any;
    countryCodeList = [];
    selectedCountryCode: any;
    regexEmail = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
    isExistPhone: boolean = false;
    activeSpinner: boolean = false;
    isPhoneMaxLengthBad: boolean = false;
    isCountrCodeBad: boolean = false;
    lblPhone: string = 'Teléfono';
    lblEmail: string = 'Correo';
    checkAllControl = new FormControl();
    emailRoomsError = [];
    whatsAppRoomsError = [];
    conflicRooms = [];
    roomsWithoutConflit = [];
    updateTxt: string = "Se actualizó el contacto y se creó el chat correctamente";
    isWrongCountryCodeOption: boolean = false;
    disabledPhone: boolean = false;
    disabledEmail: boolean = false;
    maxLengthFields = MAX_LENGTH_FIELDS;

    constructor(
        public matDialogRef: MatDialogRef<CreateContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DataCreateContactDialog,
        public _matDialog: MatDialog,
        private _fb: FormBuilder,
        private _contactService: ContactsService,
        private _roomsService: RoomsService,
        private _localStorageService: LocalStorageService
    ) {
        this.init()
    }

    ngOnInit() {
        if (this.data.persistType === 'single') {
            // Canal tipo whatsapp
            if (this.data.room.channel.id === 1) {
                this.setValidatorsPhoneControl(true, false);
            }

            // Canal tipo email
            if (this.data.room.channel.id === 5) {
                this.setValidatorsEmailControl(true, false);
            }
        }

        if (this.data.persistType === 'multiple') {
            const selectedRooms = this.f.get('selectedRooms');
            selectedRooms.setValidators([Validators.required]);
            this.f.updateValueAndValidity();
        }
    }


    init() {
        // TODO: deshabilitación temporal
        if (this.data.persistType === 'single') {
            // Canal tipo whatsapp
            if (this.data.room.channel.id === 1)
                this.disabledEmail = true;

            // Canal tipo email
            if (this.data.room.channel.id === 5)
                this.disabledPhone = true;

        }
        ///////
        this.userProfile = this._localStorageService.getCurrentUser();
        this.rform = this._fb.group({
            firstName: [null, [Validators.required, Validators.maxLength(MAX_LENGTH_FIELDS)]],
            lastName: [null, [Validators.required, Validators.maxLength(MAX_LENGTH_FIELDS)]],
            phone: [{ value: null, disabled: this.disabledPhone }, c => this.phoneValidator(c, 'phone')],
            areaPhone: [null, c => this.phoneValidator(c, 'areaPhone')],
            countryCode: [{ value: null, disabled: this.disabledPhone }, c => this.phoneValidator(c, 'countryCode')],
            email: [{ value: null, disabled: false }, Validators.pattern(this.regexEmail)],
            alias: [null, Validators.maxLength(MAX_LENGTH_FIELDS)],
            enterpriseId: null,
            businessName: [null, Validators.maxLength(MAX_LENGTH_FIELDS)],
            selectedRooms: null
        })

        // Get listado de empresas
        this._contactService.getSuggestedBusiness(this.userProfile.customerId).subscribe(data => {
            this.businessList = data || [];
            this.subscribeToBusinessControlChange();
        });


        if (this.data.persistType === 'multiple') {
            // Get listado de rooms asignados al usuario
            const requestRooms = { customeruserId: this.userProfile.id, customersId: this.userProfile.customerId }
            this._roomsService.getRoomsByCustomer(requestRooms).subscribe((roomsData: any[]) => {
                // Se filtra salas de tipos Whatsapp o Email
                this.rooms = roomsData.map(roomData => roomData.room).filter(room => room.channel.id === 1 || room.channel.id === 5);
                this.dataSource = this.rooms.map(r => {
                    return { room: r, selected: false }
                })
            })
        }

        // Get listado de codigos de país para los telefonos
        this._contactService.getCountryCodeList().subscribe((countryCodes: any[]) => {
            this.countryCodeList = countryCodes.map(item => {
                return {
                    valueMap: `${item.country.slice(0, 3)} +${item.countryCode}`,
                    ...item
                }
            });
            this.subscribreToCountryControlChange();
        });

    }

    phoneValidator(control: AbstractControl, name: string): { [key: string]: boolean } | null {
        if (this.f) {
            const countryCode = this.f.get('countryCode').value || '';
            //const areaPhone = this.f.get('areaPhone').value || '';
            const phone = this.f.get('phone').value || '';

            //const phoneValue = `${countryCode}${areaPhone}${phone}`;
            let phoneValue = `${countryCode}${phone}`;

            this.isExistPhone = false;

            if (phoneValue.length === 0) {
                this.isCountrCodeBad = false;
                this.isPhoneMaxLengthBad = false;
                this.isWrongCountryCodeOption = false;
                return;
            }

            if (!countryCode) {
                this.isCountrCodeBad = true;
                this.isPhoneMaxLengthBad = false;
                this.isWrongCountryCodeOption = false;
                return
            } else {
                this.isCountrCodeBad = false;
                // Check si la opción de codigo pais seleccionada es valida
                if (this.countryCodeList.some(item => item.valueMap === countryCode)) {
                    this.isWrongCountryCodeOption = false;
                } else {
                    this.isWrongCountryCodeOption = true;
                }
            }

            if (this.selectedCountryCode) {
                phoneValue = `${this.selectedCountryCode.countryCode.replace(/ /g, '')}${phone}`;
                this.isPhoneMaxLengthBad = phoneValue.length !== this.selectedCountryCode.length;
            }

            return;
        }

        return;
    }

    subscribreToCountryControlChange(): void {
        this.countryCodeOptions = this.rform.get('countryCode').valueChanges
            .pipe(startWith(''), map(value => this._filterCountriesCode(value)))
    }

    private _filterCountriesCode(value: any): string[] {
        const filterValue = value.toLowerCase();
        return this.countryCodeList.filter(option => option.valueMap.toLowerCase().includes(filterValue)
        );
    }

    subscribeToBusinessControlChange(): void {
        this.businessFilteredOptions = this.rform.get('businessName').valueChanges
            .pipe(startWith(''), map(value => this._filter2(value)));
    }

    private _filter2(value: any): string[] {
        const filterValue = value.toLowerCase();
        return this.businessList.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onSubmit(e) {
        // reset variables used
        this.isExistPhone = false;
        if (this.rform.invalid || this.isCountrCodeBad || this.isWrongCountryCodeOption || this.isPhoneMaxLengthBad) {
            this.f.markAllAsTouched();
            return;
        }

        this.activeSpinner = true;
        let roomsId = [];
        if (this.data.persistType === 'multiple') {
            roomsId = this.dataSource.filter(data => data.selected).map(data => data.room.id);
        }
        if (this.data.persistType === 'single') {
            roomsId.push(this.data.room.id);
        }

        let countryCode = '';
        const phone = this.rform.get('phone').value;
        let phoneData = null;
        if (this.selectedCountryCode && phone) {
            countryCode = this.selectedCountryCode.countryCode.replace(/ /g, '');
            phoneData = `${countryCode}${phone}`;
        }

        const contactDTO: ContactDTO = {
            ...this.rform.value,
            countryCode: this.selectedCountryCode ? this.selectedCountryCode.countryCode : null,
            phone: phoneData
        }

        const dataToSave: RequestCreateContactDTO = {
            roomsId: roomsId,
            customerId: this.userProfile.customerId,
            contact: contactDTO,
            customerUserId: this.userProfile.id
        }

        this._contactService.validateNewContact(dataToSave).subscribe((validationData: any) => {
            this.activeSpinner = false;

            if (this.data.persistType === 'single') {

                if (!validationData['validContact']) {

                    this.openModalWarningBeforeSave(validationData, dataToSave);
                    return;
                }

                this.save(dataToSave);
            }

            // TODO: por el momento solo se hara el flujo "single" por la pantalla de chats.
            /*   if (this.data.persistType === 'multiple') {
  
                  if (validation && validation.length > 0) {
                      this.emailRoomsError = validation.filter(val => val.existContact && val.channelId === 5);
                      this.whatsAppRoomsError = validation.filter(val => val.existContact && val.channelId === 1);
  
                      this.conflicRooms = [...this.whatsAppRoomsError, ...this.emailRoomsError];
                      this.roomsWithoutConflit = validation.filter(val => !val.existContact);
  
                  }
                  // Guardado normal
                  this.save(dataToSave);
              } */

        }, err => {
            console.error(err);
            this.activeSpinner = false;
        }
        )
    }

    openModalWarningBeforeSave(validationData: any, dataToSave: any) {
        const dialogRef = this._matDialog.open(WarningCreateContactDialogComponent, {
            panelClass: 'dialog-warning-custom-wrapper',
            data: {
                ...validationData,
                roomId: this.data.room.id
            }
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) return;

                const updateContact = response['updateContact'];
                dataToSave['updateContact'] = updateContact;

                let updateTxt = updateContact ? this.updateTxt : 'Se creó el chat correctamente';
                this.save(dataToSave, updateTxt)
            });
    }

    save(data: RequestCreateContactDTO, updateTxt?: string) {
        this.activeSpinner = true;
        this._contactService.createNewContact(data).subscribe(resp => {
            this.activeSpinner = false;
            let data = {};

            if (this.conflicRooms.length > 0) {
                data = {
                    persist: resp,
                    emailRoomsError: this.emailRoomsError,
                    whatsAppRoomsError: this.whatsAppRoomsError,
                    conflicRooms: this.conflicRooms,
                    roomsWithoutConflit: this.roomsWithoutConflit
                }
            } else {
                Swal.fire({
                    title: 'Atención!',
                    position: 'center',
                    type: 'success',
                    text: updateTxt ? updateTxt : 'Se creó el contacto y el chat correctamente',
                    confirmButtonColor: '#e2ae2f',
                    showConfirmButton: false,
                    timer: 1700
                });
            }

            // close modal
            this.matDialogRef.close(['save', data]);

        }, err => {
            console.error(err);
            this.activeSpinner = false;
            Swal.fire({
                title: 'Atención!',
                position: 'center',
                type: 'error',
                text: '¡Ups! Ha ocurrido un error al procesar la solicitud. Inténtelo de nuevo más tarde.',
                confirmButtonColor: '#e2ae2f',
                showConfirmButton: false,
                timer: 2000
            });
        }
        )
    }

    onCreateNewBusiness(): void {
        const dialogRef = this._matDialog.open(BusinessFormDialogComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'edit'
            }
        });
        dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) return;

                this.businessList.push(response[1]);
                const actionType: string = response[0];
                const bussines: any = response[1];

                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save': this.setDataBussines(bussines); break;

                    default: break;
                }
            });
    }

    onSelecteBussines(e: any, business: any) {
        if (e.isUserInput) this.setDataBussines(business);
    }

    setDataBussines(business: any) {
        if (business) {
            this.rform.patchValue({
                enterpriseId: business.id,
                businessName: business.name
            })
        }
    }

    onSelectedRow(event: MatCheckboxChange, roomData: any, type: 'all' | 'single' | 'row', index?: number) {
        if (type === 'all') {
            this.dataSource.forEach(dataRoom => dataRoom.selected = event.checked);
        }

        if (type === 'single') {
            roomData.selected = event.checked;
        }

        const onlyCheckedRooms = this.dataSource.filter(dataRoom => dataRoom.selected);
        if (!onlyCheckedRooms || onlyCheckedRooms.length === 0) {
            this.setValidatorsPhoneControl(false);
            this.setValidatorsEmailControl(false);

            if (this.checkAllControl.value) {
                this.checkAllControl.setValue(false);
            }

            this.f.get('selectedRooms').setValue(null);
        } else {
            const isRoomTypeEmail = onlyCheckedRooms.some(dataRoom => dataRoom.room.channel.id === 5 && dataRoom.selected);
            const isRoomTypeWhats = onlyCheckedRooms.some(dataRoom => dataRoom.room.channel.id === 1 && dataRoom.selected);

            isRoomTypeEmail ? this.setValidatorsEmailControl(true) : this.setValidatorsEmailControl(false);
            isRoomTypeWhats ? this.setValidatorsPhoneControl(true) : this.setValidatorsPhoneControl(false);

            this.f.get('selectedRooms').setValue(onlyCheckedRooms);
        }

    }

    setValidatorsPhoneControl(isRequired: boolean, touched: boolean = true) {
        const phoneControl = this.f.get('phone');
        const areaPhoneControl = this.f.get('areaPhone');
        const countryCodeControl = this.f.get('countryCode');
        if (isRequired) {
            phoneControl.setValidators([c => this.phoneValidator(c, 'phone'), Validators.required]);
            // areaPhoneControl.setValidators([c => this.phoneValidator(c, 'areaPhone'), Validators.required]);
            countryCodeControl.setValidators([c => this.phoneValidator(c, 'countryCode'), Validators.required]);
            this.lblPhone = 'Teléfono *';
        } else {
            this.lblPhone = 'Número';
            phoneControl.setValidators([c => this.phoneValidator(c, 'phone')]);
            //areaPhoneControl.setValidators([c => this.phoneValidator(c, 'areaPhone')]);
            countryCodeControl.setValidators([c => this.phoneValidator(c, 'countryCode')]);
        }

        if (touched) {
            phoneControl.markAsTouched();
            // areaPhoneControl.markAsTouched();
            countryCodeControl.markAsTouched();
        }

        phoneControl.updateValueAndValidity({ emitEvent: false });
        //areaPhoneControl.updateValueAndValidity();
        countryCodeControl.updateValueAndValidity({ emitEvent: false });
    }

    setValidatorsEmailControl(isRequired: boolean, touched: boolean = true) {
        const mailControl = this.f.get('email');
        if (isRequired) {
            mailControl.setValidators([Validators.pattern(this.regexEmail), Validators.required]);
            this.lblEmail = 'Email *';
        } else {
            mailControl.setValidators([Validators.pattern(this.regexEmail)]);
            this.lblEmail = 'Email';
        }

        if (touched) { mailControl.markAsTouched() }

        mailControl.updateValueAndValidity();
    }


    onSelectedCountryCode(e: any, countryCode: any) {
        if (e.isUserInput) {
            this.selectedCountryCode = countryCode ? countryCode : null;
        }
    }

    errorMessage() {
        return "Este campo es requerido"
    }

    get f() {
        return this.rform;
    }

}
