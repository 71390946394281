import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'beplic-warning-create-contact-dialog',
    templateUrl: './warning-create-contact-dialog.component.html',
    styleUrls: ['./warning-create-contact-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WarningCreateContactDialogComponent implements OnInit {
    contactFound: any;
    contactRooms: any[] = [];
    isContactInCurrentRoom: boolean = false;
    lblExistContact: string = 'Contacto Existente'

    constructor(public matDialogRef: MatDialogRef<WarningCreateContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public _matDialog: MatDialog) { }

    ngOnInit() {
        if (this.data) {
            this.contactFound = this.data['contactFound'];
            this.contactRooms = this.data['contactRooms'].filter(contactRoom => contactRoom['existContact']);
            const currentRoom = this.contactRooms.find(contactRoom => contactRoom.roomId === this.data.roomId);

            if (currentRoom) {
                this.isContactInCurrentRoom = true;
                this.lblExistContact = `Contacto existente en la sala ${currentRoom['roomName']}`
            }
        }
    }

    onSubmit(updateContact: boolean) {
        this.matDialogRef.close({ updateContact });
    }
}
