import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: 'input[onlyNumber]'
})
export class OnlyNumbersDirective {

    constructor(private _ref: ElementRef) { }

    @HostListener('input', ['$event']) toNumber(event: any) {
        const initalValue = this._ref.nativeElement.value;

        this._ref.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
        if (initalValue !== this._ref.nativeElement.value) {
            event.stopPropagation();
        }
    }

}
