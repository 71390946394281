import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ToolbarService {

  enableNotifyCallSource: BehaviorSubject<any>;

  constructor() {
    this.enableNotifyCallSource = new BehaviorSubject(null);
  }
}
