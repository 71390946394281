import { Injectable, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';
import { Todo } from 'app/main/apps/template/template.model';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { environment } from 'environments/environment'
import { LocalStorageService } from 'app/services/local-storage.service';

@Injectable({ providedIn: 'root' })
export class TemplateService implements Resolve<any> {
    todos: Todo[];
    selectedTodos: Todo[];
    currentTodo: Todo;
    searchText: string;
    filters: any[];
    tags: any[];
    routeParams: any;
    userProfile: CustomerAccount;
    roomsFake: any[];
    templates: Todo[];
    roomsMessengerPeople: any[];
    deleteButton: boolean = false;
    token: string;
    waba: string
    idRoom: any;
    findRoom: any
    lista: any
    @Output() sendType: EventEmitter<any> = new EventEmitter();
    @Output() sendState: EventEmitter<any> = new EventEmitter();

    onTodosChanged: BehaviorSubject<any>;
    onSelectedTodosChanged: BehaviorSubject<any>;
    onCurrentTodoChanged: BehaviorSubject<any>;
    onFiltersChanged: BehaviorSubject<any>;
    onTagsChanged: BehaviorSubject<any>;
    onSearchTextChanged: BehaviorSubject<any>;
    onNewTodoClicked: Subject<any>;
    onCategoryChanged: BehaviorSubject<any>;
    onLanguagesChanged: BehaviorSubject<any>;
    onTemplatesChanged: BehaviorSubject<any>;
    onTemplateUpdate: BehaviorSubject<any>;
    onIsLoadingTemplateList: BehaviorSubject<boolean>;
    onIsErrorTemplateList: BehaviorSubject<boolean>;
    onRoomChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {Location} _location
     */
    constructor(
        private _httpClient: HttpClient,
        private _location: Location,
        private _localStorageService: LocalStorageService
    ) {
        // Set the defaults
        this.selectedTodos = [];
        this.searchText = '';
        this.onTodosChanged = new BehaviorSubject([]);
        this.onSelectedTodosChanged = new BehaviorSubject([]);
        this.onCurrentTodoChanged = new BehaviorSubject([]);
        this.onFiltersChanged = new BehaviorSubject([]);
        this.onTagsChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new BehaviorSubject('');
        this.onNewTodoClicked = new Subject();
        this.onCategoryChanged = new BehaviorSubject([]);
        this.onLanguagesChanged = new BehaviorSubject([]);
        this.onTemplatesChanged = new BehaviorSubject([]);
        this.onTemplateUpdate = new BehaviorSubject([]);
        this.onIsLoadingTemplateList = new BehaviorSubject(false);
        this.onIsErrorTemplateList = new BehaviorSubject(false);
        this.onRoomChanged = new BehaviorSubject([]);
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.userProfile = this._localStorageService.getCurrentUser();

        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.loadTemplateList()
            ]).then(
                () => {
                    if (this.routeParams.todoId) {
                        this.setCurrentTodo(this.routeParams.todoId);
                    }
                    this.onSearchTextChanged.subscribe(searchText => {
                        if (searchText !== '') {
                            this.searchText = searchText;
                            this.getTodosByParams();
                        }
                        else {
                            this.searchText = searchText;
                            this.getTodos();
                        }
                    });
                    resolve('');
                },
                reject
            );
        });
    }

    /**
     * Get todos
     *
     * @returns {Promise<Todo[]>}
     */
    getTodos(): Promise<Todo[]> {
        // if ( this.routeParams.tagHandle )
        // {
        //     return this.getTodosByTag(this.routeParams.tagHandle);
        // }

        if (this.routeParams.filterHandle) {
            return this.getTodosByFilter(this.routeParams.filterHandle);
        }

        // return this.getTodosByParams(this.routeParams);
    }


    /**
     * Get todos by params
     *
     * @param handle
     * @returns {Promise<Todo[]>}
     */
    getTodosByParams(): Promise<Todo[]> {
        return new Promise((resolve, reject) => {
            this.todos = FuseUtils.filterArrayByString(this.todos, this.searchText);
            // this.todos = null;
            this.onTodosChanged.next(this.todos);
            resolve(this.todos);
        });
    }

    /**
     * Get todos by filter
     *
     * @param handle
     * @returns {Promise<Todo[]>}
     */
    getTodosByFilter(roomId): Promise<Todo[]> {
        var i = parseInt(roomId);
        return new Promise((resolve, reject) => {
            const found = this.filters.find(element => element.room.id === i);
            if (found) {
                this.todos = found.room.templates || [];
            } else {
                this.todos = null;
            }
            this.onTodosChanged.next(this.todos);
            resolve(this.todos)
        });
    }

    /**
     * Set current todo by id
     *
     * @param id
     */
    setCurrentTodo(id): void {
        this.currentTodo = this.todos.find(todo => {
            return todo.id === id;
        });

        this.onCurrentTodoChanged.next([this.currentTodo, 'edit']);

        const tagHandle = this.routeParams.tagHandle,
            filterHandle = this.routeParams.filterHandle;

        if (tagHandle) {
            this._location.go('apps/template/tag/' + tagHandle + '/' + id);
        }
        else if (filterHandle) {
            this._location.go('apps/template/filter/' + filterHandle + '/' + id);
        }
        else {
            this._location.go('apps/template/all/' + id);
        }
    }

    /**
     * Toggle tag on selected todos
     *
     * @param tagId
     */
    toggleTagOnSelectedTodos(tagId): void {
        this.selectedTodos.map(todo => {
            this.toggleTagOnTodo(tagId, todo);
        });
    }

    /**
     * Toggle tag on todo
     *
     * @param tagId
     * @param todo
     */
    toggleTagOnTodo(tagId, todo): void {
        const index = todo.tags.indexOf(tagId);

        if (index !== -1) {
            todo.tags.splice(index, 1);
        }
        else {
            todo.tags.push(tagId);
        }
    }

    /**
     * Has tag?
     *
     * @param tagId
     * @param todo
     * @returns {boolean}
     */
    hasTag(tagId, todo): any {
        if (!todo.tags) {
            return false;
        }

        return todo.tags.indexOf(tagId) !== -1;
    }

    createTemplateMessengerPeople(todo, idRoom): any {
        let url;
        const bodyTemplate = {
            token: this.token,
            payload: {
                name: todo.name,
                category: todo.category,
                waba_id: this.waba,
                translations: {
                    [todo.language]: [
                    ]
                }
            }
        }

        bodyTemplate.payload.translations[todo.language].push(
            {
                "type": "HEADER",
                'format': todo.headerType,
                'text': todo.headerText,
                'example': null
            },
            {
                "type": "BODY",
                'text': todo.bodyText,
                'example': null
            }
        )

        if (todo.footerText) {
            bodyTemplate.payload.translations[todo.language].push(
                {
                    "type": "FOOTER",
                    'text': todo.footerText
                },
            );
        }

        const dataBtn = {
            "type": "BUTTONS",
            "buttons": []
        }

        // Set botones
        let hasBtnQuickReply: boolean = false;
        const quickReplyList = [];

        for (const key in todo) {
            if (key.includes('quickReply') && todo[key]) {
                quickReplyList.push(todo[key]);
                hasBtnQuickReply = true;
            }
        }

        if (hasBtnQuickReply && todo['btnType'] === 'quick_reply') {
            quickReplyList.forEach(quickReply => {
                dataBtn.buttons.push({
                    "type": "QUICK_REPLY",
                    "text": quickReply,
                });
            })

        } else if (todo['btnType'] === 'cta') {
            if (todo['labelPhone'] !== null) {
                dataBtn.buttons.push({
                    "type": "PHONE_NUMBER",
                    "text": todo.labelPhone,
                    "phone_number": todo.botonPhone
                })
            }

            if (todo['labelUrl'] !== null) {
                dataBtn.buttons.push({
                    "type": "URL",
                    "text": todo.labelUrl,
                    "url": todo.botonUrl
                });
            }
        }

        if (dataBtn.buttons && dataBtn.buttons.length > 0) {
            bodyTemplate.payload.translations[todo.language].push(dataBtn);
        }

        const translations = bodyTemplate.payload.translations[todo.language];

        // Initialization of common fields to send a template with a variable
        if (todo['headerAmount'] > 0 || todo['bodyAmount'] > 0) {
            bodyTemplate["hasVar"] = 1;
        }

        if (todo['headerAmount'] > 0) {
            bodyTemplate["headerAmount"] = todo.headerAmount;
            bodyTemplate["parameterHeader"] = todo.parameterHeader;

            const header = translations.find(translation => translation.type === "HEADER");
            // Added example in header parameters
            if (header) {
                // Init property example
                header['example'] = {
                    header_text: null,
                    body_text: null
                }
                header.example['header_text'] = [bodyTemplate["parameterHeader"]];
            }
        }
        if (todo['bodyAmount'] > 0) {
            bodyTemplate["bodyAmount"] = todo.bodyAmount;
            bodyTemplate["parameter1Body"] = todo.parameter1Body;
            bodyTemplate["parameter2Body"] = todo.parameter2Body;
            bodyTemplate["parameter3Body"] = todo.parameter3Body;

            const body = translations.find(translation => translation.type === "BODY");
            // Added examples in body parameters
            if (body) {
                // Init property example
                body['example'] = {
                    header_text: null,
                    body_text: null
                }
                body.example['body_text'] = [];
                const bodyExamples = [];
                // Add example values in body field
                Object.entries(bodyTemplate).map(([key, value]) => {
                    if (key.match(/parameter(\d?)Body/gi) && value) {
                        bodyExamples.push(value);
                    }
                });
                body.example['body_text'].push(bodyExamples);
            }
        }

        if (todo['headerType'] !== 'TEXT') {
            bodyTemplate["hasVar"] = bodyTemplate["headerAmount"] = 1;
            bodyTemplate["parameterHeader"] = todo.mediaHeaderUrl;
        }

        return new Promise((resolve, reject) => {

            if (todo.language === 'en') {
                url = '/services/bepliccoreroom/api/template/en/create?idRoom=';
            }
            if (todo.language === 'es') {
                url = '/services/bepliccoreroom/api/template/es/create?idRoom='
            }

            this._httpClient.post(url + idRoom, bodyTemplate).subscribe((response: any) => {
                // Update template list
                this.loadTemplateList();
                resolve(response);
            }, err => {
                reject(err);
            });
        });
    }

    createTemplate(todo, idRoom): any {
        if (this.getRoomToken() === null || this.getRoomToken() === '') {
            this.createTemplateMessengerPeople(todo, idRoom)
        } else {
            const bodyTemplate = {
                token: this.token,
                payload: {
                    name: todo.name,
                    category: todo.category,
                    waba_id: this.waba,
                    translations: {
                        [todo.language]: [
                        ]
                    }
                }
            }

            let newTemplate = new NewTemplate();
            newTemplate.components = [];
            newTemplate.name = todo.name;
            newTemplate.category = todo.category;
            newTemplate.language = todo.language;

            newTemplate.components.push(
                {
                    "type": "HEADER",
                    'format': todo.headerType,
                    'text': todo.headerText,
                    'example': todo.headerHandle ? { "header_handle": [todo.headerHandle] } : (todo.parameterHeader ? { "header_text": [todo.parameterHeader] } : null)
                },
                {
                    "type": "BODY",
                    'text': todo.bodyText,
                    'example': null
                }
            )

            if (todo['headerAmount'] > 0 || todo['bodyAmount'] > 0) {
                todo["hasVar"] = 1;
            }

            if (todo['bodyAmount'] > 0) {

                const body = newTemplate.components.find(x => x.type === "BODY");
                // Added examples in body parameters
                if (body) {
                    // Init property example
                    body['example'] = {
                        header_text: null,
                        body_text: null
                    }
                    body.example['body_text'] = [];
                    const bodyExamples = [];
                    // Add example values in body field
                    Object.entries(todo).map(([key, value]) => {
                        if (key.match(/parameter(\d?)Body/gi) && value) {
                            bodyExamples.push(value);
                        }
                    });
                    body.example['body_text'].push(bodyExamples);
                }
            }

            if (todo.footerText) {
                newTemplate.components.push(
                    {
                        "type": "FOOTER",
                        'text': todo.footerText
                    },
                );
            }

            const templateButtons = {
                "type": "BUTTONS",
                "buttons": []
            }

            // Set botones
            let hasBtnQuickReply: boolean = false;
            const quickReplyList = [];

            for (const key in todo) {
                if (key.includes('quickReply') && todo[key]) {
                    quickReplyList.push(todo[key]);
                    hasBtnQuickReply = true;
                }
            }

            if (hasBtnQuickReply && todo['btnType'] === 'quick_reply') {
                quickReplyList.forEach(quickReply => {
                    templateButtons.buttons.push({
                        "type": "QUICK_REPLY",
                        "text": quickReply,
                    });
                })

            } else if (todo['btnType'] === 'cta') {
                if (todo['labelPhone'] !== null) {
                    templateButtons.buttons.push({
                        "type": "PHONE_NUMBER",
                        "text": todo.labelPhone,
                        "phone_number": todo.botonPhone
                    })
                }

                if (todo['labelUrl'] !== null) {
                    templateButtons.buttons.push({
                        "type": "URL",
                        "text": todo.labelUrl,
                        "url": todo.botonUrl
                    });
                }
            }

            if (templateButtons.buttons && templateButtons.buttons.length > 0) {
                newTemplate.components.push(templateButtons);
            }

            if (todo['headerType'] !== 'TEXT') {
                bodyTemplate["hasVar"] = bodyTemplate["headerAmount"] = 1;
                bodyTemplate["parameterHeader"] = todo.mediaHeaderUrl;
            }

            let newTemplateRequest = {
                "accessToken": this.getRoomToken(),
                "waba": this.waba,
                "new_template": newTemplate,
                "roomId": this.routeParams.filterHandle,
                "hasVar": todo.hasVar,
                "headerAmount": todo.headerAmount,
                "bodyAmount": todo.bodyAmount,
                "parameterHeader": todo.parameterHeader,
                "parameter1Body": todo.parameter1Body,
                "parameter2Body": todo.parameter2Body,
                "parameter3Body": todo.parameter3Body,
            }

            return new Promise((resolve, reject) => {
                this._httpClient.post("/services/bepliccoretemplates/api/template", newTemplateRequest).subscribe((response: any) => {
                    // Update template list
                    this.loadTemplateList();
                    resolve(response);
                }, err => {
                    reject(err);
                });
            });
        }
    }

    getRoomTokenAndList() {
        this.idRoom = this.routeParams.filterHandle;

        this.findRoom = this.filters.filter(e => {
            return e.room.id == this.idRoom
        })

        this.token = this.findRoom[0].room.tokenTemplate;

        this.lista = this.findRoom[0].room.templates.filter(e => {
            return e.status === "PENDING";
        })
    }

    getRoomToken() {
        this.idRoom = this.routeParams.filterHandle;
        this.findRoom = this.filters.filter(e => e.room.id == this.idRoom);
        return this.findRoom[0].room.appSecretPass;
    }

    getWaba() {
        this.idRoom = this.routeParams.filterHandle;
        this.findRoom = this.filters.filter(e => e.room.id == this.idRoom);
        return this.findRoom[0].room.waba;
    }

    deleteTemplate(todo): Promise<any> {
        if (this.getRoomToken() === null || this.getRoomToken() === '') {
            this.deleteTemplateMessengerPeople(todo)
        } else {
            return new Promise((resolve, reject) => {
                this.getRoomTokenAndList();
                const body = {
                    "accessToken": this.getRoomToken(),
                    "waba": this.getWaba()
                }
                const httpOptions = {
                    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                    body: body
                };

                this._httpClient.delete('/services/bepliccoretemplates/api/template/' + todo.name, httpOptions)
                    .subscribe(response => {
                        // Update template list
                        this.loadTemplateList();
                    });
            });
        }
    }

    deleteTemplateMessengerPeople(todo): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getRoomTokenAndList();
            const body = {
                id: todo.id,
                uuidTemplate: todo.uuidTemplate,
                token: this.token
            }
            const httpOptions = {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                body: body
            };

            this._httpClient.delete('/services/bepliccoreroom/api/delete/template', httpOptions)
                .subscribe(response => {
                    // Update template list
                    this.loadTemplateList();
                });
        });
    }

    validateKey(key): Promise<any> {
        const body = {
            name: key,
            roomId: this.routeParams.filterHandle
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post('/services/bepliccoreroom/api/template/findtemplateAndRoom', body).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getTokenAndWaba(idRoom) {
        this._httpClient.get('/services/bepliccoreroom/api/rooms/' + idRoom)
            .subscribe((sala: any) => {

                this.token = sala.tokenTemplate;
                this.waba = sala.waba;
                this.getCategory()
                this.getLanguages()
                //this.getTemplates()
            });
    }

    getCategory(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('/services/bepliccoretemplates/api/categories').subscribe((response: any) => {
                this.onCategoryChanged.next(response);
                resolve(response);
            }, reject);
        })
    }

    getLanguages(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('/services/bepliccoretemplates/api/languages').subscribe((response: any) => {
                this.onLanguagesChanged.next(response);
                resolve(response);
            }, reject);
        })
    }

    getTemplates(): Promise<Todo[]> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        })

        return new Promise((resolve, reject) => {
            this._httpClient.get<Todo[]>(environment.apiUrlMessengerPeople, { headers: headers }).subscribe((response) => {
                this.templates = response;
                this.onTemplatesChanged.next(response);
                resolve(response);
            }, reject)
        })
    }

    updateTemplate() {
        this.onIsLoadingTemplateList.next(true);
        if (this.getRoomToken() === null || this.getRoomToken() === '') {
            this.updateTemplateMessengerPeople()
        } else {
            this.getRoomTokenAndList();
            return new Promise((resolve, reject) => {
                this._httpClient.post('/services/bepliccoretemplates/api/update/templates?idRoom=' + this.idRoom, {})
                    .subscribe(response => {
                        // Update template list
                        this.loadTemplateList();
                    }, err => {
                        this.onIsLoadingTemplateList.next(false);
                        this.onIsErrorTemplateList.next(true);
                        reject();
                    });
            });
        }
    }

    updateTemplateStatus(roomId) {
        this.onIsLoadingTemplateList.next(true);
        this.findRoom = this.filters.filter(e => e.room.id == roomId);
        this.token = this.findRoom[0].room.tokenTemplate;
        this.lista = this.findRoom[0].room.templates.filter(e => e.status === 'PENDING');
        const appSecretPass = this.findRoom[0].room.appSecretPass;

        const apiUrl = appSecretPass === null || appSecretPass === '' ?
            '/services/bepliccoreroom/api/update/templates?idRoom=' + roomId :
            '/services/bepliccoretemplates/api/update/templates?idRoom=' + roomId;

        return new Promise((resolve, reject) => {
            this._httpClient.post(apiUrl, {})
                .subscribe(response => {
                    // Update template list
                    this.loadTemplateList();
                    resolve(response);
                }, err => {
                    this.onIsLoadingTemplateList.next(false);
                    this.onIsErrorTemplateList.next(true);
                    reject(err);
                });
        });
    }

    updateTemplateMessengerPeople() {
        this.getRoomTokenAndList();
        return new Promise((resolve, reject) => {
            this._httpClient.post('/services/bepliccoreroom/api/update/templates?idRoom=' + this.idRoom, {})
                .subscribe(response => {
                    // Update template list
                    this.loadTemplateList();
                }, err => {
                    this.onIsLoadingTemplateList.next(false);
                    this.onIsErrorTemplateList.next(true);
                    reject();
                });
        });
    }

    loadTemplateList() {
        this.onIsLoadingTemplateList.next(true);
        const page = 0;
        const size = 200;
        const body = {
            customeruserId: this.userProfile.id,
            customersId: this.userProfile.customerId
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(`/services/bepliccoreroom/api/findSala?page=${page}&size=${size}`, body).subscribe((response: any[]) => {
                this.onIsLoadingTemplateList.next(false);
                this.filters = [];
                // TODO: se filtra de forma temporal se debe armar un endpoint para filtrar salas que puedan crear templates
                if (response && response.length > 0) {
                    this.filters = response.filter(customerUserRoom => customerUserRoom.room.channel.id === 1);
                }
                this.onFiltersChanged.next(this.filters);
                this.getTodos();
                resolve(this.filters);
            }, err => {
                this.onIsLoadingTemplateList.next(false);
                reject()
            },);
        });
    }

    getFileIdentifier(multipartFile: File): Observable<string> {
        const formData: FormData = new FormData();
        formData.append('multipartFile', multipartFile);
        return this._httpClient.post('/services/bepliccoretemplates/api/get-file-identifier', formData, { responseType: 'text' });
    }

    getAvailablesTemplatesByRoom(roomId: number) {
        return this._httpClient.get(`/services/bepliccoretemplates/api/available/room/${roomId}/templates`);
    }
}

class NewTemplate {
    name: string
    category: string
    allow_category_change: boolean
    language: string
    components: Component[]
    constructor() { }
}

class Component {
    type: string;
    text?: string;
    buttons?: Button[];
    format?: string;
    example?: Example;
}

class Button {
    type: string;
    text: string;
    phone_number?: string;
    url?: string;
}

class Example {
    header_text?: string[];
    body_text?: string[][];
    header_handle?: string[];
}