import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'validate-file',
  templateUrl: './validate-file.component.html',
  styleUrls: ['./validate-file.component.scss']
})
export class ValidateFileComponent implements OnInit {
  @Input() idMsg: number;
  @Input() urlFile: string;
  @Output() invalidFile = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.validateFileLoad(this.urlFile);
  }

  async validateFileLoad(urlFile: string){
    if (urlFile) {
      try {
          const resp = await fetch(urlFile);
          if (!resp.ok) {
            this.invalidFile.emit(this.idMsg);
          }
        } catch (error) {
            this.invalidFile.emit(this.idMsg);
        }
    }

  }

}
