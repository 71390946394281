import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

    constructor() { }

    getCurrentUser(): any {
        return JSON.parse(localStorage.getItem('userLoginSession'));
    }

    setCurrentUser(userData: any) {
        localStorage.setItem('userLoginSession', JSON.stringify(userData));
    }
}
