import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DashboardService } from 'app/main/apps/dashboards/dashboard.service';
import * as pbi from "powerbi-client";

declare let powerbi: any;
const models = pbi.models;

@Component({
    selector: 'dashboard-powerbi',
    templateUrl: './dashboard-powerbi.component.html',
    styleUrls: ['./dashboard-powerbi.component.scss'],
    animations: fuseAnimations
})
export class DashboardPowerbiComponent implements OnInit {
    @ViewChild("contentDashboard", { static: false }) pbiContainer: ElementRef;
    @Input("customerId") customerId: number;
    @Input("userId") userId: number;
    @Input("isFromAdminPage") isFromAdminPage: boolean = true; // default value

    isLoading: boolean = true;
    isError: boolean = false;
    messageToAlert = {
        txtMain: "No se pudo cargar el dashboard",
        txtSub: "Por favor vuelva a intentarlo más tarde",
    };
    status: string = "ACTIVE"
    capacityStatus: any;
    isReportOff: boolean = false;
    currentDate = Date.now();
    // const horaActual = new Date().getHours();
    options: any = { timeZone: 'America/Argentina/Buenos_Aires' };
    horaActualArgentina: any = new Date().toLocaleString('en-US', this.options);
    horaActual: number = new Date(this.horaActualArgentina).getHours();
    diaSemana: number = new Date(this.horaActualArgentina).getDay();
    constructor(private dashboardService: DashboardService) { }
    ngOnInit() { }
    ngAfterViewInit(): void {
        if (this.customerId || this.userId) {
            if (this.diaSemana >= 1 && this.diaSemana <= 5 && this.horaActual >= 7 && this.horaActual < 19) {

                this.dashboardService.getDataPowerbi().subscribe((resp: any) => {

                    const reportId = resp.reportId;
                    const accessTokenAux = resp.embedToken;
                    const embedUrlPart = resp.embedReports[0].embedUrl;
                    const pageUrl = this.getUrlPowerbiEmbedded(this.customerId, this.userId, this.isFromAdminPage);
                    const embedUrlAux = embedUrlPart + pageUrl;
                    const config = {
                        type: 'report',
                        tokenType: models.TokenType.Embed,
                        id: reportId,
                        embedUrl: embedUrlAux,
                        accessToken: accessTokenAux,
                        permissions: models.Permissions.All,
                        settings: {
                            filterPaneEnabled: false,
                            navContentPaneEnabled: false
                        }
                    };
                    const report = powerbi.embed(this.pbiContainer.nativeElement, config);
                    // Handle Events
                    report.on('loaded', this.handleEvents.bind(this, 'loaded'));
                    report.on('rendered', this.handleEvents.bind(this, 'rendered'));
                    report.on('error', function (e) {
                        console.warn("Error:", e);
                    }
                    );

                }, err => {
                    console.error(err);
                    this.isLoading = false;
                    this.isError = true;

                })
            } else {
                this.isLoading = false;
                this.isReportOff = true;
            }

        } else {
            console.warn("customerId or userId not defined");
        }

    }

    handleEvents(eventType: 'loaded' | 'rendered') {
        this.isLoading = false;
    }

    private getUrlPowerbiEmbedded(customerId: number, userId: number, isAdminPage: boolean) {
        if (isAdminPage)
            return `&pageName=ReportSection&$filter=Customer/IdCliente eq ${customerId}`;
        else
            return `&pageName=ReportSectionac3fe25f12d1aa22e8d6&$filter=User/Id eq ${userId}`
    }
}
