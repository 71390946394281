import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';

@Injectable({ providedIn: 'root' })
export class RoomsService {
    private urlRooms = UrlList.URL_ROOMS;
    private urlConversationRoomsIntegra = UrlList.URL_CONVERSATIONS_ROOMS_INTEGRA;

    constructor(private _httpClient: HttpClient) { }

    getRoomsByCustomer(data: { customeruserId: number, customersId: number }) {
        return this._httpClient.post(`${this.urlConversationRoomsIntegra}/findRooms?page=0&size=200`, data);
    }

}
