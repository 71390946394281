import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {


  constructor(private _httpClient: HttpClient) {

  }

  getChannelActiveByCustomerUserIdObservable(customerUserId: number, active: boolean): Observable<any> {
    const params = new HttpParams()
      .set('customerUserId', customerUserId.toString())
      .set('active', active.toString());
    return this._httpClient.get(UrlList.URL_ROOMS + '/channelsByUser', { params });
  }

  getChannelActiveByCustomerUserIdPromise(customerUserId: number, active: boolean): Promise<any> {
    const params = new HttpParams()
      .set('customerUserId', customerUserId.toString())
      .set('active', active.toString());
    return new Promise((resolve, reject) => {
      this._httpClient.get(UrlList.URL_ROOMS + '/channelsByUser', { params })
        .subscribe((response: any) => {
          resolve(response);
        }, error => reject(error));
    });
  }
}
