import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { checkCurrentPartner } from 'app/shared/util/common-functions';

@Directive({
    selector: '[isCurrentPartner]'
})
export class CheckCurrentPartnerDirective {

    private partnerId: number;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainer: ViewContainerRef
    ) { }


    @Input()
    set isCurrentPartner(partnerId: number) {
        this.partnerId = partnerId;
        this.updateView();
    }


    updateView() {
        if (checkCurrentPartner(this.partnerId)) {
            this._viewContainer.createEmbeddedView(this._templateRef);
        } else {
            this._viewContainer.clear();
        }
    }
}
