import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { SwPush, SwUpdate } from '@angular/service-worker';

import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SocketService } from 'app/main/apps/chat/socket.service';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { LocalStorageService } from 'app/services/local-storage.service';
import { environment } from 'environments/environment';
import { NavigationRoomStatusService } from './navigation-room-status.service';

@Component({
  selector: 'fuse-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit {
  @Input()
  layout = 'vertical';
  @Input()
  navigation: any;
  userProfile: CustomerAccount;
  roomTypes = [
    { name: 'whatsapp', value: 1 },
    { name: 'web', value: 3 },
    { name: 'messenger', value: 2 },
    { name: 'email', value: 5 },
    { name: 'instagram', value: 6 },
  ];

  // Private
  private _unsubscribeAll: Subject<any>;
  readonly VAPID_PUBLIC_KEY = environment.VAPID_PUBLIC_KEY;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private socketService: SocketService,
    private _platform: Platform,
    private swPush: SwPush,
    private swUpdate: SwUpdate,
    private _localStorageService: LocalStorageService,
    private navigationRoomStatusService: NavigationRoomStatusService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.getUserProfile();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    // Solicitud de permisos para notificaciones
    if (this.checkPlataform()) {
      this.subscribeToNotificationsPush();
    }

    // Subscribe to the current navigation changes
    this._fuseNavigationService.onNavigationChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(navigationKey => {
        
        // Load the navigation
        this.navigation = navigationKey ? this._fuseNavigationService.getCurrentNavigation() : null;
        
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Subscribe to navigation item
    merge(this._fuseNavigationService.onNavigationItemAdded,this._fuseNavigationService.onNavigationItemUpdated,this._fuseNavigationService.onNavigationItemRemoved
    ).pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Suscribo a los eventos de las salas (nuevos mensajes, cambios de estado, etc.)
    this.suscribeToRoomNotifications();

  }

  suscribeToRoomNotifications(){

    this.roomTypes.forEach(element => {
      this.socketService[`${element.name}` + 'MethodCallSource']
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(msj => {
          
          // MANEJA LO NOTIFICACIONES PUSH Y CONTADORES DE SALAS
          if (msj) {
            const currentUser = this.userProfile;
            this.navigationRoomStatusService.notifyChatsStatus(msj);
  
            if (msj.chatMessage.customerId === currentUser.customerId) {
              const { messageType, text, chat } = msj.chatMessage;
          
              if (messageType === 'IN') {
                this.notifyNewMessages(currentUser, msj);
              } else if (messageType === 'OUT') {
                this.handleOutgoingMessage(text, chat);
              }
            }
          }
        });
    });
  }

  handleOutgoingMessage(text: string, chat: any): void {
    if (text === 'change_rooms_assigned') {
      this._fuseNavigationService.getRooms();
    }
    if (chat.state === "NOTVIEW") {
      this.notifyNewMessages(this.userProfile, { chatMessage: { chat } });
    }
  }

  notifyNewMessages(currentUser: CustomerAccount, msj: any){
    const isAdmin = currentUser.authorities.includes('ROLE_ADMIN');
    const isATC = currentUser.authorities.includes('ROLE_ATC');

    if (isAdmin) {
        this.notifyme();
        return;
    }

    if (isATC) {
        const roomsJson = sessionStorage.getItem('myRooms');
        if (!roomsJson) {
            return;
        }

        let rooms: any[];
        try {
            rooms = JSON.parse(roomsJson);
        } catch (error) {
            console.error('Error parsing rooms from sessionStorage', error);
            return;
        }

        const found = rooms.find(room => room.room.id === msj.chatMessage.chat.roomId);
        if (found) {
            const { customerUserId, modality } = msj.chatMessage.chat;
            if (!customerUserId || modality === 'OPEN' || customerUserId === currentUser.id) {
                this.notifyme();
            }
        }
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getUserProfile(): void {
    this.userProfile = this._localStorageService.getCurrentUser();
  }

  subscribeToNotificationsPush() {   
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('New version available. Load New Version?')) {
          window.location.reload();
        }
      });
    }

    this.swPush.requestSubscription({serverPublicKey: this.VAPID_PUBLIC_KEY,})
      .then(sub => {
        localStorage.setItem('subcription', JSON.stringify(sub));
      })
      .catch(err => {
        console.error(err);
        alert('No se pudo subcribir a las notificaciones');
      });
  }

  notifyme(): void {
    // if the platform is mobile
    if (this.checkPlataform()) {
      const sub = JSON.parse(localStorage.getItem('subcription'));
      if (sub) {
        this._fuseNavigationService.sendNotification(sub).subscribe(() => {});
      }
    } else {
      if (!('Notification' in window)) {
        alert('This browser does not support system notifications');
        // This is not how you would really do things if they aren't supported. :)
      }
      // Let's check whether notification permissions have already been granted
      if ((Notification as any).permission === 'granted') {
        // If it's okay let's create a notification
        const options = {
          body: '¡Tienes nuevos mensajes!',
          icon: 'https://i.ibb.co/HFncCXr/abejita-11.png',

          // requireInteraction:true,
          // renotify:true,
        };
        const n = new (Notification as any)('Beplic', options);
        // n.renotify
        // setTimeout(n.close.bind(n),5000);
      }
    }
  }

  checkPlataform() {
    return this._platform.ANDROID || this._platform.IOS;
  }
}
