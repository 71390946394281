import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable()
export class MailConfirmService implements Resolve<any> {

    resolve(): Promise<any> {
        return new Promise((resolve, reject) => {
            const currentUrl = window.location.href;

            if (currentUrl.startsWith(environment.URL_DOPPLER)) {
                document.body.classList.add('theme-doppler');
            } else {
                document.body.classList.add('theme-default');
            }

            resolve(null);
        });
    }
}