import { ChannelBot } from "./channel-bot";

export class Bot {
    id: number;
    botName: string;
    completed: boolean;
    active: boolean;
    pictureName: string;
    fanpageUser: string;
    channelBotId: number;
    customerBotId: number;
    appSecretPass: string;
    appIdentifier: string;
    phone: number;
    sexo: string;
    statusBotApi:string;
    channelBot:ChannelBot;
    notifications:number;
    constructor() {
        this.id = null;
        this.botName = null;
        this.completed = false;
        this.active = true;
        this.pictureName = 'bot_avatar.png';
        this.channelBotId = null;
        this.customerBotId = null;
        this.fanpageUser = null;
        this.appIdentifier = null;
        this.phone = null;
        this.appSecretPass = null;
        this.sexo = null;
    }
}
