import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarDopplerComponent } from 'app/layout/components/navbar-doppler/navbar-doppler.component';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';

import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    declarations: [
        VerticalLayout1Component,
        NavbarDopplerComponent,
    ],
    imports: [
        RouterModule,

        FuseSharedModule,
        FuseSidebarModule,

        ContentModule,
        FooterModule,
        NavbarModule,
        ToolbarModule,
        SharedModule
    ],
    exports: [
        VerticalLayout1Component,
    ]
})
export class VerticalLayout1Module {
}
