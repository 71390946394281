import { Injectable, EventEmitter, Output} from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
//import { HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
// import {ConfigurationTax} from '../class/configurationTax';
import { BehaviorSubject, Observable, Subject} from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { UrlService} from 'app/model';
// import { StorageManager } from 'app/public/security/storage-manager';
import { Client } from '../model/client.model';
import { UrlList } from './../url-list.enum';
import { Bot } from '../model/bot.model';
import { ContactService } from '../services/contact.service';
import { Chat } from '../model/chat.model';
import { CustomerAccount } from '../model/user.model';
import { Channel } from '../model/channel.model';
import { Group } from '../model/group.model';
import { ContactGroupName } from '../model/contact-group-name.model';

let check = 0;
// const JSOG = require("JSOG");

@Injectable()
export class SendDataService {
  private editSubject = new BehaviorSubject<Boolean>(false);
  private clientSubject = new BehaviorSubject<Client>(new Client());
  private botSubject = new BehaviorSubject<Bot>(new Bot());
  edit = this.editSubject.asObservable();
  client = this.clientSubject.asObservable();
  bot = this.botSubject.asObservable();
  // private token = StorageManager.retrieveToken();
  // private baseUrl = this.url.urlFinances + '/sealTax';
  private missingProfileSubject = new BehaviorSubject<Boolean>(false);
  missingProfile = this.missingProfileSubject.asObservable();
  public botSession: Bot;
  public chats: Chat[];
  public chat: Chat;
  public channel: Channel;
  url = UrlList.URL;
  user: CustomerAccount;
  firstBot: boolean;
  chatTable: Chat;
  currentChat: Chat;
  changeState = false;
  // bot websocket
  idBotws: number;
  idBotWeb = 0;
  evt = new BehaviorSubject<Boolean>(false);
  evtSource: Observable<any> = this.evt.asObservable();

  socketFlag  = true;

  notificationFlag  = true;

  repeatSocketConnection: boolean;
  groups: Group[] = [];

  createGroup = true;

  massiveContacts: Chat[] = [];

  currentGroup: Group;

  currentMassiveBot:number; 

  contactGroupName: ContactGroupName[] = [];
  isOpen = false;

//configuración del la app
  hostName:string;
  port:string;
  microservice:string;
/////////

  actualPage:number;

  finishPage:number;

  imageUrl:string;
  scrollFlag:boolean;

  emailResetPassword: string;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient,
    private contactService: ContactService
  ) {
    this.repeatSocketConnection = true;
     check++;
  }

  setSocket(flag: boolean) {
    this.idBotws = 2;
    this.repeatSocketConnection = flag;
  }

  scrollToTop(){
    this.isOpen = !this.isOpen;
    this.change.emit(this.isOpen);
  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.change.emit(this.isOpen);
  }
  changeBotSelected(botSelected: Bot) {
    this.botSubject = new BehaviorSubject<Bot>(new Bot());
    this.bot = this.botSubject.asObservable();
    this.botSubject.next(botSelected);
  }

  changeClientSelected(clientSelected: Client) {
    this.clientSubject = new BehaviorSubject<Client>(new Client());
    this.client = this.clientSubject.asObservable();
    this.clientSubject.next(clientSelected);
  }

  changeEdit(edit: Boolean) {
    this.editSubject.next(edit);
  }

  getSearchURL() {
    // return this.baseUrl + '/search';
  }

  setMissingProfile(missing: Boolean) {
    this.missingProfileSubject = new BehaviorSubject<boolean>(false);
    this.missingProfile = this.missingProfileSubject.asObservable();
    this.missingProfileSubject.next(missing);
  }

  getMissingProfile(): Observable<any> {
    return (this.missingProfile);
  }

  setBotSession(bot: Bot) {
    this.botSession = bot;
    /*this.contactService.getContactsByBot(bot.id).subscribe(
      (res:any)=>{
        this.chats = res;
      },
      err =>{
        console.log(err);
      }
    )*/
  }

  getContacts(id: number) {
    const c = 0;
    return c;
  }

  setUser(cust: CustomerAccount) {
    this.user = cust;
  }

  setFirstBot(data: boolean) {
    this.firstBot = data;
  }

  setChannel(channel: Channel) {
    this.channel = channel;
  }

  sendChat(chat: Chat) {
    this.chatTable = chat;
  }

  setBotWs(idBot: number) {
    this.idBotws = idBot;
  }

  setBotWeb(idBot: number) {
    this.idBotWeb = idBot;
  }

  setEvt(evt) {
    this.evt.next(evt)
  }

}
