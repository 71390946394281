import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'info-create-contact-dialog',
    templateUrl: './info-create-contact-dialog.component.html',
    styleUrls: ['./info-create-contact-dialog.component.scss']
})
export class InfoCreateContactDialogComponent implements OnInit {
    conflicRooms = [];
    roomsWithoutConflit = [];

    constructor(public matDialogRef: MatDialogRef<InfoCreateContactDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public _matDialog: MatDialog,) { }

    ngOnInit() {
        if (this.data) {
            this.conflicRooms = this.data.conflicRooms;
            this.roomsWithoutConflit = this.data.roomsWithoutConflit;
        }
    }

}
