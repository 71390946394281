import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { ContactsService } from '../contacts.service';


@Component({
    selector: 'business-form-dialog',
    templateUrl: './business-form.component.html',
    styleUrls: ['./business-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BusinessFormDialogComponent implements OnInit {
    action: string;
    contactForm: FormGroup;
    dialogTitle: string;

    myControl = new FormControl();

    /**
     * Constructor
     *
     * @param {MatDialogRef<BusinessFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<BusinessFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private contactService: ContactsService
    ) {
               // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Contact';
        }
        else {
            this.dialogTitle = 'New Contact';
        }

        this.contactForm = this.createContactForm();
    }

    ngOnInit(): void {
        
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createContactForm(): FormGroup {
        return this._formBuilder.group({
            id: [''],
            businessName: ['', Validators.required],
            cuit: [''],
            adress: ['']
        });
    }

    onCreateNewBusiness(): void {
        this.contactService.createEnterprise(this.contactForm.getRawValue()).subscribe(data => {
            this.matDialogRef.close(['save', data]);
        });
    }
}
