
import { Rol } from './rol.model';
import { Business } from './business.model';
import { Preferences } from './preferences.model';

export class CustomerAccount {
    id: number;
    firstName: string;
    lastName: string;
    avatar: 'assets/images/avatars/profile.jpg';
    // Account
    login: string;
    password: string;
    email: string;
    imageUrl: string;
    active: boolean;
    activated: boolean;
    // Customer
    userName: string;
    username: string;
    // username:string;
    du: string;
    businessName: string;
    fiscalNumber: string;
    business: Business;
    // businessId:number;
    // added
    paymentExpirationDate: Date;
    testExpirationDate: Date;
    registerDate: Date;
    // Undefined
    residence: string;
    cellphone: string;
    typeRegistration: boolean;
    customerId: number;
    // rolesId:number;
    uaaUserId: number;
    customerUserPreferences: Preferences[];
    originedByPartner: any;
    authorities: string[];
    roles: Rol;
    emailSignature: string;
    langKey: string;
    admEmail: string;
    path: string;
    providerUserId: string;
    customerConfigurations: any;
    frontConfigurations: any;
    plan: any;

    constructor() {
        this.id = undefined;
        this.firstName = undefined;
        this.username = undefined;
        this.lastName = undefined;
        this.active = undefined;
        this.emailSignature = undefined;
        this.langKey = undefined;
        // Account
        this.login = undefined;
        this.password = undefined;
        this.email = undefined;
        this.imageUrl = 'http://icons.iconarchive.com/icons/custom-icon-design/pretty-office-3/128/Edit-Male-User-icon.png';
        // Customer
        this.userName = undefined;
        this.du = undefined;
        this.businessName = undefined;
        this.fiscalNumber = undefined;
        this.paymentExpirationDate = undefined;
        this.testExpirationDate = undefined;
        this.registerDate = undefined;
        this.customerUserPreferences = [];
        this.originedByPartner = undefined;
        // Undefined
        this.residence = undefined;
        this.cellphone = undefined;
        this.typeRegistration = undefined;
        // this.rolesId = undefined;
        this.customerConfigurations = undefined;
    }
}
