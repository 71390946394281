export const environment = {
    production: false,
    apiUrl: 'http://localhost:8500',
    apiUrlUaa: 'http://localhost:9999',
    apiUrlCore: 'http://localhost:8501',
    apiUrlMessengerPeople: 'https://api.messengerpeople.dev/templates',
    hmr: false,
    // token: 'G-5PPJS58RGP',
    GA_TRACKING_ID: 'G-FDJDWQS9FZ',
    VAPID_PUBLIC_KEY: 'BPDPEPEMF-GVhPPtGwmYva_szQdTUgFjKIGG8qPJYY87uKRX82Pu47q2FbpOIMVmm1h5vulI24UHUIXbgSn-uR8',
    // TODO: cambiar por la URL real, y reemplazar aqui y en los enviroment.qa enviroment.prod
    URL_EXCEL_FILE_MODEL: 'https://qabeplic.s3.amazonaws.com/Plantilla+Whatsapp+-+Masivo+-+Uso+de+par%C3%A1metros.xlsx',
    META_APP_ID: '3786546154738749', // Beplic Comment
    META_APP_LOGIN_CONFIG: '7329399303790497', // Beplic Comment
    META_SRC_SDK_SCRIPT: 'https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v8.0&appId={%META_APP_ID%}&autoLogAppEvents=1',
    META_GRAPH_API_VERSION: 'v16.0',
    IS_ENABLED_DEBUG_SOCKETJS: true,
    IS_ENABLED_LOGGING: false,
    URL_BASE_DOPPLER_CONFIGURATION: 'https://appqa.fromdoppler.net',
    WSP_API_CLOUD_REQUEST_ENABLED: false,
    PERMANENT_TOKEN_WSP_BEPLIC: 'EAAHlAtiJA1ABO5I2u5Cqm0HddtXe7Myq1ZAInXJAsYMU80CCkuqTvvwpFfiv9MsPevL0seLCVBthrCd7J23ZA5Q35rzHVWKDrtiEddlgysbnjQxjKDZBfEGpfsKzRxlNs5TKWBxRW43n4zIRd7Oc0VpGjHhZAVuwErbZB3jqnKF8sIl5aqRe8eqZCxkWUkWY6S',
    META_PERMISSION_REQUEST_ENABLED: false,
    INSTAGRAM_REQUEST_ENABLED: false,
    MESSENGER_REQUEST_ENABLED: false,
    URL_DOPPLER: 'https://conversationsqa.fromdoppler.net',
    URL_DOPPLER_MAIN: 'https://webappqa.fromdoppler.net',
    URL_BEPLIC: 'https://dev5.beplic.io',
    DOPPLER_STYLE_ENABLED: false,
    CHATBOT_SCRIPT: 'assets/content/js/dev-beplic-external-chat.js',
    PARTNER_KEY_DOPPLER: 'ef3e19d248a4fd23ccf809835cc1c143',
    PATH_EXTERNAL_LOGIN: 'external-login'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
