import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';

import { LoginService } from 'app/main/pages/authentication/login/login.service';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { environment } from 'environments/environment';
import { LocalStorageService } from 'app/services/local-storage.service';

const IS_ENABLED_LOGGING = environment.IS_ENABLED_LOGGING;
const URL_DOPPLER_MAIN = environment.URL_DOPPLER_MAIN;
const URL_PROFILE_INTEGRA = UrlList.URL_PROFILE_INTEGRATED;


@Injectable({ providedIn: 'root' })
export class AccountService {

    private userProfile: any;
    private urlSecurity: string = UrlList.URL_SECURITY;
    private userAdminEvent = new Subject<boolean>();

    public isAdmin2 = new Subject<boolean>();
    isAdmin2$ = this.isAdmin2.asObservable();

    public isAdmin1 = new BehaviorSubject<boolean>(false);
    isAdmin1$ = this.isAdmin1.asObservable();

    constructor(
        private _http: HttpClient,
        private router: Router,
        private loginService: LoginService,
        private _localStorageService: LocalStorageService
    ) {
        this.checkDopplerSession();

    }

    checkDopplerSession() {
        window.addEventListener("doppler-session-state-update", () => {
            const dopplerSessionState = window['dopplerSessionState'];
            // Log only in non-production mode
            if (IS_ENABLED_LOGGING) { console.log("DopplerSession: ", dopplerSessionState); }
            // Get UserProfile
            this.userProfile = this.getUserData();
            // Check if the user logged in externally and ended it 
            if ((dopplerSessionState && dopplerSessionState.status === 'non-authenticated') && (this.userProfile && this.userProfile['isExternal'])) {
                this.endSessionDoppler();
            }
        });
    }

    endSessionDoppler() {
        const isRolAdmin = this.userProfile.authorities.includes('ROLE_ADMIN');
        // If you are an ADMIN user you are redirected to the doppler login
        if (isRolAdmin) {
            this.cleanDataAndLogout();
            window.location.href = `${URL_DOPPLER_MAIN}/login`;
            //this.router.navigateByUrl(`${URL_DOPPLER}/login`);
        } else {
            // If you are an ATC user you will be redirected to the beplic-doppler login
            this.defaultEndSession();
        }
    }

    private cleanDataAndLogout() {
        this.loginService.logout();
    }

    defaultEndSession() {
        Swal.fire({
            title: 'Atención!',
            text: '¡Tu tiempo de sesión ha finalizado, vuelve a loguearte!',
            type: 'info',
            confirmButtonColor: '#e2ae2f',
            confirmButtonText: 'Aceptar',
            width: '40rem'
        }).then((result) => {
            this.cleanDataAndLogout();
            this.router.navigateByUrl('pages/auth/login');
        });
    }

    verifyAccounyByExternalLogin(email: string) {
        const headers = new HttpHeaders().set('Skip-Authorization', 'true');
        return this._http.post(`${this.urlSecurity}/account/check-account/init`, email, { headers: headers })
    }

    private getUserData() {
        return this.userProfile = this._localStorageService.getCurrentUser();
    }

    // TODO: metodo deprecado
    emitUserAdminEvent(value: boolean) {
        this.userAdminEvent.next(value);
    }

    // TODO: metodo deprecado
    getUserAdminEvent() {
        return this.userAdminEvent.asObservable();
    }
}
