import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SocketService } from 'app/main/apps/chat/socket.service';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { LoginService } from 'app/main/pages/authentication/login/login.service';
import { LocalStorageService } from 'app/services/local-storage.service';

@Component({
    selector: 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: FuseNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;
    allRooms: any[] = [];
    chatsNotViewed: any[] = [];
    userProfile: CustomerAccount;
    chatsNotViewATC: any[];
    roomTypes = [{ name: 'whatsapp', value: 1 }, { name: 'web', value: 3 }, { name: 'messenger', value: 2 }, { name: 'email', value: 5 }, { name: 'instagram', value: 6 }];

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private socketService: SocketService,
        private loginService: LoginService,
        private router: Router,
        private _localStorageService: LocalStorageService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.chatsNotViewATC = [];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getUserProfile();

        if (this.item.id === 'chat') {
            this.item.badge.title = '0';
        }

        if (this.item.id === 'chat') {
            this.roomTypes.forEach(element => {
                this.socketService[`${element.name}` + 'MethodCallSource']
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((msj) => {
                        if (msj) {
                            if (this.item.id === 'chat') {

                                if (this.userProfile.authorities.includes('ROLE_ADMIN')) {
                                    this.updateNotViewedChats(msj);
                                } else if (this.userProfile.authorities.includes('ROLE_ATC') || this.userProfile.authorities.includes('ROLE_SUPERVISOR')) {

                                    const room = JSON.parse(sessionStorage.getItem('myRooms')).find(room => room.room.id === msj.chatMessage.chat.roomId);
                                    if (!msj.chatMessage.chat.customerUserId || room.modality === 'OPEN') {
                                        this.updateNotViewedChats(msj);
                                    } else if (msj.chatMessage.chat.customerUserId === this.userProfile.id) {
                                        this.updateNotViewedChats(msj);
                                    }
                                }

                            }
                        }
                    });
            });
        }

        this._fuseNavigationService._onLoadRooms
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((chats) => {
                this.chatsNotViewATC = [];
                if (this.item.id === 'chat' && chats) {
                    if (this.userProfile.authorities.includes('ROLE_ADMIN')) {
                        this.chatsNotViewed = chats || [];
                        this.item.badge.title = chats.length ? chats.length.toString() : '0';
                        this._changeDetectorRef.markForCheck();
                        this.socketService.setMobileNotification(this.item.badge.title);
                    } else if (this.userProfile.authorities.includes('ROLE_ATC') || this.userProfile.authorities.includes('ROLE_SUPERVISOR')) {
                        chats.forEach(chat => {
                            // if (!chat.customerUserId || chat.modality === 'OPEN') {
                            if (!chat.customerUserId) {
                                this.chatsNotViewATC.push(chat);
                            } else if (chat.customerUserId === this.userProfile.id) {
                                this.chatsNotViewATC.push(chat);
                            }
                        });

                        this.chatsNotViewed = this.chatsNotViewATC || [];
                        this.item.badge.title = this.chatsNotViewATC.length.toString();
                        this._changeDetectorRef.markForCheck();
                        this.socketService.setMobileNotification(this.item.badge.title);
                    }
                }

            });


        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        this.socketService.businessMethodCallSource.pipe(takeUntil(this._unsubscribeAll)).subscribe((evt => {
            if (evt) {
                this.onBusinessEvent(evt);
            }
        }));

    }


    getUserProfile(): void {
        this.userProfile = this._localStorageService.getCurrentUser();;
    }

    updateNotViewedChats(msj): void {
        const idx = this.chatsNotViewed.findIndex(x => x.id === msj.chatMessage.chat.id);

        if (msj.chatMessage.messageType === 'OUT' && msj.chatMessage.chat.state !== 'NOTVIEW') {
            // if(this.chatsNotViewATC) {
            // const idx = this.chatsNotViewed.findIndex(x => x.id === msj.chatMessage.chat.id);
            if (idx !== -1) {
                this.chatsNotViewed.splice(idx, 1);
                this.item.badge.title = this.chatsNotViewed.length.toString();
                this._changeDetectorRef.markForCheck();

                this.socketService.setMobileNotification(this.item.badge.title); // descuento el contador general
                this.socketService.sendMobileNotification('out_message', msj);
                this.socketService.sendMobileNotification('nulllll', msj);
            }
            /*          for (var i = 0; this.chatsNotViewed.length > i; i++) {
                         if (this.chatsNotViewed[i].id === msj.chatMessage.chat.id) {
                             this.chatsNotViewed.splice(i, 1);
                         }
                     } */
            // }
        } else if (msj.chatMessage.messageType === 'IN') {
            // if(this.chatsNotViewATC){
            // const found = this.chatsNotViewed.find(chat => chat.id === msj.chatMessage.chat.id);
            if (idx === -1) {
                this.chatsNotViewed.push(msj.chatMessage.chat);
                this.item.badge.title = this.chatsNotViewed.length.toString();
                this._changeDetectorRef.markForCheck();
                this.socketService.setMobileNotification(this.item.badge.title); // aumento el contador general
                this.socketService.sendMobileNotification('new_message', msj);
                this.socketService.sendMobileNotification('nulllll', msj);
            }
            //  }
        }
    }

    pushRooms(chats): void {
        chats.forEach(chat => {
            // this.allRooms.push(chat);
        });
    }

    onBusinessEvent(evt): void {
        if (evt.chatMessage.customerUserId === this.userProfile.id && evt.chatMessage.text === 'on_role_changed_to_admin') {
            setTimeout(() => {
                Swal.fire({
                    title: 'Atención!',
                    text: '¡Tu rol ha sido cambiado a ADMINISTRADOR, vuelve a loguearte por favor!',
                    type: 'info',
                    confirmButtonColor: '#e2ae2f',
                    confirmButtonText: 'Aceptar',
                    width: '40rem'
                }).then((result) => {
                    this.socketService.disconnect();
                    this.loginService.logout();
                    this.router.navigateByUrl('pages/auth/login');
                });
            }, 500);

        }
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.chatsNotViewed = null;
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    selectedItem(item: FuseNavigationItem) {
        if (item.childrenType === 'room') {
            const customerUserRooms: any[] = this.getCustomerUserRooms();
            const currentRoom = customerUserRooms.find(customerUserRoom => customerUserRoom.room.id === (+item.id));
            // Set Current Room
            if (currentRoom) {
                if (JSON.parse(sessionStorage.getItem('currentRoom'))) {
                    sessionStorage.removeItem('currentRoom');
                }
                sessionStorage.setItem('currentRoom', JSON.stringify(currentRoom));
            } else {
                console.warn("No room found to set as current")
            }
        }
    }

    private getCustomerUserRooms(): any[] {
        return JSON.parse(sessionStorage.getItem('myRooms')) || [];
    }
}
