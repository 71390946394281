import { EventEmitter, Component, HostBinding, Input, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
    selector: 'beplic-assig-flow-list-item',
    templateUrl: './assig-flow-list-item.component.html',
    styleUrls: ['./assig-flow-list-item.component.scss']
})
export class AssigFlowListItemComponent implements OnInit {

    @Input() mail: any;
    @HostBinding('class.selected')
    selected: boolean;
    @Output() selectedItem = new EventEmitter<any>();

    labels: any[];
    roomsImages = {
        '1': 'Whatsapp_generic.png',
        '2': 'twitter_generic.png',
        '3': 'roomWeb_generic.png',
        '4': 'messenger_generic.png',
        '5': 'email_generic.png',
        '6': 'instagram_generic.png'
    };

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor() { }

    ngOnInit() {

    }

    checkedItem(room: any) {
        this.selectedItem.emit(room);
    }

    getChannelIcon(channelId: number) {
        return this.roomsImages[channelId + ''] || "roomWeb_generic.png";
    }
}
