import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FuseUtils } from '@fuse/utils';
import { Todo } from 'app/main/apps/todo/todo.model';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { LocalStorageService } from 'app/services/local-storage.service';
import { TemplateService } from '../template/template.service';

@Injectable()
export class TodoService implements Resolve<any> {
    todos: Todo[];
    selectedTodos: Todo[];
    currentTodo: Todo;
    searchText: string;
    filters: any[];
    tags: any[];
    routeParams: any;
    room: any;
    mobileRoomNotifications: any;
    groups: any;
    usersByCustumer: any;

    private urlRooms = UrlList.URL_ROOMS;
    private conversationsRoomssIntegra = UrlList.URL_CONVERSATIONS_ROOMS_INTEGRA;
    private urlContacts = UrlList.URL_CONTACTS;

    onTodosChanged: BehaviorSubject<any>;
    onSelectedTodosChanged: BehaviorSubject<any>;
    onCurrentTodoChanged: BehaviorSubject<any>;
    onFiltersChanged: BehaviorSubject<any>;
    onTagsChanged: BehaviorSubject<any>;
    onSearchTextChanged: BehaviorSubject<any>;
    onNewTodoClicked: Subject<any>;
    onSelectedRoom: BehaviorSubject<any>;
    onMobileNotification: BehaviorSubject<any>;
    onLoadGroups: BehaviorSubject<any>;
    onCurrentMailChanged: BehaviorSubject<any>;
    onLoadTemplateList: BehaviorSubject<any[]>;

    userProfile: CustomerAccount = new CustomerAccount();

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {Location} _location
     */
    constructor(
        private _httpClient: HttpClient,
        private _location: Location,
        private _localStorageService: LocalStorageService,
        private _templateService: TemplateService
    ) {
        // Set the defaults
        this.selectedTodos = [];
        this.searchText = '';
        this.onTodosChanged = new BehaviorSubject([]);
        this.onSelectedTodosChanged = new BehaviorSubject([]);
        this.onCurrentTodoChanged = new BehaviorSubject([]);
        this.onFiltersChanged = new BehaviorSubject([]);
        this.onTagsChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new BehaviorSubject('');
        this.onNewTodoClicked = new Subject();
        this.onSelectedRoom = new BehaviorSubject(null);
        this.onMobileNotification = new BehaviorSubject(null);
        this.onLoadGroups = new BehaviorSubject(null);
        this.onCurrentMailChanged = new BehaviorSubject(null);
        this.onLoadTemplateList = new BehaviorSubject([]);
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.getUserProfile();
        this.routeParams = route.params;
        this.getAvailableTemplatesByRoomProm(this.routeParams.filterId); //
        // Add only dependent method to load the route
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getUsersByCustomer(this.userProfile.customerId),
                this.init()
            ]).then(
                ([]) => {
                    this.getLabels();

                    this.onSearchTextChanged.subscribe(searchText => {
                        if (searchText !== '') {
                            this.searchText = searchText;
                        }
                        else {
                            this.searchText = searchText;
                        }
                    });
                    resolve(null);
                },
                reject
            );
        });
    }

    getLabels(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlContacts + UrlList.CUSTOMER_LABELS + this.userProfile.customerId).subscribe((response: any) => {

                const labels = response.sort((a, b) => {
                    const nameA = a.label.name.toLowerCase();
                    const nameB = b.label.name.toLowerCase();
                    if (nameA < nameB)
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0;
                })

                if (JSON.parse(sessionStorage.getItem('myLabels'))) {
                    sessionStorage.removeItem('myLabels');
                    sessionStorage.setItem('myLabels', JSON.stringify(labels));
                } else {
                    sessionStorage.setItem('myLabels', JSON.stringify(labels));
                }
                resolve(labels);
            }, reject);
        });
    }

    assignLabel(label): Promise<any> {
        const lbl = [label];
        const body = {
            contactId: JSON.parse(sessionStorage.getItem('currentChat')).contactId,
            label: lbl
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlContacts + UrlList.ADD_LABEL, body).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    assignLabelFromContacts(label, contact): Promise<any> {
        const lbl = [label];
        const body = {
            contactId: contact.id,
            label: lbl
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlContacts + UrlList.ADD_LABEL, body).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    getUserProfile(): void {
        this.userProfile = this._localStorageService.getCurrentUser();
    }
    /**
     * Get all filters
     *
     * @returns {Promise<any>}
     */
    getFilters(): Promise<any> {
        const body = {
            customeruserId: this.userProfile.id,
            customersId: this.userProfile.customerId
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.conversationsRoomssIntegra + UrlList.GET_ROOMS_WITH_CONVERSATIONS_NOT_VIEW, body).subscribe((response: any) => {
                this.filters = response;
                this.onFiltersChanged.next(this.filters);
                const currentRoom = this.filters.find(r => r.room.id === +this.routeParams.filterId);
                if (currentRoom) {
                    this.setCurrentRoomNumber(currentRoom);
                } else {
                    this.setCurrentRoomNumber(null);
                }

                resolve(this.filters);
            }, reject);
        });
    }

    getChatsNotViewed(rooms) {
        // rooms.forEach(room => {
        //     const idRoom = {
        //         id:room.room.id
        //     }

        //     this.room.push(idRoom);
        //     const body = {
        //         room:rooms
        //     }
        //     console.log(body);
        //         return new Promise((resolve, reject) => {
        //                 this._httpClient.post(this.urlConversation + UrlList.GET_CHATS_BY_ROOMS,body)
        //                 .subscribe((chats: any) => {   
        //                     console.log()
        //                     resolve(chats);
        //                     // this._onLoadRooms.next(chats);
        //                 }, reject);

        //         });

        // });
    }

    getAssignedRooms(customeruserId: number, customersId: number): Observable<any> {

        const body = {
            customeruserId: customeruserId,
            customersId: customersId
        };

        return this._httpClient.post(this.urlRooms + UrlList.GET_CUSTOMER_ROOMS, body);
    }

    setCurrentRoomNumber(room: any): void {
        if (JSON.parse(sessionStorage.getItem('currentRoom'))) {
            sessionStorage.removeItem('currentRoom');
        }
        sessionStorage.setItem('currentRoom', JSON.stringify(room));

        this.onSelectedRoom.next(room);
    }

    sendMobileNotification(msj: any): void {
        this.onMobileNotification.next(msj);
    }

    setCurrentRoom(room: any): void {
        this.room = room;
    }

    getCurrentRoom(): void {
        return this.room;
    }

    /**
     * Get all tags
     *
     * @returns {Promise<any>}
     */
    getTags(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/todo-tags')
                .subscribe((response: any) => {
                    this.tags = response;
                    this.onTagsChanged.next(this.tags);
                    resolve(this.tags);
                }, reject);
        });
    }

    /**
     * Get todos
     *
     * @returns {Promise<Todo[]>}
     */
    getTodos(): Promise<Todo[]> {
        if (this.routeParams.tagHandle) {
            return this.getTodosByTag(this.routeParams.tagHandle);
        }

        if (this.routeParams.filterHandle) {
            return this.getTodosByFilter(this.routeParams.filterHandle);
        }

        return this.getTodosByParams(this.routeParams);
    }

    /**
     * Get todos by params
     *
     * @param handle
     * @returns {Promise<Todo[]>}
     */
    getTodosByParams(handle): Promise<Todo[]> {
        return new Promise((resolve, reject) => {

            this._httpClient.get('api/todo-todos')
                .subscribe((todos: any) => {
                    this.todos = todos.map(todo => {
                        return new Todo(todo);
                    });

                    this.todos = FuseUtils.filterArrayByString(this.todos, this.searchText);

                    this.onTodosChanged.next(this.todos);

                    resolve(this.todos);
                });
        });
    }

    /**
     * Get todos by filter
     *
     * @param handle
     * @returns {Promise<Todo[]>}
     */
    getTodosByFilter(handle): Promise<Todo[]> {

        let param = handle + '=true';

        if (handle === 'dueDate') {
            param = handle + '=^$|\\s+';
        }

        return new Promise((resolve, reject) => {

            this._httpClient.get('api/todo-todos?' + param)
                .subscribe((todos: any) => {

                    this.todos = todos.map(todo => {
                        return new Todo(todo);
                    });

                    this.todos = FuseUtils.filterArrayByString(this.todos, this.searchText);

                    this.onTodosChanged.next(this.todos);

                    resolve(this.todos);

                }, reject);
        });
    }

    /**
     * Get todos by tag
     *
     * @param handle
     * @returns {Promise<Todo[]>}
     */
    getTodosByTag(handle): Promise<Todo[]> {
        return new Promise((resolve, reject) => {
            this._httpClient.get('api/todo-tags?handle=' + handle)
                .subscribe((tags: any) => {

                    const tagId = tags[0].id;

                    this._httpClient.get('api/todo-todos?tags=' + tagId)
                        .subscribe((todos: any) => {

                            this.todos = todos.map(todo => {
                                return new Todo(todo);
                            });

                            this.todos = FuseUtils.filterArrayByString(this.todos, this.searchText);

                            this.onTodosChanged.next(this.todos);

                            resolve(this.todos);

                        }, reject);
                });
        });
    }

    /**
     * Toggle selected todo by id
     *
     * @param id
     */
    toggleSelectedTodo(id): void {
        // First, check if we already have that todo as selected...
        if (this.selectedTodos.length > 0) {
            for (const todo of this.selectedTodos) {
                // ...delete the selected todo
                if (todo.id === id) {
                    const index = this.selectedTodos.indexOf(todo);

                    if (index !== -1) {
                        this.selectedTodos.splice(index, 1);

                        // Trigger the next event
                        this.onSelectedTodosChanged.next(this.selectedTodos);

                        // Return
                        return;
                    }
                }
            }
        }

        // If we don't have it, push as selected
        this.selectedTodos.push(
            this.todos.find(todo => {
                return todo.id === id;
            })
        );

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedTodos.length > 0) {
            this.deselectTodos();
        }
        else {
            this.selectTodos();
        }

    }

    /**
     * Select todos
     *
     * @param filterParameter
     * @param filterValue
     */
    selectTodos(filterParameter?, filterValue?): void {
        this.selectedTodos = [];

        // If there is no filter, select all todos
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedTodos = this.todos;
        }
        else {
            this.selectedTodos.push(...
                this.todos.filter(todo => {
                    return todo[filterParameter] === filterValue;
                })
            );
        }

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }

    /**
     * Deselect todos
     */
    deselectTodos(): void {
        this.selectedTodos = [];

        // Trigger the next event
        this.onSelectedTodosChanged.next(this.selectedTodos);
    }

    /**
     * Set current todo by id
     *
     * @param id
     */
    setCurrentTodo(id): void {
        this.currentTodo = this.todos.find(todo => {
            return todo.id === id;
        });

        this.onCurrentTodoChanged.next([this.currentTodo, 'edit']);

        const tagHandle = this.routeParams.tagHandle,
            filterHandle = this.routeParams.filterHandle;

        if (tagHandle) {
            this._location.go('apps/todo/tag/' + tagHandle + '/' + id);
        }
        else if (filterHandle) {
            this._location.go('apps/todo/filter/' + filterHandle + '/' + id);
        }
        else {
            this._location.go('apps/todo/all/' + id);
        }
    }

    /**
     * Toggle tag on selected todos
     *
     * @param tagId
     */
    toggleTagOnSelectedTodos(tagId): void {
        this.selectedTodos.map(todo => {
            this.toggleTagOnTodo(tagId, todo);
        });
    }

    /**
     * Toggle tag on todo
     *
     * @param tagId
     * @param todo
     */
    toggleTagOnTodo(tagId, todo): void {
        const index = todo.tags.indexOf(tagId);

        if (index !== -1) {
            todo.tags.splice(index, 1);
        }
        else {
            todo.tags.push(tagId);
        }

        this.updateTodo(todo);
    }

    /**
     * Has tag?
     *
     * @param tagId
     * @param todo
     * @returns {boolean}
     */
    hasTag(tagId, todo): any {
        if (!todo.tags) {
            return false;
        }

        return todo.tags.indexOf(tagId) !== -1;
    }

    /**
     * Update the todo
     *
     * @param todo
     * @returns {Promise<any>}
     */
    updateTodo(todo): any {
        return new Promise((resolve, reject) => {

            this._httpClient.post('api/todo-todos/' + todo.id, { ...todo })
                .subscribe(response => {

                    this.getTodos().then(todos => {

                        resolve(todos);

                    }, reject);
                });
        });
    }

    getGroups(roomSelected): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlContacts + '/gruposbyroom/' + roomSelected.room.id)
                .subscribe((response: any) => {
                    this.groups = response.sort((a, b) => {
                        const nameA = a.name.toLowerCase();
                        const nameB = b.name.toLowerCase();
                        if (nameA < nameB)
                            return -1;
                        if (nameA > nameB)
                            return 1;
                        return 0;
                    });

                    // TODO: probar, porque da error por exceso de memoria local
                    // sessionStorage.removeItem('myGroups');
                    // sessionStorage.setItem('myGroups', JSON.stringify(this.groups));

                    this.onLoadGroups.next(this.groups);
                    resolve(this.groups);
                }, reject);
        });
    }

    updateGroup(group): Promise<any> {
        const cont = group.contacts.map(cont => {
            return { id: cont.id };
        });
        const body = {
            id: group.id,
            name: group.name,
            active: group.active,
            createdBy: group.createdBy,
            createdDate: group.createdDate,
            roomId: group.roomId,
            contacts: cont
        };
        return new Promise((resolve, reject) => {
            this._httpClient.put(this.urlContacts + '/grupos', body)
                .subscribe(response => {
                    // this.updateLocalStorage().then(todos => {
                    resolve(response);

                    // }, reject);
                }, reject);
        });
    }

    getUsersByCustomer(customerId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`services/beplicoreuser/api/customer-users/customerId/${customerId}`)
                .subscribe((response: any[]) => {
                    this.usersByCustumer = null;

                    this.usersByCustumer = response.reduce((acc, elem) => { return { ...acc, [elem.id]: elem } }, {});

                    resolve(response)
                }, reject)
        })
    }

    setCurrentMail(mail) {
        this.onCurrentMailChanged.next(mail);
    }

    getVarUsersByCustomer() {
        return this.usersByCustumer;
    }

    filterUsersByMailRoom(data: any): Observable<any> {
        return this._httpClient.post<any>("/services/bepliccoreconversationroomsintegra/api/integra/contacts/findContactInChannelEmail", data);
    }

    getAvailableTemplatesByRoomProm(roomId: number) {
        return new Promise((resolve, reject) => {
            this._templateService.getAvailablesTemplatesByRoom(roomId)
                .subscribe((resp: any[]) => {
                    this.onLoadTemplateList.next(resp);
                    resolve(true);
                }, err => reject(err))

        })
    }

    init() {
        return new Promise((resolve, reject) => {
            const customerUserRoom: any = JSON.parse(sessionStorage.getItem("currentRoom"));
            const customerUserRooms: any[] = JSON.parse(sessionStorage.getItem("myRooms"));

            // Send customerUserRoom in subject
            if (customerUserRoom) {
                this.onSelectedRoom.next(customerUserRoom);
            } else {
                this.onSelectedRoom.next(null);
            }
            // Send customerUserRooms in subject
            if (customerUserRooms) {
                this.onFiltersChanged.next(customerUserRooms);
            }

            resolve(true);
        })
    }

}
