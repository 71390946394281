import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Contact } from 'app/main/apps/contacts/contact.model';
import { BusinessFormDialogComponent } from '../contact-form copy/business-form.component';
import { ContactsService } from '../contacts.service';
import { LocalStorageService } from 'app/services/local-storage.service';

const MAX_LENGTH_FIELDS = 100;

@Component({
    selector: 'contacts-contact-form-dialog',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ContactsContactFormDialogComponent implements OnInit {
    action: string;
    contact: Contact;
    contactForm: FormGroup;
    dialogTitle: string;
    dialogRef: any;

    myControl = new FormControl();

    options: string[] = [];
    filteredOptions: Observable<string[]>;
    businessFilteredOptions: Observable<string[]>;
    options2: any[] = [];
    maxLengthFields = MAX_LENGTH_FIELDS;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<ContactsContactFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private contactService: ContactsService,
        private _localStorageService: LocalStorageService,
        public _matDialog: MatDialog,
    ) {
        this.contactService.getSuggestedAliases(this._localStorageService.getCurrentUser().customerId).then(data => {
            this.options = data;
        });

        this.contactService.getSuggestedBusiness(this._localStorageService.getCurrentUser().customerId).subscribe(data => {
            this.options2 = data || [];
            this.subscribeToBusinessControlChange();
        });
        // Set the defaults
        this.action = _data.action;

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Contact';
            this.contact = _data.contact;
        }
        else {
            this.dialogTitle = 'New Contact';
            this.contact = new Contact({});
        }

        this.contactForm = this.createContactForm();
    }

    ngOnInit(): void {
        this.filteredOptions = this.contactForm.get('alias').valueChanges
            .pipe(startWith(''), map(value => this._filter(value)));
    }


    subscribeToBusinessControlChange(): void {
        this.businessFilteredOptions = this.contactForm.get('business').valueChanges
            .pipe(startWith(''), map(value => this._filter2(value)));
    }


    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }

    private _filter2(value: any): string[] {
        const filterValue = value.toLowerCase();
        return this.options2.filter(option => option.name.toLowerCase().includes(filterValue));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createContactForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.contact.id],
            alias: [this.contact.alias, Validators.maxLength(MAX_LENGTH_FIELDS)],
            name: [this.contact.name],
            firstName: [this.contact.firstName || this.contact.name, [Validators.required, Validators.maxLength(MAX_LENGTH_FIELDS)]],
            lastName: [this.contact.lastName, [Validators.required, Validators.maxLength(MAX_LENGTH_FIELDS)]],
            avatar: [this.contact.avatar],
            nickname: [this.contact.nickname],
            company: [this.contact.company],
            jobTitle: [this.contact.jobTitle],
            email: [this.contact.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
            phone: [this.contact.phone],
            address: [this.contact.address],
            birthday: [this.contact.birthday],
            notes: [this.contact.notes],
            creationDate: [this.contact.creationDate],
            userfId: [this.contact.userfId ? this.contact.userfId : null],
            business: [this.contact.enterpriseName, Validators.maxLength(MAX_LENGTH_FIELDS)]
        });
    }

    onCreateNewBusiness(): void {
        this.dialogRef = this._matDialog.open(BusinessFormDialogComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }

                this.options2.push(response[1]);
                const actionType: string = response[0];
                const formData: any = response[1];
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':
                        this.contactForm.controls['business'].setValue(formData.name);

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        // this.deleteContact(contact);

                        break;
                }
            });
    }

    save(): void {
        const business = this.options2.find(op => op.name === this.contactForm.get('business').value);
        this.matDialogRef.close(['save', this.contactForm, business]);
    }
}
