import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CampaignService {
    private urlCampaign = UrlList.URL_CAMPAIGN;

    onMediaFileChanged: BehaviorSubject<any>

    constructor(
        private _httpClient: HttpClient
    ) { 
        this.onMediaFileChanged = new BehaviorSubject([]);
    }

    validateCampaing(data) {
        return this._httpClient.post<any>(`${this.urlCampaign + "/validate/campaign"}`, data);
    }

    saveCampaign(scheduledMessage: any) {
        return this._httpClient.post(`${this.urlCampaign + "/create/campaign"}`, scheduledMessage);
    }
}
