import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
// import { environment } from 'environments/environment';
import { CustomerAccount } from '../authentication/shared/model/user.model';
import { UrlList } from '../authentication/shared/url-list.enum';

@Injectable()
export class ProfileService implements Resolve<any>
{
    timeline: any;
    about: any;
    photosVideos: any;
    timelineOnChanged: BehaviorSubject<any>;
    aboutOnChanged: BehaviorSubject<any>;
    photosVideosOnChanged: BehaviorSubject<any>;
    private url = UrlList.URL_PROFILE_INTEGRATED;
    private urlRooms = UrlList.URL_ROOMS;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this.timelineOnChanged = new BehaviorSubject({});
        this.aboutOnChanged = new BehaviorSubject({});
        this.photosVideosOnChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            resolve(null);
        });
    }

    getUserProfile() {
        // this.recordlocalstorage();
        //     let headers = new HttpHeaders();
        // headers = headers.append('Skip-Authorization', '');
        // headers = headers.append('Content-Type', 'application/json');
        // console.log(this.url);
        // return this._httpClient.get<any>(environment.apiUrl+this.url, { username: user.userName, password: user.password },
        //   { headers: headers });
    }

    updateUserProfile(contact): Observable<any> {
        const body = {
            id: contact.id,
            firstName: contact.name,
            lastName: contact.lastName,
            email: contact.email,
            phoneNumber: contact.phone,
            authorities: contact.authorities,
            uaaUserId: contact.uaaUserId,
            status: 'ACTIVE',
            customerId: contact.customerId,
            pwd: '',
            emailSignature: contact.emailSignature,
            langKey: contact.langKey,
            providerUserId: contact.providerUserId
        };
        return this._httpClient.post(this.url + UrlList.UPDATE_PROFILE, body);
    }

    getAssignedRooms(customeruserId: number, customersId: number): Observable<any> {

        const body = {
            customeruserId: customeruserId,
            customersId: customersId
        };
        return this._httpClient.post(this.urlRooms + UrlList.GET_CUSTOMER_ROOMS, body);
    }

}

