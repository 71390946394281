import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'beplic-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplateDialogComponent {
  url: any;
  vpMobile = false;

  constructor(
    public matDialogRef: MatDialogRef<TemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }

}
