import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Observable, Subscription } from 'rxjs';

import { DashboardService } from '../dashboard/dashboard.service';
import { LocalStorageService } from 'app/services/local-storage.service';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';

@Component({
    selector: 'beplic-iframe-metabase',
    templateUrl: './iframe-metabase.component.html',
    styleUrls: ['./iframe-metabase.component.scss']
})
export class IframeMetabaseComponent implements OnInit, OnDestroy {

    @Input() isFromAdminPage: boolean = true;
    iframeUrl: SafeResourceUrl | undefined;
    isLoading = true;
    dashboardReportOpen: boolean = false;
    userProfile: CustomerAccount;
    reports: DasboardReportResponse[];
    dashboardNameAdmin: string = 'INDICATORS_ADMIN';
    dashboardNameAtc: string = 'INDICATORS_ATC';
    activeTab: string = 'indicadores';
    tabIndicadores: string = 'indicadores';
    tabReportes: string = 'reportes';

    private subscription$: Subscription;

    constructor(
        private _dashboardService: DashboardService,
        private _localStorageService: LocalStorageService,
        private _sanitizer: DomSanitizer
    ) {
        setTimeout(() => {
            this.isLoading = false;
        }, 2000);

        this.userProfile = _localStorageService.getCurrentUser();
    }

    ngOnInit(): void {
        this.getDashboard();
        if (this.isFromAdminPage) this.getReports();
    }

    ngOnDestroy(): void {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }

    isLoadingTrue() {
        return this.iframeUrl === undefined;
    }

    onDownloadReport(reportName: string): void {
        this.dashboardReportOpen = true;
        this.getDashboard(reportName);
    }

    getReports() {
        let serviceMethod: Observable<DasboardReportResponse[]>;
        serviceMethod = this._dashboardService.getReports();

        this.subscription$ = serviceMethod.subscribe((data: DasboardReportResponse[]) => {
            this.reports = data;
        });
    }

    getDashboard(nameDashboard?: string) {
        let data: any;
        const dashboardData = {
            customerId: this.userProfile.customerId,
        };

        if (!this.isFromAdminPage) {
            const dashboardName = nameDashboard && nameDashboard.trim() !== '' ? nameDashboard : this.dashboardNameAtc;
            data = {
                ...dashboardData,
                customerUsersId: this.userProfile.id,
                dashboardName: dashboardName
            }
        } else {
            const dashboardName = nameDashboard && nameDashboard.trim() !== '' ? nameDashboard : this.dashboardNameAdmin;
            data = {
                ...dashboardData,
                dashboardName: dashboardName
            };
        }

        let serviceMethod: Observable<any>;
        serviceMethod = this._dashboardService.getDashboard(data);
        this.subscription$ = serviceMethod.subscribe((data: any) => {
            this.iframeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(data);
        });
    }

    setActiveTab(tab: string): void {
        this.activeTab = tab;
        if (tab == this.tabIndicadores) {
            this.dashboardReportOpen = false;
            this.getDashboard();
        }
    }
}
