import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material';
import { MatSnackBarModule } from "@angular/material";

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from '../app/fuse-config';
import { AppComponent } from 'app/app.component';
// import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { RegisterService } from './main/pages/authentication/shared/services/register.service';
import { LoginService } from './main/pages/authentication/login/login.service';
import { SessionService } from './main/pages/authentication/shared/services/session.service';
import { SendDataService } from './main/pages/authentication/shared/services/send-data.service';
import { ContactService } from './main/pages/authentication/shared/services/contact.service';
import { BotService } from './main/pages/authentication/shared/services/services-bot/bot.service';
import { HeaderHttpInterceptor } from './main/pages/authentication/shared/interceptor/token-interceptor.service';
import { NavigationService } from './navigation.service';
import { ForgotPasswordService } from './main/pages/authentication/forgot-password/forgot-password.service';
import { environment } from 'environments/environment';
import { ResetPasswordService } from './main/pages/authentication/reset-password/reset-password.service';
import { MailConfirmService } from './main/pages/authentication/mail-confirm/mail-confirm.service';

const PATH_EXTERNAL_LOGIN = environment.PATH_EXTERNAL_LOGIN;
registerLocaleData(localeEsAr, 'es-Ar');

const appRoutes: Routes = [
    {
        path: 'apps',
        //path: '**',
        loadChildren: './main/apps/apps.module#AppsModule'
    },
    {
        path: 'pages',
        loadChildren: './main/pages/pages.module#PagesModule',
    },
    {
        path: 'external-chat',
        loadChildren: './external-chat/external-chat.module#ExternalChatModule',
    },
    {
        path: 'external-template',
        loadChildren: './external-template/external-template.module#ExternalTemplateModule',
    },
    {
        path: PATH_EXTERNAL_LOGIN,
        loadChildren: './external-login/external-login.module#ExternalLoginModule',
    },
    {
        path: 'access-denied',
        loadChildren: './shared/access-denied/access-denied.module#AccessDeniedModule',
    },
    {
        path: '**',
        redirectTo: 'pages/auth/login'
    }

];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        // ApmModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
                deps: [HttpClient]
            }
        }),
        /*   InMemoryWebApiModule.forRoot(FakeDbService, {
              delay: 0,
              passThruUnknownUrl: true
          }), */

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        //other mat
        // MatDialog,
        // MatDialogRef,
        MatSnackBarModule,
        // CuitValidator,

        // App modules
        LayoutModule,
        CommonModule,

        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        // AppStoreModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        RegisterService,
        LoginService,
        SessionService,
        SendDataService,
        ContactService,
        ForgotPasswordService,
        ResetPasswordService,
        MailConfirmService,
        NavigationService,
        BotService, {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        }, { provide: LOCALE_ID, useValue: 'es-Ar' }]

})
export class AppModule {
    constructor() {
        // Agent API is exposed through this apm instance
    }
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}