import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Resolve } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { UrlList } from '@fuse/url-list.enum';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { CustomerAccount } from '../shared/model/user.model';
import { environment } from 'environments/environment';
import { checkUserConfiguration } from 'app/shared/util/common-functions';
import { SocketService } from 'app/main/apps/chat/socket.service';
import { LocalStorageService } from 'app/services/local-storage.service';
import { FrontConfiguration } from 'app/config/front-configuration';

@Injectable()
export class LoginService implements Resolve<any> {
    userCurrent: CustomerAccount;
    url: string;
    urlRefreshToken: string;
    showNotificationSubject = new Subject<boolean>();
    showNotification$ = this.showNotificationSubject.asObservable();

    showDopplerImageSubject = new BehaviorSubject<boolean>(false);
    showDopplerImage$ = this.showDopplerImageSubject.asObservable();

    private loggedIn = new BehaviorSubject<boolean>(false);
    loggedIn$ = this.loggedIn.asObservable();

    constructor(
        private http: HttpClient,
        private _fuseNavigationService: FuseNavigationService,
        private _socketService: SocketService,
        private _localStorageService: LocalStorageService
    ) {
        this.url = UrlList.LOGIN;
        this.urlRefreshToken = UrlList.REFRESH_TOKEN;
    }

    resolve(): Promise<any> {
        return new Promise((resolve, reject) => {
            const currentUrl = window.location.href;

            if (currentUrl.startsWith(environment.URL_DOPPLER)) {
                document.body.classList.add('theme-doppler');
                this.showDopplerImageSubject.next(true);
            } else {
                document.body.classList.add('theme-default');
                this.showDopplerImageSubject.next(false);
            }

            resolve(null);
        });
    }

    getLogin(user: CustomerAccount): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Skip-Authorization', '');
        headers = headers.append('Content-Type', 'application/json');

        const currentUrl = window.location.href;

        if (currentUrl.startsWith(environment.URL_DOPPLER)) {
            headers = headers.append('partner-key', environment.PARTNER_KEY_DOPPLER);
        }

        return this.http.post<any>(this.url, { username: user.userName, password: user.password },
            { headers: headers });
    }

    refreshToken(token: string): Observable<any> {
        const params = new HttpParams().set('refresh_token', token);
        const headers = new HttpHeaders().set('Skip-Authorization', 'true');
        return this.http.get(this.urlRefreshToken, { params, headers: headers });
    }

    isAuthenticated() {
        const currentUser = this._localStorageService.getCurrentUser();

        // Verificar usuario actual
        if (!currentUser) return false;

        // Obtener la URL actual.
        const currentUrl = window.location.href;

        if (currentUrl.startsWith('http://localhost')) return true;

        const isLoginEnabled = checkUserConfiguration([FrontConfiguration.PARTNER_LOGIN_ENABLED]);

        if (!isLoginEnabled) {
            this.showNotificationSubject.next(true);
            return false;
        }

        return true
    }

    logout() {
        this._fuseNavigationService.unregister('inside');
        this._socketService.disconnect();
        sessionStorage.clear();
        localStorage.clear();
        this.setLoggedIn(false);
    }

    setLoggedIn(isLogged: boolean) {
        this.loggedIn.next(isLogged);
    }
}
