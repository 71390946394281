import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { environment } from 'environments/environment';
import { LoginService } from '../../login/login.service';
import { ScriptService } from 'app/services/script.service';
import { RoomsService } from 'app/main/apps/services/rooms.service';
import { navigation } from 'app/navigation/navigation_wsb';
import { configureChatWidget } from '../../../../../../assets/content/js/doppler/chatbot.js';
import { initDopplerNavbar } from '../../../../../../assets/content/js/doppler/navbar-configuration.js';
import { UrlList } from '../url-list.enum';
import { LocalStorageService } from 'app/services/local-storage.service';
import { NavigationRoomStatusService } from '@fuse/components/navigation/navigation-room-status.service';
import { UserRequiredAction } from 'app/constants/user-required-action';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseNavigation } from '@fuse/types';
import { checkUserConfiguration } from 'app/shared/util/common-functions';
import { AuthRoles } from '../model/enum/auth-roles.enum';

const DOPPLER_STYLE_ENABLED = environment.DOPPLER_STYLE_ENABLED;
const META_PERMISSION_REQUEST_ENABLED = environment.META_PERMISSION_REQUEST_ENABLED;
const URL_BASE_DOPPLER_CONFIGURATION = environment.URL_BASE_DOPPLER_CONFIGURATION;
const URL_DOPPLER_MAIN = environment.URL_DOPPLER_MAIN;

@Injectable()
export class SessionService implements CanActivate {
    url = UrlList.URL;
    urlIntegraded = UrlList.URL_PROFILE_INTEGRATED;

    constructor(
        private http: HttpClient,
        private loginService: LoginService,
        private router: Router,
        private _scriptService: ScriptService,
        @Inject(DOCUMENT) private document: any,
        private _roomService: RoomsService,
        private _navigationService: FuseNavigationService,
        private _fuseNavigationService: FuseNavigationService,
        private _localStorageService: LocalStorageService,
        private navigationRoomStatusService: NavigationRoomStatusService,
        private _snackBar: MatSnackBar,
        private translate: TranslateService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.loginService.isAuthenticated()) {
            return true;
        } else {
            this.router.navigate(['/pages/auth/login']);
            return false;
        }
    }

    getUserProfile(login: string, isExternal?: boolean): Observable<any> {
        return this.http.get(this.urlIntegraded + UrlList.GET_USER_PROFILE + '/' + login)
            .pipe(
                concatMap(data => {
                    data['login'] = login; // set field "login"
                    data['isExternal'] = isExternal;
                    return this.identity(data)
                }),
                catchError(err => throwError(err))
            );
    }

    createUser(user: any): Observable<any> {
        const userProfile = this._localStorageService.getCurrentUser();
        const body = {
            login: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phone,
            customerId: userProfile.customerId,
            pwd: user.password,
            authorities: [user.role],
            emailSignature: `<p><strong>${user.lastName} ${user.firstName}</p></strong>`
        };
        return this.http.post(this.urlIntegraded + UrlList.CREATE_USER, body);
    }

    deleteUser(user: any): Observable<any> {
        const body = {
            id: user.id,
            login: user.login,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            email: user.email,
            status: user.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
            customerId: user.customersId,
            authorities: user.authorities,
            emailSignature: user.emailSignature,
            appStatus: user.appStatus === null || user.appStatus === 'ENABLED' || user.appStatus === 'CHANGED' || user.appStatus === 'DATACHANGED' ? 'DISABLED' : 'ENABLED',
            receiveNotification: user.receiveNotification === null || user.receiveNotification === false ? true : false,
            providerUserId: user.providerUserId
        };
        return this.http.post(this.urlIntegraded + UrlList.DELETE_USER, body);
    }

    updateUser(user: any): Observable<any> {
        this.getUser(user.id).subscribe(u => {
            user.deviceKey = u.deviceKey;
            this.sendLogoutNotification(user);
        }
        );
        const body = {
            id: user.id,
            login: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phone,
            email: user.email,
            status: user.status,
            customerId: user.customersId,
            authorities: [user.role],
            emailSignature: user.emailSignature,
            appStatus: user.appStatus === 'DISABLED' ? 'DISABLED' : 'DATACHANGED',
            receiveNotification: user.receiveNotification === null || user.receiveNotification === false ? true : false,
            providerUserId: user.providerUserId
        };

        return this.http.post(this.urlIntegraded + UrlList.DELETE_USER, body);
    }

    validateEmail(email: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Skip-Authorization', 'true');
        const body = {
            email: email
        }
        return this.http.post(UrlList.URL_SECURITY + UrlList.VALIDATE_EMAIL, body, { headers });
    }

    sendLogoutNotification(user: any): Observable<any> {
        const body = {
            notification: {
                title: '',
                body: ''
            },
            priority: 'high',
            to: user.deviceKey
        }
        const headers = new HttpHeaders({
            'Authorization': 'KEY=AAAAVsAtWVk:APA91bH6ymNXN1RBGHMBg_CAuOZAZ6MzSVV4sdkCbPNahM2NNkSWldZFQNRu6fyETxhhkNsqdmpeRFrpRvTCf-qZ3-_TvLOnoZQioERhf9EF4mz6K-wpDX_oVq4xdUTpF7YK3JTMpFcR'
        })
        const options = {
            headers: headers
        }
        return this.http.post("https://fcm.googleapis.com/fcm/send", body, options);
    }

    getUser(id: String): Observable<any> {
        return this.http.get(UrlList.URL_PROFILE_INTEGRATED + UrlList.MOBILE_GET_PROFILE + id);
    }

    private setUserData(data: any) {
        const userLoginSession = {
            id: data.id,
            login: data.login,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            cellphone: data.phoneNumber,
            authorities: data.authorities,
            customerId: data.customerId,
            uaaUserId: data.uaaUserId,
            customerUserPreferences: data.customerUserPreferences,
            langKey: data.langKey,
            emailSignature: null,
            isExternal: data.isExternal, // Field to know if the login was external
            path: '',
            admEmail: data.admEmail,
            assignedRooms: data.assignedRooms,
            providerUserId: data.providerUserId,
            requiredAction: data.requiredAction,
            customerConfigurations: data.customerConfigurations,
            frontConfigurations: data.frontConfigurations,
            plan: data.plan
        }
        // Set current Rol
        const currentRol = data.authorities[0];
        if (data.emailSignature !== null) {
            userLoginSession.emailSignature = data.emailSignature;
        } else {
            userLoginSession.emailSignature = `<p><strong>${data.lastName} ${data.firstName}</p></strong>`;
        }
        // Check if the user has to change the password
        const profilePath = "/pages/profile";
        const isUpdatePwd = userLoginSession.requiredAction && userLoginSession.requiredAction === UserRequiredAction.UPDATE_PASSWORD;
        if ((userLoginSession && currentRol === 'ROLE_ADMIN')) {
            // Default redirection for user with ADMIN role
            userLoginSession.path = isUpdatePwd ? profilePath : '/apps/dashboards/analytics';
        } else if (userLoginSession && currentRol === 'ROLE_ATC') {
            userLoginSession.path = isUpdatePwd ? profilePath : '/apps/dashboards/project';
        }

        // TODO: solo para solicitar permisos de whatsapp
        if (META_PERMISSION_REQUEST_ENABLED) {
            userLoginSession.path = '/apps/rooms';
        }

        // Save user data to localstorage
        this._localStorageService.setCurrentUser(userLoginSession);
        return userLoginSession;
    }

    getAuthorities(): Observable<any> {
        const userProfile = this._localStorageService.getCurrentUser();
        const configValues: string[] = userProfile.frontConfigurations && userProfile.frontConfigurations.configurations ? userProfile.frontConfigurations.configurations : [];
        return this.http.post(UrlList.URL_SECURITY + UrlList.USER_AUTHORITIES, configValues);
    }

    getRoomsByCustomerPromise(data: { customeruserId: number, customersId: number }) {
        return new Promise((resolve, reject) => {
            this._roomService.getRoomsByCustomer(data).subscribe(resp => resolve(resp), err => reject(err));
        });
    }

    /**
     * Get menu list in Beplic
     * @param data {login} : logged in username. ex: test@gmail.com
     * @returns json with menu list
     * @see  MS Partners-> NavigationResource.class
     */
    getNavigationPromise(data: { login: string }) {
        return new Promise<FuseNavigation[]>((resolve, reject) => {
            return this.http.get<FuseNavigation[]>(UrlList.URL_PARTNER + "/api/menu/" + data.login)
                .pipe(map((navigationMenu: FuseNavigation[]) => this.filterNavigationMenuByConfig(navigationMenu)))
                .subscribe((navigationMenu: FuseNavigation[]) => {
                    resolve(navigationMenu)
                }, err => reject(err));
        });
    }

    async identity(data: any) {
        try {
            const currentUser = this.setUserData(data);
            // Init navigation menu construction asynchronously
            this.initMainConfiguration({ ...currentUser, isDopplerMenuLoaded: currentUser.isExternal });

            return currentUser;
        } catch (error) {
            throw error;
        }
    }

    async initMainConfiguration(userProfile: any) {
        try {
            this.loginService.setLoggedIn(true); // Set Logged in user to true
            // Init doppler setup
            this.initDopplerConfiguration(userProfile);
            let navigationMenu = null;
            // TODO: solo para solicitar permisos de Meta
            if (META_PERMISSION_REQUEST_ENABLED) {
                navigationMenu = JSON.parse(JSON.stringify(navigation));
            } else {
                navigationMenu = await this.getNavigationPromise({ login: userProfile.email });
            }

            // Init Current Navigation without rooms
            this._navigationService.initCurrentNavigation(navigationMenu, []);
            // Get rooms assigned to the user asynchronously
            this.getRoomsByCustomerPromise({ customersId: userProfile.customerId, customeruserId: userProfile.id })
                .then((customerUserRooms: any[]) => {

                    this.checkRooms(customerUserRooms, userProfile);
                    // Init Current Navigation with rooms
                    this._navigationService.buildNavigationMenu(navigationMenu, customerUserRooms);
                    // Set assigned rooms in session storage
                    this.setRoomsByCustomers(customerUserRooms);

                    // Actualiza el contador general
                    this.navigationRoomStatusService.updateGeneralCounter();

                    // Starting reactive services
                    this._fuseNavigationService.getReactiveProfile();
                })
                .catch(err => {
                    console.warn("Could not get rooms to show in the navigation menu");
                })
        } catch (error) {
            console.error("Could not start navigation menu. {}", error);
        }
    }

    checkRooms(customerUserRooms: any[], userProfile: any) {
        const authorities: string[] = userProfile.authorities ? userProfile.authorities : [];
        if (customerUserRooms.length === 0 && authorities.includes(AuthRoles.ROLE_ATC)) {
            this.openSnackBar();
        }
    }

    openSnackBar() {
        this._snackBar.open(this.translate.instant('NAV.EMPTY.REQUEST_FOR_ROOM'), this.translate.instant('TEMPLATE.SNACKBAR.CLOSE'), {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['custom-snackbar-info']
        });
    }

    initDopplerConfiguration(userProfile: any) {
        if (userProfile && userProfile.frontConfigurations.partner === 2) {
            // Activate Doppler navigation menu if the user logged in from Doppler
            if (userProfile.isExternal && !userProfile.isDopplerMenuLoaded) {
                // Init Doppler navigation menu
                this.initDopplerNavigationMenu(userProfile.authorities[0]);
            }
            // Set Doppler Styles Theme
            this.document.body.classList.add('theme-doppler');
            // Show chatbot only for the Doppler partner
            const scriptDoopler = this._scriptService.loadScriptWithPromise(this._scriptService.getDataDopplerChatbot());
            scriptDoopler.then(() => {
                configureChatWidget();
            }).catch(() => { });
        }

        // Activate Doppler styles for debugging
        if (DOPPLER_STYLE_ENABLED) {
            this.document.body.classList.add('theme-doppler');
        }
    }

    initDopplerNavigationMenu(currentRol: string) {
        // Init navigation menu settings
        const config = {
            dopplerLegacyBaseUrl: URL_BASE_DOPPLER_CONFIGURATION,
            redirectTo: `${URL_DOPPLER_MAIN}/login`,
            enableRedirection: currentRol === 'ROLE_ADMIN', // Enable redirection only for user with ADMIN role
            endSessionFn: () => {
                this.loginService.logout();
            }
        }
        // Setup for doppler navigation menu
        initDopplerNavbar(config);
    }

    private setRoomsByCustomers(rooms: any): void {
        if (JSON.parse(sessionStorage.getItem('myRooms'))) {
            sessionStorage.removeItem('myRooms');
        }
        const roomsToSave = rooms || [];
        sessionStorage.setItem('myRooms', JSON.stringify(roomsToSave));
    }

    private filterNavigationMenuByConfig(navigationMenu: FuseNavigation[]): FuseNavigation[] {
        return navigationMenu
            .map((navigationItem: FuseNavigation) => {
                // It is traversed recursively if it has children.
                if (navigationItem.children) {
                    navigationItem.children = this.filterNavigationMenuByConfig(navigationItem.children);
                }
                // If the menu option has the configuration associated with the user, it is displayed
                if (!navigationItem.feature || checkUserConfiguration([navigationItem.feature])) {
                    return navigationItem;
                }
                return null;
            })
            .filter((navigationItem: FuseNavigation | null) => {
                // Filter: remove null objects and those without children
                return (
                    navigationItem !== null &&
                    (navigationItem.children === undefined || navigationItem.children === null || navigationItem.children.length > 0)
                );
            }
            ); // remove null elements
    }
}
