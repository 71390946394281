export const initDopplerNavbar = (dopplerConfig) => {
    window["style-guide-configuration"] = {
        initFonts: true,
    };
    assetServices.load({
        manifestURL:
            "https://cdn.fromdoppler.com/doppler-style-guide/asset-manifest-V1.json?s=" + Date.now(),
    });

    window["doppler-session-mfe-configuration"] = {
        dopplerLegacyBaseUrl: dopplerConfig.dopplerLegacyBaseUrl,
        useDummies: false,
    };
    assetServices.load({
        manifestURL:
            "https://cdn.fromdoppler.com/doppler-session-mfe/asset-manifest-v1.json?s=" + Date.now(),
    });

    window["doppler-menu-mfe-configuration"] = {
        dopplerMenuElementId: "doppler-menu-mfe",
        dopplerLegacyBaseUrl: dopplerConfig.dopplerLegacyBaseUrl,
        beamerId: "wCWRrvwa50706",
    };
    assetServices.load({
        manifestURL:
            "https://cdn.fromdoppler.com/doppler-menu-mfe/asset-manifest-v1.json?s=" + Date.now(),
    });

    // Doppler events to get information
    /* window.addEventListener("doppler-session-state-update", () => {
        console.log(window.dopplerSessionState);
    });
    
    window.addEventListener("DOMContentLoaded", () => {
        console.log(window.dopplerSessionState);
    }); */
};
