export class Client {

    constructor(
        public id?: number,
        public name?: string,
        public notify?: string,
        public notificationEnabled?: boolean,
        public idSessionChat?: string,
        public idBot?: number
    ) {
    }
}
