import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Observable, of } from 'rxjs';
import { catchError, delay, finalize, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { TemplateHeaderTypeValues } from 'app/main/apps/types/common-types.type';
import { replaceParameterInTemplate } from '../util/common-functions';
import { FileRules } from 'app/config/file-rules';

interface DataPreviewTemplateDialog {
    template: any,
    headerText: string,
    bodyText: string,
    headerAmount: number,
    bodyAmount: number,
    action?: string,
    room: any
}

interface ParameterData {
    defaultValue: string,
    defaulInitValue: number,
    initValue: number,
    lengthValue: number
}

@Component({
    selector: 'preview-template-dialog',
    templateUrl: './preview-template-dialog.component.html',
    styleUrls: ['./preview-template-dialog.component.scss']
})
export class PreviewTemplateDialogComponent implements OnInit {
    template: any;
    rform: FormGroup;
    typeFile: string;
    selectedFile: File = null;
    isFileLoaded = false;
    parametersData = {
        header: [],
        body: []
    };
    templateWithExamples: any;
    regexToValidateParameter = /({{\d*[0-9]}})(?![^{{}}]*})/g;
    headerType: TemplateHeaderTypeValues;
    urlHeaderInitCheck: boolean = false;
    headerMediaType = {
        IMAGE: {
            name: 'Imagen',
            label: 'jpg / png',
            mimeTypes: ['image/png', 'image/jpeg', 'image/jpg']
        },
        VIDEO: {
            name: 'Video',
            label: 'mp4',
            mimeTypes: ['video/mp4', 'application/mp4']
        },
        DOCUMENT: {
            name: 'Documento',
            label: 'pdf',
            mimeTypes: ['application/pdf']
        }
    }

    private maxLengthHeader: number = 60;
    private maxLengthBody: number = 1024;

    constructor(
        public matDialogRef: MatDialogRef<PreviewTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DataPreviewTemplateDialog,
        private _fb: FormBuilder,
        private _http: HttpClient
    ) {

        this.rform = this._fb.group({
            headerText: [null, Validators.maxLength(this.maxLengthHeader)],
            headerMediaValue: null,
            bodyText: [null, this.lengthValidator()],
            headerParameter: null,
            bodyParameters: this._fb.array([]),
        });
    }

    ngOnInit() {
        if (this.data) {
            if (this.data.template) {
                this.template = { ...this.data.template }
                this.headerType = this.template['headerType'];
                this.templateWithExamples = replaceParameterInTemplate(this.template);
                // Set to null to not show the example of a multimedia type template at the beginning
                this.template.headerText = null;
                this.template.parameterHeader = null;
                ////
            };

            this.f.patchValue({
                headerText: this.template['headerText'],
                bodyText: this.template['bodyText']
            });

            if (this.headerType && this.headerType !== 'TEXT') {
                const headerTextCtrl = this.f.get('headerText');
                const headerMediaValueCtrl = this.f.get('headerMediaValue');

                headerTextCtrl.setValidators([]);
                headerMediaValueCtrl.setValidators(Validators.required);

                headerTextCtrl.updateValueAndValidity();
                headerMediaValueCtrl.updateValueAndValidity();
            } else if (this.data.headerAmount > 0) {
                const headerParameterCtrl = this.f.get('headerParameter');
                headerParameterCtrl.setValidators(Validators.required);
                headerParameterCtrl.updateValueAndValidity();

                const tmpDefaultValueHeader = `{{1}}`;
                const tmpInitValueHeader = this.data.headerText.indexOf(tmpDefaultValueHeader);
                if (tmpInitValueHeader === 0 || tmpInitValueHeader > 0) {
                    const parameterData: ParameterData = {
                        defaultValue: tmpDefaultValueHeader,
                        lengthValue: 5,
                        initValue: tmpInitValueHeader,
                        defaulInitValue: tmpInitValueHeader
                    };
                    this.parametersData.header.push(parameterData);
                }
            }

            if (this.data.bodyAmount > 0) {
                // Se crea tantos inputs como parametros en el body haya.
                for (let index = 0; index < this.data.bodyAmount; index++) {
                    const tmpDefaultValue = `{{${index + 1}}}`;
                    const tmpInitValue = this.data.bodyText.indexOf(tmpDefaultValue);
                    if (tmpInitValue === 0 || tmpInitValue > 0) {
                        const parameterData: ParameterData = {
                            defaultValue: tmpDefaultValue,
                            lengthValue: tmpDefaultValue.length,
                            initValue: tmpInitValue,
                            defaulInitValue: tmpInitValue,
                        };

                        this.parametersData.body.push(parameterData);
                    }

                    const formControl = this._fb.control(null, Validators.required);
                    this.bodyParamatersArr.push(formControl);
                }
            }
        }
    }

    exampleInputChangeHandler(paramerterType: 'header' | 'body', parameterNumberOrIndex: number) {
        const initValue = this.parametersData[paramerterType][parameterNumberOrIndex].initValue;
        const lengthValue = this.parametersData[paramerterType][parameterNumberOrIndex].lengthValue;
        const defaultValue = this.parametersData[paramerterType][parameterNumberOrIndex].defaultValue;

        const regString = `(.{${initValue}}).{${lengthValue}}`;
        const regCustom = new RegExp(regString);

        if (paramerterType === 'header') {
            let headerTextCtrlValue = this.f.get('headerParameter').value;

            if (!headerTextCtrlValue) headerTextCtrlValue = defaultValue;

            const dataChange = this.data.headerText.replace(regCustom, `$1${headerTextCtrlValue}`);
            this.template.headerText = dataChange;

            this.f.get('headerText').setValue(this.template.headerText);
        }

        if (paramerterType === 'body') {
            for (let index = 0; index < this.getBodyParamatersArr().length; index++) {

                const defaultValue = this.parametersData.body[index].defaultValue;
                let valueToSet = defaultValue;

                if (this.getBodyParamatersArr()[index].value) {
                    valueToSet = this.getBodyParamatersArr()[index].value;
                }

                let replace;
                if (index === 0) {
                    replace = this.data.bodyText.replace(defaultValue, valueToSet);
                } else {
                    replace = this.template.bodyText.replace(defaultValue, valueToSet);
                }

                this.template.bodyText = replace
                this.f.get('bodyText').setValue(this.template.bodyText);
            }
        }
    }

    onSubmit() {
        const dataToSend = {
            headerAmount: this.data.headerAmount,
            bodyAmount: this.data.bodyAmount,
            parameterHeader: this.f.get('headerParameter').value,
            bodyParameters: this.f.get('bodyParameters').value,
            mediaHeaderUrl: this.f.get('headerMediaValue').value,
            multipartFile: this.selectedFile
        }

        if (dataToSend.bodyAmount > 0) {
            for (let index = 0; index < this.getBodyParamatersArr().length; index++) {
                dataToSend[`parameter${index + 1}Body`] = this.getBodyParamatersArr()[index].value;
            }
        }

        this.matDialogRef.close(dataToSend);
    }

    getBodyParamatersArr() {
        return (this.f.get('bodyParameters') as FormArray).controls;
    }

    getErrorMessage() {
        return 'Este campo es requerido';
    }

    lengthValidator(): ValidatorFn | null {
        return (control: AbstractControl) => {
            const wordSize = this.calculateLengthOfField('bodyText');

            if (wordSize > this.maxLengthBody)
                return { maxlengthBad: true }

            return null;
        }
    }

    calculateLengthOfField(field: 'headerText' | 'bodyText') {
        let replaceString: string = '';
        const txtValueCtrol: string = this.f ? this.f.get(field).value : '';

        if (txtValueCtrol)
            replaceString = txtValueCtrol.replace(this.regexToValidateParameter, '');


        return replaceString.length;
    }

    checkValidMymeType(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const value = control.value;
            this.urlHeaderInitCheck = true;
            this.template.headerText = null;

            if (!value) return of();

            return of(control.value)
                .pipe(
                    delay(500),
                    switchMap(url => this._http.head(url, { observe: 'response' })
                        .pipe(
                            map(event => {
                                if (event instanceof HttpResponse) {
                                    const contentType = event.headers.get('content-type');
                                    const mimeType: string[] = this.headerMediaType[this.headerType]['mimeTypes'];
                                    if (mimeType.some(elem => elem === contentType)) {
                                        this.template.headerText = this.f.get('headerMediaValue').value;
                                        return;
                                    }
                                }
                                return { checkValidMymeTypeBad: true };
                            }),
                            catchError(error => {
                                console.error(error);
                                return of({ checkValidMymeTypeBad: true });
                            }),
                            finalize(() => {
                                this.urlHeaderInitCheck = false;
                            })
                        ))
                )
        }
    }

    clearInputMediaUrl() {
        this.f.get('headerMediaValue').setValue(null);
        this.template.headerText = null;
    }

    get bodyTextValue(): string {
        return this.f.get('bodyText') ? this.f.get('bodyText').value : '';
    }

    get headerTextValue(): string {
        return this.f.get('headerText') ? this.f.get('headerText').value : '';
    }

    get bodyParamatersArr(): FormArray {
        return this.f.get('bodyParameters') as FormArray;
    }

    get f() {
        return this.rform;
    }

    fileSelected(event, type: 'img' | 'file' | 'document' | 'video') {
        if (event.target.files[0]) {
            let file: any;
            file = type === 'img' ? 'La imagen' : (type === 'video' ? 'El video' : 'El documento');

            if (event.target.files[0].size > FileRules.getMaxSize(type)) {
                Swal.fire({
                    title: 'Atención!',
                    text: FileRules.getTitleMaxFileSize(type, file),
                    type: 'warning',
                    confirmButtonColor: '#e2ae2f',
                    confirmButtonText: 'Aceptar',
                    width: '40rem'
                });
            } else {
                this.urlHeaderInitCheck = false;
                this.typeFile = type;
                this.selectedFile = event.target.files[0] as File;
                this.previewFile(this.selectedFile, type);
            }
        }
        event.target.value = '';
    }

    previewFile(file: File, type: string) {
        this.isFileLoaded = true;
        // Previsualizar imagen, video o documento
        if (type === 'img' || type === 'video') {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.template.headerText = reader.result.toString();
            };
            reader.readAsDataURL(file);
        } else if (type === 'document') {
            this.template.headerType = 'DOCUMENT';
            this.template.headerText = URL.createObjectURL(file);
        }
    }

    clearFileSelected() {
        this.selectedFile = null;
        this.isFileLoaded = false;
        this.urlHeaderInitCheck = true;
        this.template.headerText = null;
    }
}
