import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';

@Injectable({ providedIn: 'root' })
export class DashboardService {
    private urlGeneralIntegra = UrlList.URL_GENERAL_INTEGRATED;

    constructor(private _httpClient: HttpClient) { }

    getDataPowerbi() {
        return this._httpClient.get(`${this.urlGeneralIntegra}/dashboard/info-embed-powerbi`)
    }

    getDataCapacityStatus(){
        return this._httpClient.get(`${this.urlGeneralIntegra}/dashboard/info-capacity`)
    }
}
