import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        "id": "applications",
        "title": "Applications",
        "translate": "NAV.APPLICATIONS",
        "type": "group",
        "icon": "apps",
        "children": [
            {
                "id": "administration",
                "title": "Administration",
                "translate": "NAV.ADMINISTRATION",
                "type": "collapsable",
                "icon": "build",
                "children": [
                    {
                        "id": "rooms",
                        "title": "Rooms",
                        "translate": "NAV.ADMINISTRATION.ROOMS",
                        "type": "item",
                        "url": "/apps/rooms"
                    }/* ,
                    {
                        "id": "templates",
                        "title": "Templates",
                        "translate": "NAV.ADMINISTRATION.TEMPLATES",
                        "type": "item",
                        "url": "/apps/template/all"
                    } */
                ]
            },
            {
                "id": "chat",
                "title": "Chat",
                "translate": "NAV.CHAT",
                "type": "collapsable",
                "icon": "chat",
                "url": null,
                "show": true,
                "children": [],
            }
        ]
    },
    {
        "id": "pages",
        "title": "Pages",
        "translate": "NAV.PAGES",
        "type": "group",
        "icon": "pages",
        "children": [
            {
                "id": "profile",
                "title": "Profile",
                "translate": "NAV.PAGES.PROFILE",
                "type": "item",
                "icon": "person",
                "url": "/pages/profile"
            }
        ]
    }
];
