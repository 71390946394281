import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'emoji',
    templateUrl: './emoji.component.html',
    styleUrls: ['./emoji.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EmojiComponent implements OnInit {
    @Input('formText') formText;
    @Input('inputHTML') inputHTML;
    @Input('formName') formControlName;
    @Output() emojiSelectEvent = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
    }

    addEmoji(event) {
        this.formText = this.formText || '';
        const emoji = event.emoji.native;
        const inputText: any = this.inputHTML;
        const currentIndex = inputText.selectionStart;

        if (currentIndex > 0) {
            this.formText = `${this.formText.substring(0, currentIndex)}${emoji}${this.formText.substring(currentIndex)}`;
        } else {
            this.formText = `${emoji}${this.formText}`;
        }

        this.emojiSelectEvent.emit(
            {
                text: this.formText,
                pos: currentIndex + 1,
                formName: this.formControlName,
                elemHTML: this.inputHTML
            });

    }

}
