import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SSE } from 'sse.js';
import * as _ from 'lodash';

import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { SocketService } from 'app/main/apps/chat/socket.service';
import { getConfigurationValue } from 'app/shared/util/common-functions';
import { PartnerConfiguration } from '../../../app/main/pages/authentication/shared/model/partner-configuration.model';
import { LocalStorageService } from 'app/services/local-storage.service';
import { MatSnackBar } from '@angular/material';
import { FrontConfiguration } from 'app/config/front-configuration';

@Injectable({ providedIn: 'root' })
export class FuseNavigationService {
    onItemCollapsed: Subject<any>;
    onItemCollapseToggled: Subject<any>;
    userProfile: CustomerAccount;
    chatsNotView: any[] = [];
    rooms: any[];
    eventSource: SSE;
    reactiveUrl: string = '/services/bepliccoreprofileintegra/api/profile/merge';
    partnerConfiguration: PartnerConfiguration;

    // Private
    private urlRooms = UrlList.URL_ROOMS;
    private urlConversation = UrlList.URL_CONVERSATIONS;
    private _onNavigationChanged: BehaviorSubject<any>;
    private _onNavigationRegistered: BehaviorSubject<any>;
    private _onNavigationUnregistered: BehaviorSubject<any>;
    private _onNavigationItemAdded: BehaviorSubject<any>;
    private _onNavigationItemUpdated: BehaviorSubject<any>;
    private _onNavigationItemRemoved: BehaviorSubject<any>;
    private _currentNavigationKey: string;
    private _registry: { [key: string]: any } = {};

    public _onLoadRooms: BehaviorSubject<any>;
    public _onNodesLoaded: BehaviorSubject<any>;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private zone: NgZone,
        private translate:
            TranslateService,
        private socketService: SocketService,
        private _localStorageService: LocalStorageService,
        private _snackBar: MatSnackBar,

    ) {
        this.rooms = [];
        // Set the defaults
        this.onItemCollapsed = new Subject();
        this.onItemCollapseToggled = new Subject();

        // Set the private defaults
        this._currentNavigationKey = null;
        this._onNavigationChanged = new BehaviorSubject(null);
        this._onNavigationRegistered = new BehaviorSubject(null);
        this._onNavigationUnregistered = new BehaviorSubject(null);
        this._onNavigationItemAdded = new BehaviorSubject(null);
        this._onNavigationItemUpdated = new BehaviorSubject(null);
        this._onNavigationItemRemoved = new BehaviorSubject(null);
        this._onLoadRooms = new BehaviorSubject(null);
        this._onNodesLoaded = new BehaviorSubject(null);
    }

    public getReactiveProfile(): void {
        if (this.getUserSessionProfile()) {
            this.importFile().subscribe(
                (node) => {
                    this._onNodesLoaded.next(node);
                    this.mapNodes(node);
                },
                (error) => {
                    console.error(`Error while getting file import progress: ${error}`);
                },
                () => {
                    // on complete, close connection 
                    // this._analyticsDashboardService.closeConnetion();
                },
            );
        }
    }

    mapNodes(node: any): void {
        if ('serverProperties' in node) {
            if (this.userProfile) {
                let hostName = node['serverProperties'].remote;
                // Set hostName based on configuration if it exists
                if (this.userProfile.frontConfigurations.partner && this.userProfile.frontConfigurations.partner === 2) {
                    const configValue = getConfigurationValue(FrontConfiguration.PARTNER_HOSTNAME);
                    if (configValue) {
                        hostName = configValue;
                    }
                }
                // Set hostName to browser storage
                sessionStorage.setItem('hostName', hostName);
                // Starting room connections to the websocket
                if (hostName) {
                    this.socketService.initStompConnection(hostName);
                } else {
                    console.warn("Hostname not found.");
                }
            }
        }
    }

    initCurrentNavigation(navigationMenu: any, customerUserRooms: any[]) {
        // Get CurrentUser
        this.userProfile = this.getUserSessionProfile();

        // Build navigation menu
        this.buildNavigationMenu(navigationMenu, customerUserRooms);

        for (const numero in navigationMenu) {
            const obj = navigationMenu[numero];
            if (obj.hasOwnProperty('children')) {
                const child = obj.children;
                // tslint:disable-next-line: forin
                for (const second in child) {
                    const obj2 = child[second];
                    if (obj2.translate) {
                        obj2.title = this.translate.instant(obj2.translate);
                    }
                    if (obj2.hasOwnProperty('children')) {
                        const child3 = obj2.children;
                        // tslint:disable-next-line: forin
                        for (const third in child3) {
                            const obj3 = child3[third];
                            if (obj3.translate) {
                                obj3.title = this.translate.instant(obj3.translate);
                            }
                        }
                    }
                }
            }
            obj.title = this.translate.instant(obj.translate);
        }

        // Name navigation key
        const navigationKey = 'inside';
        // Delete navigation key if it already exists
        if (this._registry[navigationKey]) {
            this.unregister(navigationKey)
        }
        this.register(navigationKey, navigationMenu);
        // Set the main navigation as our current navigation
        this.setCurrentNavigation(navigationKey);

    }

    getUserSessionProfile(): CustomerAccount {
        return this.userProfile = this._localStorageService.getCurrentUser();
    }

    public importFile(): Observable<any> {
        const body = {
            login: this.userProfile.login
        }
        // you can append other data if needed
        // formData.append('param', variable);

        return this.getServerSentEvent(this.reactiveUrl, body);
    }

    private getServerSentEvent(url: string, data: any): Observable<any> {
        return new Observable<any>((observer) => {
            const eventSource = this.getEventSourceWithPost(url, data);
            // Launch query
            eventSource.stream();
            // on answer from message listener 
            eventSource.onmessage = (event) => {
                const body = JSON.parse(event.data);
                this.zone.run(() => {
                    observer.next(body);
                });
            };
            eventSource.onerror = (error) => {
                this.zone.run(() => {
                    observer.error(error);
                });
            };
        });
    }
    /**
     * Constructor of SSE service
     * @param kcService injection to get the authorization token
     */

    /**
     * Create an event source of POST request
     * @param API url 
     * @formData data (file, ...etc.)
     */
    public getEventSourceWithPost(url: string, formData: any): SSE {
        return this.buildEventSource(url, 'POST', formData);
    }

    /**
    * Create an event source of GET request
    * @param API url 
    * @formData data (file, ...etc.)
    */
    public getEventSourceWithGet(url: string, formData: FormData): SSE {
        return this.buildEventSource(url, 'GET', formData);
    }

    /**
     * Building the event source
     * @param url  API URL
     * @param meth  (POST, GET, ...etc.)
     * @param formData data
     */
    private buildEventSource(url: string, meth: string, formData: any): SSE {
        const options = this.buildOptions(meth, formData);
        this.eventSource = new SSE(url, options);

        // add listener
        this.eventSource.addEventListener('message', (e) => {
            return e.data;
        });

        return this.eventSource;
    }

    /**
     * close connection
     */
    public closeEventSource() {
        if (!!this.eventSource) {
            this.eventSource.close();
        }
    }

    /**
     * Récupération du token à passer dans le header de la requête
     */
    protected checkAuthorization(): string {
        const authToken = localStorage.getItem('auth_token') || '';
        // If we are on localhost (my environment.enableTokenInterceptor = false) we don't need a token
        const auth = localStorage.getItem('auth_token') ? 'Bearer ' + authToken : '';
        return auth;
    }

    /**
     * Build query options
     * @param meth POST or GET
     * @param formData data
     */
    private buildOptions(meth: string, formData: any,): any {
        const auth = this.checkAuthorization();
        return {
            payload: JSON.stringify(formData),
            method: meth,
            headers: { "Authorization": auth, "Content-Type": "application/json" }
        };
    }

    getRooms(): Promise<any> {
        const body = {
            customeruserId: this.userProfile.id,
            customersId: this.userProfile.customerId
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlRooms + UrlList.GET_CUSTOMER_ROOMS, body).subscribe((response: any) => {
                if (JSON.parse(sessionStorage.getItem('myRooms'))) {
                    sessionStorage.removeItem('myRooms');
                    sessionStorage.setItem('myRooms', JSON.stringify(response));
                } else {
                    sessionStorage.setItem('myRooms', JSON.stringify(response));
                }
                resolve(response);
            }, reject);
        });
    }

    getChats(rooms): Promise<any> {
        const body = {
            room: rooms
        };
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlConversation + UrlList.GET_CHATS_BY_ROOMS, body)
                .subscribe((chats: any) => {
                    chats.forEach(chat => {
                        const found = this.rooms.find(room => room.id === chat.roomId);
                        if (found) {
                            const chatNotView = {
                                contactId: chat.contactId,
                                contactName: chat.contactName,
                                conversationMode: chat.conversationMode,
                                customerUserId: chat.customerUserId,
                                id: chat.id,
                                lastMessageDate: chat.lastMessageDate,
                                roomId: chat.roomId,
                                state: chat.state,
                                modality: found.modality
                            };
                            this.chatsNotView.push(chatNotView);
                        }

                    });

                    resolve(this.chatsNotView);
                    // this._onLoadRooms.next(chats);
                }, reject);

        });

    }

    getNotViewedChats(chats): Promise<any> {
        return new Promise((resolve, reject) => {
            const filteredArray = chats.filter(chat => {
                return chat.state === 'NOTVIEW';
            }).map(obj => obj);

            resolve(filteredArray);
        });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get onNavigationChanged
     *
     * @returns {Observable<any>}
     */
    get onNavigationChanged(): Observable<any> {
        return this._onNavigationChanged.asObservable();
    }

    /**
     * Get onNavigationRegistered
     *
     * @returns {Observable<any>}
     */
    get onNavigationRegistered(): Observable<any> {
        return this._onNavigationRegistered.asObservable();
    }

    /**
     * Get onNavigationUnregistered
     *
     * @returns {Observable<any>}
     */
    get onNavigationUnregistered(): Observable<any> {
        return this._onNavigationUnregistered.asObservable();
    }

    /**
     * Get onNavigationItemAdded
     *
     * @returns {Observable<any>}
     */
    get onNavigationItemAdded(): Observable<any> {
        return this._onNavigationItemAdded.asObservable();
    }

    /**
     * Get onNavigationItemUpdated
     *
     * @returns {Observable<any>}
     */
    get onNavigationItemUpdated(): Observable<any> {
        return this._onNavigationItemUpdated.asObservable();
    }

    /**
     * Get onNavigationItemRemoved
     *
     * @returns {Observable<any>}
     */
    get onNavigationItemRemoved(): Observable<any> {
        return this._onNavigationItemRemoved.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register the given navigation
     * with the given key
     *
     * @param key
     * @param navigation
     */
    register(key, navigation): void {
        // Check if the key already being used
        if (this._registry[key]) {
            console.error(`The navigation with the key '${key}' already exists. Either unregister it first or use a unique key.`);

            return;
        }

        // Add to the registry
        this._registry[key] = navigation;

        // Notify the subject
        this._onNavigationRegistered.next([key, navigation]);
    }

    /**
     * Unregister the navigation from the registry
     * @param key
     */
    unregister(key): void {
        // Check if the navigation exists
        if (!this._registry[key]) {
            console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);
        }

        // Unregister the sidebar
        delete this._registry[key];

        // Notify the subject
        this._onNavigationUnregistered.next(key);
    }

    /**
     * Get navigation from registry by key
     *
     * @param key
     * @returns {any}
     */
    getNavigation(key): any {
        // Check if the navigation exists
        if (!this._registry[key]) {
            console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);
            return;
        }

        // Return the sidebar
        return this._registry[key];
    }

    /**
     * Get flattened navigation array
     *
     * @param navigation
     * @param flatNavigation
     * @returns {any[]}
     */
    getFlatNavigation(navigation, flatNavigation: FuseNavigationItem[] = []): any {
        for (const item of navigation) {
            if (item.type === 'item') {
                flatNavigation.push(item);

                continue;
            }

            if (item.type === 'collapsable' || item.type === 'group') {
                if (item.children) {
                    this.getFlatNavigation(item.children, flatNavigation);
                }
            }
        }

        return flatNavigation;
    }

    /**
     * Get the current navigation
     *
     * @returns {any}
     */
    getCurrentNavigation(): any {
        if (!this._currentNavigationKey) {
            console.warn(`The current navigation is not set.`);

            return;
        }
        return this.getNavigation(this._currentNavigationKey);
    }

    /**
     * Set the navigation with the key
     * as the current navigation
     *
     * @param key
     */
    setCurrentNavigation(key): void {
        // Check if the sidebar exists
        if (!this._registry[key]) {
            console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);

            return;
        }

        // Set the current navigation key
        this._currentNavigationKey = key;

        // Notify the subject
        this._onNavigationChanged.next(key);
    }

    /**
     * Get navigation item by id from the
     * current navigation
     *
     * @param id
     * @param {any} navigation
     * @returns {any | boolean}
     */
    getNavigationItem(id, navigation = null): any | boolean {
        if (!navigation) {
            navigation = this.getCurrentNavigation();
        }

        for (const item of navigation) {
            if (item.id === id) {
                return item;
            }

            if (item.children) {
                const childItem = this.getNavigationItem(id, item.children);

                if (childItem) {
                    return childItem;
                }
            }
        }

        return false;
    }

    /**
     * Get the parent of the navigation item
     * with the id
     *
     * @param id
     * @param {any} navigation
     * @param parent
     */
    getNavigationItemParent(id, navigation = null, parent = null): any {
        if (!navigation) {
            navigation = this.getCurrentNavigation();
            parent = navigation;
        }

        for (const item of navigation) {
            if (item.id === id) {
                return parent;
            }

            if (item.children) {
                const childItem = this.getNavigationItemParent(id, item.children, item);

                if (childItem) {
                    return childItem;
                }
            }
        }

        return false;
    }

    /**
     * Add a navigation item to the specified location
     *
     * @param item
     * @param id
     */
    addNavigationItem(item, id): void {
        // Get the current navigation
        const navigation: any[] = this.getCurrentNavigation();

        if (navigation) {
            // Add to the end of the navigation
            if (id === 'end') {
                navigation.push(item);

                // Trigger the observable
                this._onNavigationItemAdded.next(true);

                return;
            }

            // Add to the start of the navigation
            if (id === 'start') {
                navigation.unshift(item);

                // Trigger the observable
                this._onNavigationItemAdded.next(true);

                return;
            }

            // Add it to a specific location
            const parent: any = this.getNavigationItem(id);

            if (parent) {
                // Check if parent has a children entry,
                // and add it if it doesn't
                if (!parent.children) {
                    parent.children = [];
                }

                // Add the item
                parent.children.push(item);
            }

            // Trigger the observable
            this._onNavigationItemAdded.next(true);
        }

    }

    /**
     * Update navigation item with the given id
     *
     * @param id
     * @param properties
     */
    updateNavigationItem(id, properties): void {
        // Get the navigation item
        const navigationItem = this.getNavigationItem(id);

        // If there is no navigation with the give id, return
        if (!navigationItem) {
            return;
        }

        // Merge the navigation properties
        _.merge(navigationItem, properties);

        // Trigger the observable
        this._onNavigationItemUpdated.next(true);
    }

    /**
     * Remove navigation item with the given id
     *
     * @param id
     */
    removeNavigationItem(id): void {
        const item = this.getNavigationItem(id);

        // Return, if there is not such an item
        if (!item) {
            return;
        }

        // Get the parent of the item
        let parent = this.getNavigationItemParent(id);

        // This check is required because of the first level
        // of the navigation, since the first level is not
        // inside the 'children' array
        parent = parent.children || parent;

        // Remove the item
        parent.splice(parent.indexOf(item), 1);

        // Trigger the observable
        this._onNavigationItemRemoved.next(true);
    }

    sendNotification(subscription: any): Observable<any> {
        return this._httpClient.post('/services/bepliccorewebsocket/api/send-notification', subscription);
    }

    buildNavigationMenu(navigation: FuseNavigation[], customerUserRooms: any[]) {
        const appMenu: FuseNavigationItem = navigation.find(elem => elem.id === 'applications');
        if (appMenu) {
            const chatMenu: FuseNavigationItem = appMenu.children.find(elem => elem.id === 'chat');
            if (chatMenu) {
                chatMenu.type = 'collapsable';
                chatMenu.children = [];
                chatMenu.url = null;
                // Propiedades para abrir un elemento collapsable en la carga del menu
                /* chatMenu.toggleOpen = true
                chatMenu.classes = 'open' */

                if (customerUserRooms && customerUserRooms.length > 0) {
                    customerUserRooms.forEach(customerUserRoom => {
                        // Set children
                        chatMenu.children.push(this.buildChidrenFromMenuOption(customerUserRoom.room, customerUserRoom.chatStatusInfo));
                    });
                    chatMenu.function = null;

                } else {
                    // Add empty message
                    const message = this.userProfile.authorities.includes('ROLE_ATC') ? 'NAV.EMPTY.REQUEST_FOR_ROOM' : 'NAV.EMPTY.ROOM.LIST';
                    // Add children with empty rooms message
                    chatMenu.children.push(this.getChildrenEmptyList(message));
                    chatMenu.function = () => { this.openSnackBar(); }
                }

                chatMenu.badge = null;
            }
        }
    }

    buildChidrenFromMenuOption(room: any, chatStatusInfo: any): FuseNavigationItem {
        // Verificar si hay mensajes no vistos o no respondidos
        if (chatStatusInfo && (chatStatusInfo.chatsNotView.length > 0 || chatStatusInfo.chatsNotResponse.length > 0)) {
            const badgeTitle = chatStatusInfo.chatsNotView.length > 0 ? chatStatusInfo.chatsNotView.length : chatStatusInfo.chatsNotResponse.length;
            const badgeBg = chatStatusInfo.chatsNotView.length > 0 ? "#09d261" : "#D9A72D";

            const navigationItem: FuseNavigationItem = {
                id: room.id + '',
                title: room.name,
                type: 'item',
                imageUrl: this.getRoomImgUrl(room.channel.id),
                url: `/apps/chat/all/${room.id}`,
                limitChildren: true,
                childrenType: 'room',
                badge: {
                    title: badgeTitle.toString(),
                    translate: badgeTitle.toString(),
                    bg: badgeBg
                }
            };

            return navigationItem;
        } else {
            const navigationItem: FuseNavigationItem = {
                id: room.id + '',
                title: room.name,
                type: 'item',
                imageUrl: this.getRoomImgUrl(room.channel.id),
                url: `/apps/chat/all/${room.id}`,
                limitChildren: true,
                childrenType: 'room'
            };

            return navigationItem;
        }
    }

    getChildrenEmptyList(title: string): FuseNavigationItem {
        return {
            id: 'EMPTY_ROOM',
            title: title,
            type: 'item',
            function: () => {
                this.openSnackBar();
            }
        }
    }

    openSnackBar() {
        const message = this.userProfile.authorities.includes('ROLE_ATC') ? 'NAV.EMPTY.REQUEST_FOR_ROOM' : 'NAV.EMPTY.ROOM.LIST';
        this._snackBar.open(this.translate.instant(message), this.translate.instant('TEMPLATE.SNACKBAR.CLOSE'), {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['custom-snackbar-info'],
        });
    }

    getRoomImgUrl(channelId: number): string {
        if (channelId === 2) {
            return '/assets/images/rooms/twitter_generic.png';
        }
        if (channelId === 3) {
            return '/assets/images/rooms/roomWeb_generic.png';
        }
        if (channelId === 4) {
            return '/assets/images/rooms/messenger_generic.png';
        }
        if (channelId === 5) {
            return '/assets/images/rooms/email_generic.png';
        }
        if (channelId === 6) {
            return '/assets/images/rooms/instagram_generic.png';
        }
        // Default Value
        return '/assets/images/rooms/Whatsapp_generic.png';
    }

    activeNavigationItemUpdated() {
        // default value: true
        this._onNavigationItemUpdated.next(true);
    }
}
