import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as Stomp from 'webstomp-client';
import * as SockJS from 'sockjs-client';
import { BehaviorSubject } from 'rxjs';

import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
// import { CSRFService } from './csrf.service';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { environment } from 'environments/environment';
import { LocalStorageService } from 'app/services/local-storage.service';

const IS_ENABLED_DEBUG_SOCKETJS = environment.IS_ENABLED_DEBUG_SOCKETJS;

@Injectable()
export class SocketService {

    private stompClientWhatsapp = null;
    private stompClientWeb = null;
    private stompClient = null;
    private stompBusiness = null;
    private stompNotifyEvent = null;
    private stompNotifyAckEvent = null;
    private WEBSOCKET_URL = 'https://dev5.beplic.io/tracker';
    private WEBSOCKET_BUSINESS_URL = 'https://dev5.beplic.io/notify';
    private WEBSOCKET_NOTIFY_EVENTS_URL = 'https://dev5.beplic.io/notify-events';
    private stompNameList = [];

    greetings: string[] = [];
    disabled = true;
    userProfile: CustomerAccount;
    mobileCounter: any = 0;

    whatsappMethodCallSource: BehaviorSubject<any>;
    webMethodCallSource: BehaviorSubject<any>;
    messengerMethodCallSource: BehaviorSubject<any>;
    emailMethodCallSource: BehaviorSubject<any>;
    businessMethodCallSource: BehaviorSubject<any>;
    instagramMethodCallSource: BehaviorSubject<any>;
    notifyEventMethodCallSource: BehaviorSubject<any>;
    onMobileNotification: BehaviorSubject<any>;
    notifyAckEventMethodCallSource: BehaviorSubject<any>;

    roomTypes = [{ name: 'whatsapp', value: 1 }, { name: 'web', value: 3 }, { name: 'messenger', value: 4 }, { name: 'email', value: 5 }, { name: 'instagram', value: 6 }];
    channelType = { whatsapp: 'whatsapp', web: 'web', messenger: 'messenger', email: 'email', instagram: 'instagram' };

    constructor(
        private _httpClient: HttpClient,
        private _localStorageService: LocalStorageService
    ) {
        this.whatsappMethodCallSource = new BehaviorSubject(null);
        this.webMethodCallSource = new BehaviorSubject(null);
        this.messengerMethodCallSource = new BehaviorSubject(null);
        this.businessMethodCallSource = new BehaviorSubject(null);
        this.onMobileNotification = new BehaviorSubject(null);
        this.emailMethodCallSource = new BehaviorSubject(null);
        this.instagramMethodCallSource = new BehaviorSubject(null);
        this.notifyEventMethodCallSource = new BehaviorSubject(null);
        this.notifyAckEventMethodCallSource = new BehaviorSubject(null);
    }

    setConnected(connected: boolean): void {
        this.disabled = !connected;

        if (connected) {
            this.greetings = [];
        }
    }

    initStompConnection(hostName: string): void {
        // Get current user data
        this.getUserProfile();

        if (!this.stompClient) {
            this.stompClientSocketConnection(hostName);
        }
        if (!this.stompBusiness) {
            this.businessSocketConnection(hostName);
        }
        if (!this.stompNotifyEvent) {
            this.apiResponseSocketConnection(hostName);
        }
        // TODO: se comenta porque actualmente no se recibe ack de mensajes y evitar conexiones innecesarias.
        /* if (!this.stompNotifyAckEvent) {
            this.stompNotifyAckSocketConnection(hostName)
        } */
    }

    stompClientSocketConnection(hostName: string): void {
        //this.stompClient = Stomp.over(new SockJS(this.WEBSOCKET_URL));
        this.stompClient = Stomp.over(new SockJS(`//${hostName}/tracker`));
        // Add Stomp to List
        this.stompNameList.push('stompClient');
        // Hide debug messages
        this.hideStompDegubOutput(this.stompClient);
        // Connect to Stomp
        this.stompClient.connect({}, (frame) => {
            // Starting connection of user rooms to websocket
            this.initRoomConnection();
        }, err => {
            console.error("Failed to connect to stomp - stompClient: ", err);
        });
    }

    businessSocketConnection(hostName: string): void {
        const businessId = this.userProfile.customerId;
        // this.stompBusiness = Stomp.over(new SockJS(this.WEBSOCKET_BUSINESS_URL));
        this.stompBusiness = Stomp.over(new SockJS(`//${hostName}/notify`));
        // Add Stomp to List
        this.stompNameList.push('stompBusiness');
        // Hide debug messages
        this.hideStompDegubOutput(this.stompBusiness);
        // Connect to Stomp
        this.stompBusiness.connect({}, (frame) => {
            //console.log('Connected: ' + frame);
            this.stompBusiness.subscribe(`/customer/${businessId}`, (message: any) => {
                this.businessMethodCallSource.next(JSON.parse(message.body));
            });
        }, err => {
            console.error("Failed to connect to stomp - stompBusiness: ", err);
        });
    }

    apiResponseSocketConnection(hostName: string): void {
        const customerUserId = this.userProfile.id;
        // this.stompNotifyEvent = Stomp.over(new SockJS(this.WEBSOCKET_NOTIFY_EVENTS_URL));
        this.stompNotifyEvent = Stomp.over(new SockJS(`//${hostName}/notify-events`));
        // Add Stomp to List
        this.stompNameList.push('stompNotifyEvent');
        // Hide debug messages
        this.hideStompDegubOutput(this.stompNotifyEvent);
        // Connect to Stomp
        this.stompNotifyEvent.connect({}, (frame) => {
            //console.log('Connected: ' + frame);
            this.stompNotifyEvent.subscribe(`/api-response/${customerUserId}`, (message: any) => {
                this.notifyEventMethodCallSource.next(JSON.parse(message.body));
            });
        }, err => {
            console.error("Failed to connect to stomp - stompNotifyEvent: ", err);
        });
    }

    stompNotifyAckSocketConnection(hostName: string): void {
        const customerUserId = this.userProfile.id;
        // this.stompNotifyAckEvent = Stomp.over(new SockJS(this.WEBSOCKET_NOTIFY_EVENTS_URL));
        this.stompNotifyAckEvent = Stomp.over(new SockJS(`//${hostName}/notify-events`));
        // Add Stomp to List
        this.stompNameList.push('stompNotifyAckEvent');
        // Hide debug messages
        this.hideStompDegubOutput(this.stompNotifyAckEvent);
        // Connect to Stomp
        this.stompNotifyAckEvent.connect({}, (frame) => {
            //console.log('Connected: ' + frame);
            this.stompNotifyAckEvent.subscribe(`/message/ack/${customerUserId}`, (message: any) => {
                this.notifyAckEventMethodCallSource.next(JSON.parse(message.body));
            });
        }, err => {
            console.error("Failed to connect to stomp - stompNotifyAckEvent: ", err);
        });
    }

    initRoomConnection(): void {
        const myRooms: any[] = JSON.parse(sessionStorage.getItem('myRooms'));
        if (this.stompClient) {
            if (myRooms.length !== 0) {
                let connectedRooms = [];
                this.roomTypes.forEach(j => {
                    let filteredRooms = [];
                    filteredRooms = myRooms.filter(r => r.room.channel.id === j.value);
                    if (filteredRooms.length) {
                        filteredRooms.forEach(fr => {
                            this.sendMessages(j.name, fr.room.id);
                        });
                        connectedRooms = [...connectedRooms, ...filteredRooms.map(customerUserRoom => customerUserRoom.room.id)];
                    }
                });
                // Delete storage item
                sessionStorage.removeItem('connectedRooms');
                // Update storage item
                sessionStorage.setItem('connectedRooms', JSON.stringify(connectedRooms));
            }
        } else {
            console.warn("The stomp 'stompClient' is not initialized");
        }
    }

    getHostName(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(UrlList.URL_SECURITY + '/propertiesServer').subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    connectThroughGateway(): void {
        this.getUserProfile();
    }

    getUserProfile(): void {
        this.userProfile = this._localStorageService.getCurrentUser();
    }

    disconnect(): void {
        // Clear stomps
        this.stompNameList.map(stompName => {
            if (this[stompName] && this[stompName].connected) {
                this[stompName].disconnect();
                this[stompName] = null;
            } if (this[stompName]) {
                this[stompName] = null;
            }
        });
        this.setConnected(false);
    }

    sendMobileNotification(evt, room): void {
        this.onMobileNotification.next([evt, room]);
    }

    setMobileNotification(counter: any): void {
        this.mobileCounter = parseInt(counter);
    }

    sendBusinessEvent(msj): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        this.stompClient.send(
            '/report/notify',
            {},
            JSON.stringify({
                chatMessage: {
                    id: msj.id,
                    messageType: 'OUT',
                    customerId: this.userProfile.customerId,
                    state: 'DELETED',
                    chat: {
                        id: msj.chat.id,
                        roomId: msj.chat.roomId,
                        contactId: msj.chat.contactId,
                        contactName: msj.chat.contactName,
                        customeruserName: msj.chat.customeruserName,
                        lastMessageDate: msj.chat.lastMessageDate,
                        state: msj.chat.state
                    }
                },
                contact: {
                    channelId: currentRoom.room.channel.id,
                }
            }), {}
        );
    }

    removeMessage(msj): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        this.stompClient.send(
            `/app/message`,
            JSON.stringify({
                chatMessage: {
                    id: msj.id,
                    messageType: 'OUT',
                    customerId: this.userProfile.customerId,
                    state: 'DELETED',
                    chat: {
                        id: msj.chat.id,
                        roomId: msj.chat.roomId,
                        contactId: msj.chat.contactId,
                        contactName: msj.chat.contactName,
                        customeruserName: msj.chat.customeruserName,
                        lastMessageDate: msj.chat.lastMessageDate,
                        state: msj.chat.state
                    }
                },
                contact: {
                    channelId: currentRoom.room.channel.id,
                }
            }), {}
        );
    }

    sendName(chat): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        if (this.stompClient) {
            this.stompClient.send(
                // this.stompClientWhatsapp.send(
                '/app/message',
                JSON.stringify({
                    chatMessage: {
                        messageType: 'OUT',
                        customerId: this.userProfile.customerId,
                        chat: {
                            id: chat.chatId,
                            roomId: chat.roomId,
                            contactId: chat.contactId,
                            contactName: chat.contactName,
                            conversationMode: chat.conversationMode,
                            customerUserId: chat.customerUserId,
                            customeruserName: chat.customeruserName,
                            email: chat.email,
                            followUpMessages: chat.followUpMessages,
                            lastMessageDate: chat.lastMessageDate,
                            label: chat.label,
                            message: chat.message,
                            phone: chat.phone,
                            state: 'NOTRESPONSE',
                            expiredDate: chat.expirationDate,
                            sessionState: chat.sessionState
                        }
                    },
                    contact: {
                        channelId: currentRoom.room.channel.id,
                    }
                }), {}
            );
        } else {
            console.warn("The stomp 'stompClient' is not initialized");
        }
    }

    sendIn(body): void {
        this.stompClient.send('/app/message', JSON.stringify(body));
    }

    onChangeRoomModality(chat): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        this.stompClient.send(
            // this.stompClientWhatsapp.send(
            `/app/message`,
            JSON.stringify({
                chatMessage: {
                    text: 'change_modality',
                    messageType: 'OUT',
                    chat: {
                        roomId: chat.id,
                    }
                },
                contact: {
                    channelId: chat.channelId,
                }
            }), {}
        );
    }
    onChangeRoomModalityNull(chat): void {
        this.stompClient.send(
            // this.stompClientWhatsapp.send(
            `/app/message`,
            JSON.stringify({
                chatMessage: {
                    text: 'null',
                    messageType: 'OUT',
                    chat: {
                        roomId: chat.id,
                    }
                },
                contact: {
                    channelId: chat.channelId,
                }
            }), {}
        );
    }

    onChangeRoomsAssidned(room, type): void {
        if (room && type === 'not_null') {
            this.stompBusiness.send(
                // this.stompClientWhatsapp.send(
                `/report/notify`,
                JSON.stringify({
                    chatMessage: {
                        text: 'change_rooms_assigned',
                        customerId: this.userProfile.customerId,
                        messageType: 'OUT',
                        chat: {
                            roomId: room.rooms.id,
                            customerUserId: room.customeruserId,
                        }
                    },
                    contact: {
                        channelId: room.rooms.channelId,
                    }
                }), {}
            );
        } else if (room && type === 'null') {
            this.stompBusiness.send(
                // this.stompClientWhatsapp.send(
                `/report/notify`,
                JSON.stringify({
                    chatMessage: {
                        text: 'null',
                        customerId: this.userProfile.customerId,
                        messageType: 'OUT',
                        chat: {
                            roomId: room.rooms.id,
                        }
                    },
                    contact: {
                        channelId: room.rooms.channelId,
                    }
                }), {}
            );
        } else if (!room && type === 'not_null') {
            this.stompBusiness.send(
                // this.stompClientWhatsapp.send(
                `/report/notify`,
                JSON.stringify({
                    chatMessage: {
                        text: 'not_null',
                        customerId: this.userProfile.customerId,
                        messageType: 'OUT',
                        chat: {
                            customerId: this.userProfile.customerId,
                        }
                    },
                    contact: {
                    }
                }), {}
            );
        } else if (!room && type === 'null') {
            this.stompBusiness.send(
                // this.stompClientWhatsapp.send(
                `/report/notify`,
                JSON.stringify({
                    chatMessage: {
                        text: 'null',
                        customerId: this.userProfile.customerId,
                        messageType: 'OUT',
                        chat: {
                            customerId: this.userProfile.customerId,
                        }
                    },
                    contact: {
                    }
                }), {}
            );
        }

    }

    closeChat(chat): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        const currentChat = JSON.parse(sessionStorage.getItem('currentChat'));
        this.stompClient.send(
            `/app/message`,
            JSON.stringify({
                chatMessage: {
                    messageType: 'OUT',
                    customerId: this.userProfile.customerId,
                    chat: {
                        id: chat.id,
                        roomId: chat.roomId,
                        contactId: chat.contactId,
                        contactName: chat.contactName,
                        customerUserId: chat.customerUserId,
                        customeruserName: currentChat.customeruserName,
                        followUpMessages: currentChat.followUpMessages,
                        lastMessageDate: chat.lastMessageDate,
                        label: currentChat.label,
                        state: chat.state
                    }
                },
                contact: {
                    channelId: currentRoom.room.channel.id,
                }
            }), {}
        );
    }

    onLabelAssigned(chat): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        const currentChat = JSON.parse(sessionStorage.getItem('currentChat'));
        this.stompClient.send(
            `/app/message`,
            JSON.stringify({
                chatMessage: {
                    text: 'on_label_assigned',
                    messageType: 'OUT',
                    chat: {
                        contactId: chat.contactId,
                        roomId: chat.roomId,
                    }
                },
                contact: {
                    channelId: currentRoom.room.channel.id,
                    labels: chat.label
                }
            }), {}
        );
    }

    onFollowedMessage(chat, messages): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        this.stompClient.send(
            `/app/message`,
            JSON.stringify({
                chatMessage: {
                    text: 'on_followed_message',
                    messageType: 'OUT',
                    chat: {
                        contactId: chat.contactId,
                        roomId: chat.roomId,
                        followUpMessages: messages
                    }
                },
                contact: {
                    channelId: currentRoom.room.channel.id,
                }
            }), {}
        );
    }

    onLabelAssignedFromContacts(contact): void {
        this.stompBusiness.send(
            '/report/notify',
            JSON.stringify({
                chatMessage: {
                    text: 'on_label_assigned',
                    customerId: this.userProfile.customerId,
                    messageType: 'OUT',
                    chat: {
                        contactId: contact.id,
                        label: contact.label
                    }
                },
                contact: {
                }
            }), {}
        );
    }

    onRoleChangedToAdmin(evt, user): void {
        this.stompBusiness.send(
            '/report/notify',
            JSON.stringify({
                chatMessage: {
                    text: evt,
                    customerId: this.userProfile.customerId,
                    customerUserId: user.id,
                    messageType: 'OUT',
                    chat: {
                    }
                },
                contact: {
                }
            }), {}
        );
    }

    updateChat(chat: any): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        if (currentRoom) {
            this.stompClient.send(
                `/app/message`,
                JSON.stringify({
                    chatMessage: {
                        text: 'chat_update',
                        messageType: 'OUT',
                        chat: {
                            contactId: chat.id,
                            contactName: chat.name,
                            roomId: currentRoom.room.id,
                            email: chat.email
                        }
                    },
                    contact: {
                        channelId: currentRoom.room.channel.id,
                        alias: chat.alias
                    }
                }), {}
            );
        } else {
            this.stompBusiness.send(
                // this.stompClientWhatsapp.send(
                `/report/notify`,
                JSON.stringify({
                    chatMessage: {
                        text: 'update_contact',
                        customerId: this.userProfile.customerId,
                        messageType: 'OUT',
                        chat: {
                            contactId: chat.id,
                            contactName: chat.name,
                        }
                    },
                    contact: {
                        alias: chat.alias
                    }
                }), {}
            );
        }
    }

    showGreeting(message): void {
        this.greetings.push(message);
    }

    openConnectionForExternalChat(roomId: number): void {
        const tmp = this.channelType.web;

        const hostName = sessionStorage.getItem('hostName');
        const socket = new SockJS(`//${hostName}/tracker`);
        // const socket = new SockJS(this.WEBSOCKET_URL);
        this.stompClientWeb = Stomp.over(socket);
        // Add Stomp to List
        this.stompNameList.push('stompClientWeb');
        // Hide debug messages
        this.hideStompDegubOutput(this.stompClientWeb);
        // Connect to Stomp
        this.stompClientWeb.connect({}, (frame) => {
            //console.log('Connected: ' + frame);
            this.stompClientWeb.subscribe(`/topic/${tmp}/message/${roomId}`, (message: any) => {
                this.webMethodCallSource.next(JSON.parse(message.body));
            });
        }, err => {
            console.error("Failed to connect to stomp - stompClientWeb: ", err);
        });
    }

    openConnectionForNewRoom(roomType: string, roomId: number): void {
        try {
            this.sendMessages(roomType, roomId);
        } catch (error) {
            console.error(error);
            console.warn(`The room with id: ${roomId} and channel: ${roomType} could not subscribe to the webhook`);
        }
    }

    sendMessages(roomType: string, roomId: number): void {
        switch (roomType) {
            case this.channelType.whatsapp:
                this.stompClient.subscribe(`/topic/${roomType}/message/${roomId}`, (message: any) => {
                    this.whatsappMethodCallSource.next(JSON.parse(message.body));
                });
                break;
            case this.channelType.web:
                this.stompClient.subscribe(`/topic/${roomType}/message/${roomId}`, (message: any) => {
                    this.webMethodCallSource.next(JSON.parse(message.body));
                });
                break;
            case this.channelType.messenger:
                this.stompClient.subscribe(`/topic/${roomType}/message/${roomId}`, (message: any) => {
                    this.messengerMethodCallSource.next(JSON.parse(message.body));
                });
                break;
            case this.channelType.email:
                this.stompClient.subscribe(`/topic/${roomType}/message/${roomId}`, (message: any) => {
                    this.emailMethodCallSource.next(JSON.parse(message.body));
                });
                break;
            case this.channelType.instagram:
                this.stompClient.subscribe(`/topic/${roomType}/message/${roomId}`, (message: any) => {
                    this.instagramMethodCallSource.next(JSON.parse(message.body));
                });
                break;
            default: break;
        }
    }

    // TODO: ver si se puede reutilzar sino borrarlo
    getStompClientVariableNameAndMessageLoad(roomType: string): any {
        if (roomType === this.channelType.whatsapp) {
            return { stomp: 'stompClientWhatsapp', loadMessages: 'whatsappMethodCallSource' };
        } else if (roomType === this.channelType.web) {
            return { stomp: 'stompClientWeb', loadMessages: 'webMethodCallSource' };
        } else if (roomType === this.channelType.messenger) {
            return { stomp: 'stompClientMessenger', loadMessages: 'messengerMethodCallSource' };
        } else if (roomType === this.channelType.instagram) {
            return { stomp: 'stompClientInstagram', loadMessages: 'instagramMethodCallSource' };
        }
        return null;
    }

    // updateEmailState(chat): void {
    //   const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
    //   this.stompClient.send(
    //     // this.stompClientWhatsapp.send(
    //     '/app/message',
    //     JSON.stringify({
    //       chatMessage: {
    //         messageType: 'OUT',
    //         customerId: this.userProfile.customerId,
    //         chat: {
    //           id: chat.chatId,
    //           roomId: chat.roomId,
    //           contactName: chat.contactName,
    //           conversationMode: chat.conversationMode,
    //           customerUserId: chat.customerUserId,
    //           customeruserName: chat.customeruserName,
    //           email: chat.email,
    //           followUpMessages: chat.followUpMessages,
    //           lastMessageDate: chat.lastMessageDate,
    //           label: chat.label,
    //           message: chat.message,
    //           phone: chat.phone,
    //           state: 'NOTRESPONSE',

    //         }
    //       },
    //       contact: {
    //         channelId: currentRoom.room.channel.id,
    //       }
    //     }), {}
    //   );
    // }

    updateEmailState(mail): void {
        const currentRoom = JSON.parse(sessionStorage.getItem('currentRoom'));
        const currentChat = JSON.parse(sessionStorage.getItem('currentChat'));

        this.stompClient.send(
            `/app/message`,
            JSON.stringify({
                chatMessage: {
                    id: mail.id,
                    chatId: currentChat.chatId,
                    text: 'on_read_mail',
                    messageType: 'OUT',
                    state: 'READ',
                    chat: {
                        id: currentChat.chatId,
                        contactId: currentChat.contactId,
                        roomId: currentRoom.room.id,
                        contactName: currentChat.contactName,
                        lastMessageDate: currentChat.lastMessageDate,
                        state: currentChat.state,
                        email: currentChat.email
                    },
                    subject: mail.subject
                },
                contact: {
                    channelId: currentRoom.room.channel.id,
                }
            }), {}
        );
    }

    hideStompDegubOutput(stomp: any) {
        if (!IS_ENABLED_DEBUG_SOCKETJS && stomp) {
            stomp.debug = () => { };
        }
    }

}
