import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'beplic-card-report',
  templateUrl: './card-report.component.html',
  styleUrls: ['./card-report.component.scss']
})
export class CardReportComponent {

  @Input() report: DasboardReportResponse;
  @Output() dashboardOpen: EventEmitter<string> = new EventEmitter<string>();

  onDownloadClick(): void {
    this.dashboardOpen.emit(this.report.name);
  }

  /**Prevent default behavior to prevent page scrolling with Space key */
  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
        this.onDownloadClick();
        event.preventDefault(); 
    }
}


}
