import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'alert-user',
    templateUrl: './alert-user.component.html',
    styleUrls: ['./alert-user.component.scss'],
    animations: fuseAnimations,
})
export class AlertUserComponent {

    @Input() erroType: string = 'error'; // default value
    @Input() icon: string = 'error'; // default value
    @Input() showIcon: boolean = true // default value
    @Input() errorMessage: string;
    @Input() isTranslatable: boolean = false; // default value
    @Input() urlRedirect: string; // default value
    @Input() border_alert: string; // default value



    constructor() { }
}
