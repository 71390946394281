import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { ScriptData } from 'app/interfaces/generic/script-data.interface';

@Injectable({ providedIn: 'root' })
export class ScriptService {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    // TODO: eliminar metodo para usar el metodo "loadScript(...)"
    public loadFacebookScript(renderer: Renderer2, src: string): HTMLScriptElement {
        const script = renderer.createElement('script');
        script.src = src;
        script.async = true;
        script.defer = true;
        renderer.appendChild(this.document.body, script);
        return script;
    }

    public loadScript(data: ScriptData): HTMLScriptElement {
        const script = this.document.createElement('script');
        script.id = data.id;
        script.src = data.src;
        script.type = "text/javascript";
        script.async = data.async;
        script.defer = data.defer;
        document.getElementsByTagName('head')[0].appendChild(script);
        return script;
    }

    loadScriptWithPromise(data: ScriptData): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          const script = this.document.createElement('script');
          script.id = data.id;
          script.src = data.src;
          script.type = "text/javascript";
          script.async = data.async;
          script.defer = data.defer;
      
          script.onload = () => {
            resolve();
          };
          script.onerror = () => {
            reject(new Error('Could not load the script.'));
          };
          document.getElementsByTagName('head')[0].appendChild(script);
        });
      }

    getDataDopplerChatbot(): ScriptData {
        return {
            id: "ze-snippet",
            src: 'https://static.zdassets.com/ekr/snippet.js?key=d551604b-d03b-44e4-9ba6-7fdc92f56cb9'
        }
    }
}
