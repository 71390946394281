import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CustomerAccount } from '../model/user.model';
import { Customer } from '../model/customer.module';
import { UrlList } from '../url-list.enum';

@Injectable()
export class RegisterService {
    url = UrlList.URL_CORE;
    url2 = UrlList.URL;
    private user: CustomerAccount;

    constructor(private http: HttpClient) { }

    addCustomer(customer: Customer): Observable<any> {

        const body = {
            id: customer.id,
            firstName: customer.firstName,
            lastName: customer.lastName,
            username: customer.username,
            du: customer.du,
            // businessName: customer.businessName,
            // fiscalNumber: customer.fiscalNumber,
            active: customer.active,
            razonSocial: customer.razónSocial,
            cuit: customer.cuit,
            packId: customer.packId,
            rolesId: customer.rolesId,
            businessId: customer.businessId,
            bots: customer.bots
        };
        let headers = new HttpHeaders();
        headers = headers.append('Skip-Authorization', '');
        headers = headers.append('Content-Type', 'application/json');
        return this.http.post(this.url + UrlList.CUSTOMER, body, { headers: headers });
    }

    postRegisterUser(user: CustomerAccount): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Skip-Authorization', '');
        headers = headers.append('Content-Type', 'application/json');
        const body = {
            login: user.login,
            password: user.password,
            email: user.email,
            langKey: 'en',
            typeRegistration: user.typeRegistration
        };
        return this.http.post(this.url + UrlList.REGISTER, body, { headers: headers });
    }

    postRegisterCustomer(user: CustomerAccount): Observable<any> {
        const body = {
            firstName: user.login,
        };
        return this.http.post(this.url + UrlList.COSTUMER_RESOURCES, body);
    }

    getRegisterAccount(): Observable<any> {
        return this.http.get(this.url + UrlList.ACCOUNT);
    }

    getActivatedAccount(key: string): Observable<any> {
        // let headers = new HttpHeaders();
        // headers = headers.append('Skip-Authorization', '');
        // headers = headers.append('Content-Type', 'application/json');
        // console.log(this.url + UrlList.LOGIN_REGISTER + key);
        // return this.http.get(this.url + UrlList.LOGIN_REGISTER + key, { headers: headers });
        return this.http.get(this.url + UrlList.LOGIN_REGISTER + key);
    }

    /**
       *
       * @param customer se asigna al usuario al estado pendiente (0)
       */
    setStatePayment(customer: Customer) {
        const body = {
            state: 0,
            businessId: customer.businessId,
            paymentTypeId: 1
        }
        return this.http.post(this.url + UrlList.PAYMENT_CUSTOMER, body);
    }

    /**
       *
       * @param customer se actualiza al usuario a estado pagado (1)
       */
    setStatePaymentConfirm(customer: Customer) {
        const body = {
            id: customer.id
        }
        return this.http.put(this.url + UrlList.PAYMENT_CUSTOMER_STATE, body);
    }

    /*
       * obtener el estado de pago del usuario
       */
    getStatePayment(idCustomer: number) {
        const body = {
            id: idCustomer
        }
        return this.http.post(this.url + UrlList.PAYMENT_CUSTOMER_STATE, body);
    }

    getBusinnes(idBusiness: number): Observable<any> {
        return this.http.get(this.url + UrlList.BUSINESS + idBusiness);
    }

    /**
     * inicio de recuperacion de cuenta
     */
    initResetAccount(email: string) {
        let headers = new HttpHeaders();
        headers = headers.append('Skip-Authorization', 'true');
        return this.http.post(UrlList.URL_SECURITY + UrlList.RECOVER_ACCOUNT_INIT, email, { headers });
    }

    /*
     *fin de recuperacion de cuenta
     */
    finishResetAccount(key: string, password: string) {
        let headers = new HttpHeaders();
        headers = headers.append('Skip-Authorization', 'true');
        const body = {
            key: key,
            newPassword: password
        }
        return this.http.post(UrlList.URL_SECURITY + UrlList.RECOVER_ACCOUNT_FINISH, body, { headers });
    }

    resetPassword(login: string, password: string) {
        let headers = new HttpHeaders();
        headers = headers.append('Skip-Authorization', 'true');
        const body = {
            login: login,
            newPassword: password
        }
        return this.http.post(UrlList.URL_SECURITY + UrlList.UPDATE_PASSWORD, body, { headers });
    }


    /*
       * validacion de cuit
       */
    validateCuit(cuit: string): Observable<any> {
        return this.http.post(this.url + UrlList.VALIDATE_CUIT, cuit);
    }

    validateCUIT(cuit: string): Observable<any> {
        const body = {
            cuit: cuit
        }
        return this.http.post(this.url + UrlList.CUIT_EXISTS, body);
    }
    validateEmail(email: string): Observable<any> {
        const body = {
            email: email
        }
        return this.http.post(this.url + UrlList.EMAIL_EXISTS, body);
    }
    validateUser(user: string): Observable<any> {
        const body = {
            login: user
        }
        return this.http.post(this.url + UrlList.USER_EXISTS, body);
    }

    getHost(): Observable<any> {
        return this.http.get(UrlList.GET_HOST);

    }
}
