import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { checkUserConfiguration } from 'app/shared/util/common-functions';

@Directive({
    selector: '[hasFrontConfigurations]'
})
export class FrontConfigurationsDirective {

    private expectedConfigurations: Array<string> = [];
    private hasView: boolean;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainer: ViewContainerRef
    ) { }


    @Input()
    set hasFrontConfigurations(expectedConfigurations: Array<string>) {
        this.expectedConfigurations = expectedConfigurations;
        this.updateView();
    }

    @Input()
    set hasFrontConfigurationsElse(templateRef: TemplateRef<any> | null) {
        if (!this.hasView && templateRef) {
            this._viewContainer.createEmbeddedView(templateRef);
        } /* else if (this.hasView) {
            this._viewContainer.clear();
            this.hasView = false;
        } */
    }

    updateView() {
        if (this.expectedConfigurations && this.expectedConfigurations.length > 0) {
            if (checkUserConfiguration(this.expectedConfigurations)) {
                this._viewContainer.createEmbeddedView(this._templateRef);
                this.hasView = true;
            } else {
                this._viewContainer.clear();
                this.hasView = false;
            }
        }
    }
}   
