import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { ProfileService } from 'app/main/pages/profile/profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment.prod';
import { ReactiveFormsModule } from '@angular/forms';
import { ToolbarService } from './toolbar.service';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatBadgeModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        TranslateModule.forChild(),
        ReactiveFormsModule,
    ],
    exports: [
        ToolbarComponent
    ],
    providers: [
        ProfileService,
        ToolbarService
    ]
})
export class ToolbarModule {
}
