import { Component, Input, OnInit } from '@angular/core';

interface MessageAlert {
    txtMain: string,
    txtSub?: string,
    iconType?: string
}

@Component({
    selector: 'alert-component',
    templateUrl: './alert-component.component.html',
    styleUrls: ['./alert-component.component.scss']
})
export class AlertComponentComponent implements OnInit {
    @Input("message") message: MessageAlert;

    constructor() { }

    ngOnInit() {
    }

}
