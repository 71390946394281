export enum FrontConfiguration {
    FEATURE_DASHBORAD_ADMIN = "FEATURE_DASHBORAD_ADMIN",
    FEATURE_DASHBORAD_ATC = "FEATURE_DASHBORAD_ATC",
    FEATURE_ROOMS = "FEATURE_ROOMS",
    FEATURE_CONVERSATION_FLOWS = "FEATURE_CONVERSATION_FLOWS",
    FEATURE_USERS = "FEATURE_USERS",
    FEATURE_TEMPLATES = "FEATURE_TEMPLATES",
    FEATURE_QUICK_RESPONSES = "FEATURE_QUICK_RESPONSES",
    FEATURE_LABELS = "FEATURE_LABELS",
    FEATURE_ABSENCE_MESSAGES = "FEATURE_ABSENCE_MESSAGES",
    FEATURE_MASS_MESSAGES_GROUPS = "FEATURE_MASS_MESSAGES_GROUPS",
    FEATURE_MASS_MESSAGES_MESSAGE = "FEATURE_MASS_MESSAGES_MESSAGE",
    FEATURE_FOLLOW_MESSAGES = "FEATURE_FOLLOW_MESSAGES",
    FEATURE_CHAT = "FEATURE_CHAT",
    FEATURE_CONTACT = "FEATURE_CONTACT",
    FEATURE_PROFILE = "FEATURE_PROFILE",
    BUY_WHATSAPP_API_CHANNEL = "BUY_WHATSAPP_API_CHANNEL",
    BUY_WEBCHAT_CHANNEL = "BUY_WEBCHAT_CHANNEL",
    BUY_MESSENGER_CHANNEL = "BUY_MESSENGER_CHANNEL",
    BUY_IG_CHANNEL = "BUY_IG_CHANNEL",
    BUY_EMAIL_CHANNEL = "BUY_EMAIL_CHANNEL",
    WHATSAPP_API_CHANNEL = "WHATSAPP_API_CHANNEL",
    WEBCHAT_CHANNEL = "WEBCHAT_CHANNEL",
    MESSENGER_CHANNEL = "MESSENGER_CHANNEL",
    IG_CHANNEL = "IG_CHANNEL",
    EMAIL_CHANNEL = "EMAIL_CHANNEL",
    FORM_WEB_CHANNEL = "FORM_WEB_CHANNEL",
    NEW_ROOM_IMPORT_SUBSCRIBERS = "NEW_ROOM_IMPORT_SUBSCRIBERS",
    MAX_SEND_MESSAGE_WHATSAPP_BUSINESS = "MAX_SEND_MESSAGE_WHATSAPP_BUSINESS",
    DASHBOARD_BEPLIC = "DASHBOARD_BEPLIC",
    DASHBOARD_METABASE = "DASHBOARD_METABASE",
    DASHBOARD_POWERBI = "DASHBOARD_POWERBI",
    USER_ADMIN_RECOVER_PASSWORD = "USER_ADMIN_RECOVER_PASSWORD",
    LOGIN_ADMIN = "LOGIN_ADMIN",
    LOGIN_ATC = "LOGIN_ATC",
    MAX_AGENTS = "MAX_AGENTS",
    MAX_ROOMS = "MAX_ROOMS",
    LEADS_GENERATOR = "LEADS_GENERATOR",
    WHATSAPP_IN_AUTOMATION = "WHATSAPP_IN_AUTOMATION",
    COLLABORATIVE_CUSTOMER_SERVICES = "COLLABORATIVE_CUSTOMER_SERVICES",
    SUPPORT_BY_EMAIL = "SUPPORT_BY_EMAIL",
    SUPPORT_BY_WHATSAPP = "SUPPORT_BY_WHATSAPP",
    PRICE_ADITIONAL_CONVERSATIONS = "PRICE_ADITIONAL_CONVERSATIONS",
    PRICE_ADITIONAL_AGENT = "PRICE_ADITIONAL_AGENT",
    PRICE_ADITIONAL_CHANNEL = "PRICE_ADITIONAL_CHANNEL",
    PARTNER_NAV_TOP_ENABLED = "PARTNER_NAV_TOP_ENABLED",
    PARTNER_TOOLBAR_ENABLED = "PARTNER_TOOLBAR_ENABLED",
    PARTNER_CBX_EXTERNAL_INTEGRATION_ENABLED = "PARTNER_CBX_EXTERNAL_INTEGRATION_ENABLED",
    PARTNER_LABEL_ENABLED = "PARTNER_LABEL_ENABLED",
    PARTNER_LYT_BTN_IMPORT_CONTACTS_ENABLED = "PARTNER_LYT_BTN_IMPORT_CONTACTS_ENABLED",
    PARTNER_LYT_CALENDAR_ENABLED = "PARTNER_LYT_CALENDAR_ENABLED",
    PARTNER_WELCOME_MESSAGE_ENABLED = "PARTNER_WELCOME_MESSAGE_ENABLED",
    PARTNER_CBX_ASSIGN_USER_ROLE_ENABLED = "PARTNER_CBX_ASSIGN_USER_ROLE_ENABLED",
    PARTNER_TBL_HIDE_ROLE_COLUMN_ENABLED = "PARTNER_TBL_HIDE_ROLE_COLUMN_ENABLED",
    PARTNER_LOGIN_ENABLED = "PARTNER_LOGIN_ENABLED",
    PARTNER_URL_PLAN = "PARTNER_URL_PLAN",
    PARTNER_HOSTNAME = "PARTNER_HOSTNAME",
    PARTNER_DASHBOARD_TOGGLE = "PARTNER_DASHBOARD_TOGGLE",
    CONVERSATION_MAX_FREE = "CONVERSATION_MAX_FREE",
    INFO_CUSTOM_INTEGRATION = "INFO_CUSTOM_INTEGRATION"
}