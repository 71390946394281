import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { fuseAnimations } from '@fuse/animations';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'navbar-doppler',
    templateUrl: './navbar-doppler.component.html',
    styleUrls: ['./navbar-doppler.component.scss'],
    animations: fuseAnimations

})
export class NavbarDopplerComponent implements OnInit, AfterViewInit {

    public isDopplerSession: boolean = false;
    public showNav: boolean = false;
    urlBlackList = ["/pages/auth/login", "/pages/auth/forgot-password", "/pages/auth/reset-password", "/pages/auth/mail-confirm"]

    constructor(private router: Router) { }

    ngOnInit() {
        this.showNavDoppler();
    }

    showNavDoppler() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const found = this.urlBlackList.some(url => url === event.urlAfterRedirects);
            if (found) {
                this.showNav = false;
            } else {
                this.showNav = true;
            }
        });
    }

    ngAfterViewInit(): void {
        window.addEventListener("doppler-session-state-update", () => {
            const dopplerSessionState = window['dopplerSessionState'];
            this.checkSession(dopplerSessionState);
        });
    }

    checkSession(dopplerSessionState: any) {
        if (dopplerSessionState && dopplerSessionState.status === 'authenticated') {
            this.isDopplerSession = true;
        } else {
            this.isDopplerSession = false;
        }
    }
}
