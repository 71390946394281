import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import Swal from 'sweetalert2';

import { fuseAnimations } from '@fuse/animations';
import { FileRules } from 'app/config/file-rules';
import { Template } from 'app/interfaces/entities/template.interface';
import { CampaignService } from 'app/main/apps/services/campaign.service';
import { TemplateHeaderTypeValues } from 'app/main/apps/types/common-types.type';

@Component({
    selector: 'preview-template',
    templateUrl: './preview-template.component.html',
    styleUrls: ['./preview-template.component.scss'],
    animations: fuseAnimations
})
export class PreviewTemplateComponent implements OnInit, OnChanges {
    @Input('template') template: Template;
    @Input() isTemplateExample: boolean;
    @Input('styleNameCustom') set setStyleTemplate(styleNameCustom) {
        this.setCustomStyle(styleNameCustom);
    };

    quickReplyList = [];
    hasBtnTypeCta: boolean = false;
    hasBtnTypeQuickReply: boolean = false;
    selectedFile: File = null;
    mediaFileUrl: string;

    mediaEmptyIcon = {
        IMAGE: 'image',
        VIDEO: 'videocam',
        DOCUMENT: 'folder'
    };
    customStyle = {
        customStyle1: false,
        customStyle2: false,
        // Otros estilos...
    };

    constructor(private _campaignService: CampaignService) { }

    ngOnChanges(changes: SimpleChanges): void {
        const { currentValue: template } = changes['template'];
        this.updateTemplate(template);
    }

    ngOnInit() { }

    updateTemplate(template) {
        if (template) {
            this.resetVariables();
            for (const key in template) {
                if (key.includes('quickReply') && template[key]) {
                    this.quickReplyList.push(template[key]);
                    this.hasBtnTypeQuickReply = true;
                }
            }

            if (!this.hasBtnTypeQuickReply && (template['labelPhone'] || template['labelUrl'])) {
                this.hasBtnTypeCta = true;
            }

            /* TODO: tipear el objeto "template" para que no vengan propiedades desconocidas al modelo */
            /*  if (!(template['headerText'] && template['bodyText'])) {
                 this.template = null;
             } */
        } else {
            this.resetVariables();
        }
    }

    resetVariables() {
        this.quickReplyList = [];
        this.hasBtnTypeCta = false;
        this.hasBtnTypeQuickReply = false;
        if (this.template.fileId != null) {
            if (!this.isTemplateExample) {
                this.template.headerText = this.template.parameterHeader;
            } else {
                this.template.headerText = null;
            }
            this.selectedFile = null;
            this._campaignService.onMediaFileChanged.next(null);
        }
    }

    checkHeaderMediaType(headerType: TemplateHeaderTypeValues) {
        return ['VIDEO', 'IMAGE', 'DOCUMENT'].some(elem => elem === headerType);
    }

    setCustomStyle(styleEntered: string) {
        Object.entries(this.customStyle).forEach(([key, value]) => {
            this.customStyle[key] = key !== styleEntered ? false : true;
        })
    }

    fileSelected(event, type: 'img' | 'file' | 'document' | 'video') {
        if (event.target.files[0]) {
            let file: any;
            file = type === 'img' ? 'La imagen' : (type === 'video' ? 'El video' : 'El documento');

            if (event.target.files[0].size > FileRules.getMaxSize(type)) {
                Swal.fire({
                    title: 'Atención!',
                    text: FileRules.getTitleMaxFileSize(type, file),
                    type: 'warning',
                    confirmButtonColor: '#e2ae2f',
                    confirmButtonText: 'Aceptar',
                    width: '40rem'
                })
            } else {
                this.selectedFile = event.target.files[0] as File;
                this.previewFile(this.selectedFile, type);
                this._campaignService.onMediaFileChanged.next(this.selectedFile);
            }
        }
        event.target.value = '';
    }

    previewFile(file: File, type: string) {
        // Previsualizar imagen, video o documento
        if (type === 'img' || type === 'video') {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.template.headerText = reader.result.toString();
            };
            reader.readAsDataURL(file);
        } else if (type === 'document') {
            this.template.headerText = URL.createObjectURL(file);
        }
    }

    clearFile() {
        this.selectedFile = null;
        this.template.headerText = null;
        this._campaignService.onMediaFileChanged.next(null);
    }
}
