import { Injectable } from '@angular/core';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { FuseNavigationService } from './navigation.service';
import { FuseNavigationItem } from '@fuse/types';
import { LocalStorageService } from 'app/services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationRoomStatusService {
    userProfile: CustomerAccount;

    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _localStorageService: LocalStorageService
    ) {
        this.getUserProfile();
    }

    private updateOrAddRoomInStorage(roomId: any, chatStatusInfo: any) {
        if (roomId) {
            const customerUserRooms: any[] = JSON.parse(sessionStorage.getItem("myRooms")) || [];
            const customerUserRoomToFind = customerUserRooms.find(elem => elem.room.id === roomId);
            if (customerUserRoomToFind) {
                customerUserRoomToFind.chatStatusInfo = chatStatusInfo;
                // Add other properties to edit here
            }
            // Delete storage item
            sessionStorage.removeItem('myRooms');
            // Update storage item
            sessionStorage.setItem('myRooms', JSON.stringify(customerUserRooms));

            this.updateGeneralCounter();

        }
    }

    private getNavigationItemForRooms(): FuseNavigationItem {
        const navigationItemKey: string = "chat";
        const navigationItem = this._fuseNavigationService.getNavigationItem(navigationItemKey);
        if (navigationItem) {
            return navigationItem;
        } else {
            console.warn(`The item ${navigationItemKey} is not found`);
            return;
        }
    }

    updateRoom(roomId: any, chatStatusInfo: any) {
        const chatMenu: FuseNavigationItem = this.getNavigationItemForRooms();
        if (chatMenu) {
            const item: FuseNavigationItem = chatMenu.children.find(item => item.id === (roomId + ''));
            if (item) {
                if (chatStatusInfo && (chatStatusInfo.chatsNotView.length > 0 || chatStatusInfo.chatsNotResponse.length > 0)) {
                    const badgeTitle = chatStatusInfo.chatsNotView.length > 0 ? chatStatusInfo.chatsNotView.length : chatStatusInfo.chatsNotResponse.length;
                    const badgeBg = chatStatusInfo.chatsNotView.length > 0 ? "#09d261" : "#D9A72D";

                    item.badge = {
                        title: badgeTitle.toString(),
                        translate: badgeTitle.toString(),
                        bg: badgeBg
                    };
                } else {
                    item.badge = null;
                }

                // Update navigation menu
                this._fuseNavigationService.activeNavigationItemUpdated();
                // Update room in storage
                this.updateOrAddRoomInStorage(roomId, chatStatusInfo);
            }
        }
    }

    // Actualiza el contador general de chats
    updateGeneralCounter(){
        const chatMenu: FuseNavigationItem = this.getNavigationItemForRooms();

        const rooms = JSON.parse(sessionStorage.getItem("myRooms"));
        const generalCounter = { chatsNotResponse: [], chatsNotView: [] };

        // Recorrer cada elemento del array y sumar los valores
            rooms.forEach(room => {
                // Sumar los valores de chatsNotResponse
                if (Array.isArray(room.chatStatusInfo.chatsNotResponse)) {
                    room.chatStatusInfo.chatsNotResponse.forEach(value => {
                    generalCounter.chatsNotResponse.push(value);
                });
                }
            
                // Sumar los valores de chatsNotView
                if (Array.isArray(room.chatStatusInfo.chatsNotView)) {
                    room.chatStatusInfo.chatsNotView.forEach(value => {
                    generalCounter.chatsNotView.push(value);
                });
                }
            });
                    
            if (chatMenu) {
                if (generalCounter && (generalCounter.chatsNotView.length > 0 || generalCounter.chatsNotResponse.length > 0)) {
                    const badgeTitle = generalCounter.chatsNotView.length > 0 ? generalCounter.chatsNotView.length : generalCounter.chatsNotResponse.length;
                    const badgeBg = generalCounter.chatsNotView.length > 0 ? "#09d261" : "#D9A72D";

                    chatMenu.badge = {
                        title: badgeTitle.toString(),
                        translate: badgeTitle.toString(),
                        bg: badgeBg
                    };
                } else {
                    chatMenu.badge = null;
                }

                // Update navigation menu
                this._fuseNavigationService.activeNavigationItemUpdated();
            }
        
    }

    notifyChatsStatus(message: any): void {
        if (!message) return;

        const customerUserRooms: any[] = JSON.parse(sessionStorage.getItem("myRooms")) || [];
        const customerUserRoomToFind = customerUserRooms.find(elem => elem.room.id === message.chatMessage.chat.roomId);

        if (customerUserRoomToFind) {
            let { chatsNotView, chatsNotResponse } = customerUserRoomToFind.chatStatusInfo;
            const { chatMessage } = message;
            const { id, state } = chatMessage.chat;

            const removeFromArray = (array: any[], value: any) => array.filter(item => item !== value);

            if (chatMessage.messageType === 'OUT') {
                if (state === 'RESPONSE') {
                    chatsNotResponse = removeFromArray(chatsNotResponse, id);
                } else if (state === 'NOTRESPONSE') {
                    chatsNotView = removeFromArray(chatsNotView, id);
                    chatsNotResponse.push(id);
                } else if (state === 'NOTDELIVERED' || 'CLOSED') {
                    chatsNotView = removeFromArray(chatsNotView, id);
                    chatsNotResponse = removeFromArray(chatsNotResponse, id);
                }

                if (customerUserRoomToFind.room.channel.id === 3){
                    if (state === 'NOTVIEW'){
                        chatsNotView.push(id);
                    }
                }
            } else if (chatMessage.messageType === 'IN' && !chatsNotView.includes(id)) {
                chatsNotView.push(id);
                chatsNotResponse = removeFromArray(chatsNotResponse, id);
            }

            const chatStatusInfo = {
                chatsNotView,
                chatsNotResponse
            };

            this.updateRoom(customerUserRoomToFind.room.id, chatStatusInfo);
        }
    }

    getUserProfile(): void {
        this.userProfile = this._localStorageService.getCurrentUser();;
    }
}
