import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Contact } from 'app/main/apps/contacts/contact.model';
import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { ChannelsService } from '../channels/channels.service';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { RequestCreateContactDTO } from 'app/interfaces/dto/request-create-contact-dto.interface';
import { LocalStorageService } from 'app/services/local-storage.service';

@Injectable()
export class ContactsService implements Resolve<any>
{
    onContactsChanged: BehaviorSubject<any>;
    onSelectedContactsChanged: BehaviorSubject<any>;
    onUserDataChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    onLoadGroups: BehaviorSubject<any>;

    contacts: Contact[] = [];
    fixedContact: Contact[];
    user: any;
    selectedContacts: string[] = [];
    userProfile: CustomerAccount;
    channels: any;
    labels: any;
    constContacts: any;
    suggestedAliases: any;
    aliasFilters: string;
    labelFilters: any;
    groupFilters: string;
    isLoadingResults = false;

    searchText: string;
    filterBy: any;
    pageable = {
        number: 0,
        totalElements: 0
    };
    page: any;
    size = 10;

    myRooms: any[];

    private urlContactsRoomsIntegra = UrlList.URL_CONTACS_ROOMS_INTEGRA;
    private urlContacts = UrlList.URL_CONTACTS;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private channelService: ChannelsService,
        private router: Router,
        private _localStorageService: LocalStorageService
    ) {
        this.page = 0;
        this.labelFilters = [];
        this.aliasFilters = '';
        this.groupFilters = '';
        // Set the defaults
        this.onContactsChanged = new BehaviorSubject([]);
        this.onSelectedContactsChanged = new BehaviorSubject([]);
        this.onLoadGroups = new BehaviorSubject([]);
        this.onUserDataChanged = new BehaviorSubject([]);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.myRooms = JSON.parse(sessionStorage.getItem('myRooms')).filter(r => r.room.channel.id === 1).map(x => x.room.id);

        this.getUserProfile();
        this.getChannels();
        this.getLabels();
        this.getGroupsByRoomList();
        return new Promise((resolve, reject) => {
            this.searchText = null;
            this.filterBy = null;
            Promise.all([
                this.getContacts()
            ])
                .then(
                    ([files]) => {
                        this.onSearchTextChanged.subscribe(searchText => {
                            this.page = 0;
                            this.isLoadingResults = true;
                            this.searchText = searchText;
                            this.getContacts();
                        });

                        this.onFilterChanged.subscribe(filter => {
                            this.page = 0;
                            this.isLoadingResults = true;
                            if (filter === 'all') {
                                this.filterBy = null;
                            } else {
                                this.filterBy = filter;
                            }
                            this.getContacts();
                        });

                        resolve(null);

                    },
                    err => {
                        if (err && err.error && err.error.status === 404) {
                            resolve(null);
                        } else {
                            reject
                        }
                    }
                )
        });
    }

    getUserProfile(): void {
        this.userProfile = this._localStorageService.getCurrentUser();
    }

    getChannels(): void {
        this.channelService.getChannelActiveByCustomerUserIdPromise(this.userProfile.id, true).then(data => {
            this.channels = data;
        });
    }

    /**
     * Get contacts
     *
     * @returns {Promise<any>}
     */
    getContacts(): Promise<any> {
        return new Promise((resolve, reject) => {
            const currentUser = this._localStorageService.getCurrentUser();
            if ((this.searchText && this.searchText !== '') || this.filterBy) {
                this.searchContacts();
            } else {
                const params = new HttpParams()
                    .set('page', this.page.toString())
                    .set('size', this.size.toString());
                this._httpClient.get(this.urlContactsRoomsIntegra + UrlList.GET_CUSTOMER_CONTACTS + `/${currentUser.customerId}/customer/${currentUser.id}`, { params })
                    .subscribe((response: any) => {
                        this.fixedContact = response.content;
                        if (this.aliasFilters.length !== 0 || this.labelFilters.length !== 0 || this.groupFilters.length !== 0) {
                            this.filterTable(this.aliasFilters, this.labelFilters, this.groupFilters);
                            this.pageable = response;
                        } else {
                            this.contacts = response.content;
                            this.pageable = response;
                            this.isLoadingResults = false;
                            this.onContactsChanged.next(this.contacts);
                        }

                        resolve(this.contacts);
                    }, reject);
            }
        }
        );
    }

    searchContacts(): Promise<any> {
        const currentUser = this._localStorageService.getCurrentUser();
        const body = {
            customerId: currentUser.customerId,
            customeruserId: currentUser.id,
            channeld: this.filterBy ? this.filterBy : null,
            searchValue: this.searchText
        };
        const params = new HttpParams()
            .set('page', this.page.toString())
            .set('size', this.size.toString());
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlContactsRoomsIntegra + '/contacts/filter', body, { params })
                .subscribe((response: any) => {
                    this.pageable = response;
                    this.fixedContact = response.content;
                    if (this.aliasFilters !== '' || this.labelFilters.length !== 0 || this.groupFilters !== '') {
                        this.filterTable(this.aliasFilters, this.labelFilters, this.groupFilters);
                    } else {
                        this.contacts = response.content;
                        this.fixedContact = response.content;
                        this.pageable = response;
                        this.isLoadingResults = false;
                        this.onContactsChanged.next(this.contacts);
                    }
                    resolve(this.contacts);
                }, reject);
        }
        );
    }

    /**
     * Toggle selected contact by id
     *
     * @param id
     */
    toggleSelectedContact(id): void {
        // First, check if we already have that contact as selected...
        if (this.selectedContacts.length > 0) {
            const index = this.selectedContacts.indexOf(id);

            if (index !== -1) {
                this.selectedContacts.splice(index, 1);

                // Trigger the next event
                this.onSelectedContactsChanged.next(this.selectedContacts);

                // Return
                return;
            }
        }

        // If we don't have it, push as selected
        this.selectedContacts.push(id);

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Toggle select all
     */
    toggleSelectAll(): void {
        if (this.selectedContacts.length > 0) {
            this.deselectContacts();
        }
        else {
            this.selectContacts();
        }
    }

    /**
     * Select contacts
     *
     * @param filterParameter
     * @param filterValue
     */
    selectContacts(filterParameter?, filterValue?): void {
        this.selectedContacts = [];

        // If there is no filter, select all contacts
        if (filterParameter === undefined || filterValue === undefined) {
            this.selectedContacts = [];
            this.contacts.map(contact => {
                this.selectedContacts.push(contact.id);
            });
        }

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Update contact
     *
     * @param contact
     * @returns {Promise<any>}
     */
    //  TODO
    updateContact(contact): Promise<any> {
        const body = {
            id: contact.id,
            name: contact.name,
            firstName: contact.firstName,
            lastName: contact.lastName,
            phone: contact.phone,
            creationDate: contact.creationDate,
            email: contact.email
        };
        return new Promise((resolve, reject) => {

            this._httpClient.post(this.urlContacts + UrlList.GET_UPDATE_CONTACT, body)
                .subscribe(response => {
                    this.getContacts();
                    resolve(response);
                });
        });
    }

    updateContactChat(contact: any, business): Promise<any> {
        const body = {
            id: contact.id,
            name: null,
            firstName: contact.firstName,
            lastName: contact.lastName,
            phone: contact.phone,
            creationDate: contact.creationDate,
            email: contact.email,
            alias: contact.alias,
            userfId: contact.userfId,
            enterpriseId: business ? business.id : null,
            enterpriseName: business ? business.name : null,
            customerId: contact.customerId,
            roomId: contact.roomId,
            externalListId: contact.externalListId,
            admEmail: contact.admEmail
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlContactsRoomsIntegra + UrlList.UPDATE_CONTACT_CHAT, body)
                .subscribe(response => {
                    if (this.router.url === '/apps/contacts') {
                        this.getContacts();
                    }
                    resolve(response);
                });
        });
    }



    /**
     * Update user data
     *
     * @param userData
     * @returns {Promise<any>}
     */
    updateUserData(userData): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/contacts-user/' + this.user.id, { ...userData })
                .subscribe(response => {
                    this.getContacts();
                    resolve(response);
                });
        });
    }

    /**
     * Deselect contacts
     */
    deselectContacts(): void {
        this.selectedContacts = [];

        // Trigger the next event
        this.onSelectedContactsChanged.next(this.selectedContacts);
    }

    /**
     * Delete contact
     *
     * @param contact
     */
    deleteContact(contact): void {
        const contactIndex = this.contacts.indexOf(contact);
        this.contacts.splice(contactIndex, 1);
        this.onContactsChanged.next(this.contacts);
    }

    /**
     * Delete selected contacts
     */
    deleteSelectedContacts(): void {
        for (const contactId of this.selectedContacts) {
            const contact = this.contacts.find(_contact => {
                return _contact.id === contactId;
            });
            const contactIndex = this.contacts.indexOf(contact);
            this.contacts.splice(contactIndex, 1);
        }
        this.onContactsChanged.next(this.contacts);
        this.deselectContacts();
    }

    getCustomerContacts(customerId: number): Observable<any> {
        return this._httpClient.get(this.urlContactsRoomsIntegra + UrlList.GET_CUSTOMER_CONTACTS + '/' + customerId);
    }

    getSuggestedAliases(customerId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlContacts + '/contacts/search-distinct-alias/customerId/' + customerId)
                .subscribe(response => {
                    this.suggestedAliases = response;
                    resolve(response);
                });
        });
    }

    getSuggestedBusiness(customerId: any): Observable<any> {
        return this._httpClient.get(this.urlContacts + '/enterprises/customer/' + customerId);
    }

    getLabels(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.urlContacts + UrlList.CUSTOMER_LABELS + this.userProfile.customerId).subscribe((response: any) => {

                this.labels = response.sort((a, b) => {
                    const nameA = a.label.name.toLowerCase();
                    const nameB = b.label.name.toLowerCase();
                    if (nameA < nameB)
                        return -1;
                    if (nameA > nameB)
                        return 1;
                    return 0;
                })
                resolve(this.labels);
            }, reject);
        });
    }

    filterTable(aliasFilters, labelFilters, groupFilters): any {
        if (aliasFilters !== '' || labelFilters.length !== 0 || groupFilters !== '') {
            if (labelFilters.length !== 0 && aliasFilters === '' && groupFilters === '') {
                this.contacts = this.fixedContact.filter((contact: any) => {
                    return contact.contact.label.find(lbl => {
                        return labelFilters.find(label => {
                            return label.id === lbl.id;
                        });
                    });
                });
            } else if (labelFilters.length === 0 && aliasFilters !== '' && groupFilters === '') {
                this.contacts = this.fixedContact.filter((contact: any) => {
                    if (contact.contact.alias) {
                        return contact.contact.alias.toLowerCase().includes(aliasFilters.toLowerCase()) ? contact : undefined;
                    }
                });
            } else if (labelFilters.length === 0 && aliasFilters === '' && groupFilters !== '') {
                this.contacts = this.fixedContact.filter((contact: any) => {
                    if (contact.contact.grupos.length !== 0) {
                        return contact.contact.grupos.find(group => {
                            return group.name.toLowerCase().includes(groupFilters.toLowerCase()) ? contact : null;
                        });
                    }
                });
            } else if (labelFilters.length !== 0 && aliasFilters !== '' && groupFilters === '') {
                this.contacts = this.fixedContact.filter((contact: any) => {
                    return contact.contact.label.find(lbl => {
                        return labelFilters.find(label => {
                            return label.id === lbl.id;
                        });
                    });
                });
                this.contacts = this.contacts.filter((contact: any) => {
                    if (contact.contact.alias) {
                        return contact.contact.alias.toLowerCase().includes(aliasFilters.toLowerCase()) ? contact : undefined;
                    }
                });
            } else if (labelFilters.length !== 0 && aliasFilters === '' && groupFilters !== '') {
                this.contacts = this.fixedContact.filter((contact: any) => {
                    return contact.contact.label.find(lbl => {
                        return labelFilters.find(label => {
                            return label.id === lbl.id;
                        });
                    });
                });
                this.contacts = this.contacts.filter((contact: any) => {
                    if (contact.contact.grupos.length !== 0) {
                        return contact.contact.grupos.find(group => {
                            return group.name.toLowerCase().includes(groupFilters.toLowerCase()) ? contact : null;
                        });
                    }
                });
            } else if (labelFilters.length === 0 && aliasFilters !== '' && groupFilters !== '') {
                this.contacts = this.fixedContact.filter((contact: any) => {
                    if (contact.contact.alias) {
                        return contact.contact.alias.toLowerCase().includes(aliasFilters.toLowerCase()) ? contact : undefined;
                    }
                });
                this.contacts = this.contacts.filter((contact: any) => {
                    if (contact.contact.grupos.length !== 0) {
                        return contact.contact.grupos.find(group => {
                            return group.name.toLowerCase().includes(groupFilters.toLowerCase()) ? contact : null;
                        });
                    }
                });
            } else if (labelFilters.length !== 0 && aliasFilters !== '' && groupFilters !== '') {
                this.contacts = this.fixedContact.filter((contact: any) => {
                    return contact.contact.label.find(lbl => {
                        return labelFilters.find(label => {
                            return label.id === lbl.id;
                        });
                    });
                });

                this.contacts = this.contacts.filter((contact: any) => {
                    if (contact.contact.alias) {
                        return contact.contact.alias.toLowerCase().includes(aliasFilters.toLowerCase()) ? contact : undefined;
                    }
                });

                this.contacts = this.contacts.filter((contact: any) => {
                    if (contact.contact.grupos.length !== 0) {
                        return contact.contact.grupos.find(group => {
                            return group.name.toLowerCase().includes(groupFilters.toLowerCase()) ? contact : null;
                        });
                    }
                });

            }
            this.onContactsChanged.next(this.contacts);
            this.isLoadingResults = false;
        } else {
            this.contacts = this.fixedContact;
            this.onContactsChanged.next(this.contacts);
        }
    }

    getGroupsByRoomList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlContacts + '/gruposbyroomlist', this.myRooms)
                .subscribe((response: any) => {

                    const groups = response.sort((a, b) => {
                        const nameA = a.name.toLowerCase();
                        const nameB = b.name.toLowerCase();
                        if (nameA < nameB)
                            return -1;
                        if (nameA > nameB)
                            return 1;
                        return 0;
                    });

                    this.onLoadGroups.next(groups);
                    resolve(groups);
                }, reject);
        });
    }

    createEnterprise(form): Observable<any> {
        const body = {
            name: form.businessName,
            cuit: form.cuit,
            customerId: this._localStorageService.getCurrentUser().customerId
        }
        // return null;
        return this._httpClient.post(this.urlContacts + '/enterprises', body);
    }

    createNewContact(data: RequestCreateContactDTO) {
        return this._httpClient.post(`${this.urlContactsRoomsIntegra}/contactChat/create`, data)
    }

    validateNewContact(data: RequestCreateContactDTO) {
        return this._httpClient.post(`${this.urlContactsRoomsIntegra}/validateContact`, data)
    }

    getCountryCodeList() {
        return this._httpClient.get(`${this.urlContacts}/phones`)
    }

}
