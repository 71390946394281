// import { Injectable } from '@angular/core';
// import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { catchError } from 'rxjs/operators';
// import { Router } from '@angular/router';

// @Injectable({
//   providedIn: 'root'
// })
// export class AuthInterceptorService implements HttpInterceptor {

//   constructor(private router: Router) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const token: string = localStorage.getItem('auth_token');

//     let request = req;

//     if (token) {
//       request = req.clone({
//         setHeaders: {
//           authorization: `Bearer ${ token }`
//         }
//       });
//     }else{
//       console.log("holaaaaaa");
//     }

//     return next.handle(request).pipe(
//       catchError((err: HttpErrorResponse) => {

//         if (err.status === 401) {
//           this.router.navigateByUrl('pages/auth/login');
//         }

//         return throwError( err );

//       })
//     );
//   }


// }
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from 'app/core/auth/account.service';

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {

    constructor(private _accountService: AccountService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('Skip-Authorization')) {
            const headers = req.headers.delete('Skip-Authorization');
            const authReq = req.clone({ headers });
            return next.handle(authReq);
        } else {
            const authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${localStorage.getItem('auth_token')}`) });
            //return next.handle(null);
            return next.handle(authReq).pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        this._accountService.defaultEndSession();
                    }
                    return throwError(err);
                })
            );;
        }

    }
}
