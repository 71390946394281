import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlList } from './main/pages/authentication/shared/url-list.enum';


@Injectable({
  providedIn: 'root'
})

export class NavigationService {

  onProfileUpdated = new EventEmitter<any>();

  UrlList: any;
  constructor(private http: HttpClient) {
   }

  getNavigationList(user: any) {
    return this.http.post(UrlList.URL_SECURITY + UrlList.GET_NAVIGATION, user);
  }

  updateProfile(user: any): void{
    this.onProfileUpdated.emit(user);
}

}


