import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { UrlList } from 'app/main/pages/authentication/shared/url-list.enum';
import { CustomerAccount } from 'app/main/pages/authentication/shared/model/user.model';
import { LocalStorageService } from 'app/services/local-storage.service';

@Injectable()
export class EcommerceProductsService implements Resolve<any> {
    products: any[];
    onProductsChanged: BehaviorSubject<any>;
    onLoadUserRooms: BehaviorSubject<any>;
    private UrlList = UrlList;
    userProfile: CustomerAccount;
    private urlRooms = UrlList.URL_ROOMS;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _localStorageService: LocalStorageService
    ) {
        // Set the defaults
        this.onProductsChanged = new BehaviorSubject({});
        this.onLoadUserRooms = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.getUserProfile();
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUsers()
                // this.getUserRooms()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get products
     *
     * @returns {Promise<any>}
     */
    getUsers(): Promise<any> {
        const params = new HttpParams()
            .set('page', '0')
            .set('size', '50');

        return new Promise((resolve, reject) => {
            this._httpClient.get(this.UrlList.URL_PROFILE_INTEGRATED + this.UrlList.GET_USERS_BY_CUSTOMER_ID + this.userProfile.customerId, { params })
                .subscribe((response: any) => {
                    this.products = response.content;
                    this.onProductsChanged.next(this.products);
                    resolve(response);
                }, reject);
        });


    }

    // getUserRooms(): Promise<any> {
    //     const body = {
    //         customeruserId: this.userProfile.id,
    //         customersId: this.userProfile.customerId
    //     }
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(UrlList.URL_ROOMS + UrlList.GET_CUSTOMER_ROOMS, body)
    //             .subscribe((response: any) => {
    //                 this.onLoadUserRooms.next(response);
    //                 resolve(response);
    //             }, reject);
    //     });
    // }

    changeAppStatus(user: any): Observable<any> {
        const body = {
            id: user.id,
            appStatus: "CHANGED",
            receiveNotification: false
        }
        return this._httpClient.post(UrlList.URL_PROFILE_INTEGRATED + UrlList.MOBILE_PROFILE_STATUS, body)
    }

    getUserRooms(user: any): Observable<any> {
        const body = {
            customeruserId: user.id,
            customersId: user.customersId
        }
        return this._httpClient.post(UrlList.URL_ROOMS + UrlList.GET_USER_WITH_ASSIGNED_ROOMS, body)
    }

    getUserProfile() {
        this.userProfile = this._localStorageService.getCurrentUser();
    }

    assignRooms(rooms: any, customeruserId: number): Observable<any> {
        return this._httpClient.post(`${UrlList.URL_ROOMS}${UrlList.ASSIGN_ROOMS_TO_USER}/${customeruserId}`, rooms);
    }

    getRooms(user): Promise<any> {
        const body = {
            customeruserId: user.id,
            customersId: user.customersId
        };

        return new Promise((resolve, reject) => {
            this._httpClient.post(this.urlRooms + UrlList.GET_CUSTOMER_ROOMS, body).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    setPreferenceByUser(body: any) {
        return this._httpClient.post('/services/beplicoreuser/api/customer-user-preferences', body);
    }
}
