import { NgModule } from "@angular/core";
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatSortModule,
    MatDividerModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
} from "@angular/material";

import { TranslateModule } from "@ngx-translate/core";
import { EmojiComponent } from "./emoji/emoji.component";
import { PickerModule } from "@ctrl/ngx-emoji-mart";

import { FuseSharedModule } from "@fuse/shared.module";
import { FuseConfirmDialogModule, FuseSidebarModule } from "@fuse/components";
import { FusePipesModule } from "@fuse/pipes/pipes.module";
import { BusinessFormDialogComponent } from "app/main/apps/contacts/contact-form copy/business-form.component";
import { TemplateDialogComponent } from "app/main/apps/template/template-dialog/template-dialog.component";
import { ValidateFileComponent } from "./validate-file/validate-file.component";
import { CreateContactDialogComponent } from "./create-contact-dialog/create-contact-dialog.component";
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { InfoCreateContactDialogComponent } from './info-create-contact-dialog/info-create-contact-dialog.component';
import { WarningCreateContactDialogComponent } from './warning-create-contact-dialog/warning-create-contact-dialog.component';
import { TemplateRejectInfoDialogComponent } from './template-reject-info-dialog/template-reject-info-dialog.component';
import { PreviewTemplateDialogComponent } from './preview-template-dialog/preview-template-dialog.component';
import { PreviewTemplateComponent } from "./components/preview-template-massive/preview-template.component";
import { PreviewTemplateV1DialogComponent } from './preview-template-v1-dialog/preview-template-v1-dialog.component';
import { AlertComponentComponent } from './components/alert-component/alert-component.component';
import { DashboardPowerbiComponent } from './app-dashboards/dashboard-powerbi/dashboard-powerbi.component';
import { LazyImgDirective } from './directives/lazy-img.directive';
import { DashboardComponent } from './app-dashboards/dashboard/dashboard.component';
import { AlertUserComponent } from './components/alert-user/alert-user.component';
import { IframeMetabaseComponent } from './app-dashboards/iframe-metabase/iframe-metabase.component';
import { CardReportComponent } from './card-report/card-report.component';
import { SharedTableComponent } from './components/shared-table/shared-table.component';
import { ContactsService } from "app/main/apps/contacts/contacts.service";
import { ContactsContactFormDialogComponent } from "app/main/apps/contacts/contact-form/contact-form.component";
import { EcommerceProductsService } from "app/main/apps/e-commerce/products/products.service";
import { ChatService } from "app/main/apps/chat/chat.service";
import { TodoService } from "app/main/apps/chat/todo.service";
import { AssignFlowListComponent } from './assign-flow-list/assign-flow-list.component';
import { AssigFlowListItemComponent } from './assign-flow-list/assig-flow-list-item/assig-flow-list-item.component';
import { LoadingScreenComponet } from "./loading-screen/loading-screen.component";
import { FrontConfigurationsDirective } from "./directives/front-configurations.directive";
import { CheckCurrentPartnerDirective } from './directives/check-current-partner.directive';

const DIRECTIVES = [
    OnlyNumbersDirective,
    LazyImgDirective,
    FrontConfigurationsDirective,
    CheckCurrentPartnerDirective
];

const DIALOGS = [
    CreateContactDialogComponent,
    InfoCreateContactDialogComponent,
    WarningCreateContactDialogComponent,
    TemplateRejectInfoDialogComponent,
    PreviewTemplateDialogComponent,
    PreviewTemplateV1DialogComponent,
]

@NgModule({
    declarations: [
        ContactsContactFormDialogComponent,
        BusinessFormDialogComponent,
        EmojiComponent,
        TemplateDialogComponent,
        ValidateFileComponent,
        PreviewTemplateComponent,
        AlertComponentComponent,
        DashboardPowerbiComponent,
        ...DIRECTIVES,
        ...DIALOGS,
        LazyImgDirective,
        DashboardComponent,
        AlertUserComponent,
        IframeMetabaseComponent,
        CardReportComponent,
        SharedTableComponent,
        AssignFlowListComponent,
        AssigFlowListItemComponent,
        LoadingScreenComponet
    ],
    imports: [
        TranslateModule.forChild(),
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatDividerModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        FuseSidebarModule,
        PickerModule,
        FusePipesModule,
        MatTooltipModule
    ],
    providers: [ContactsService, EcommerceProductsService, ChatService, TodoService],
    exports: [
        ContactsContactFormDialogComponent,
        BusinessFormDialogComponent,
        EmojiComponent,
        TemplateDialogComponent,
        ValidateFileComponent,
        PreviewTemplateComponent,
        AlertComponentComponent,
        DashboardPowerbiComponent,
        DashboardComponent,
        IframeMetabaseComponent,
        AlertUserComponent,
        SharedTableComponent,
        ...DIRECTIVES,
        ...DIALOGS,
        LoadingScreenComponet
    ],
    entryComponents: [
        ContactsContactFormDialogComponent,
        BusinessFormDialogComponent,
        TemplateDialogComponent,
        AssigFlowListItemComponent,
        AssignFlowListComponent,
        ...DIALOGS
    ],
})
export class SharedModule { }
